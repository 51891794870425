import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User, UserList } from '../../domain/User';

export const fetchUserListStart = () => ({
  type: actionTypes.FETCH_USER_LIST_START,
});

export const fetchUserListSuccess = (userList: UserList) => ({
  type: actionTypes.FETCH_USER_LIST_SUCCESS,
  userList,
});

export const fetchUserListFail = (userListError: HttpError) => ({
  type: actionTypes.FETCH_USER_LIST_FAIL,
  userListError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = () => ({
  type: actionTypes.CREATE_USER_SUCCESS,
});

export const createUserFail = (createUserError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  createUserError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (deleteUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  deleteUserError,
});

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (updateUserError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  updateUserError,
});

export const resetCreateAndUpdateUser = () => ({
  type: actionTypes.RESET_CREATE_AND_UPDATE_USER,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const resetUserStoreLogout = () => ({
  type: actionTypes.RESET_USER_STORE_LOGOUT,
});

export const resetDeleteUser = () => ({
  type: actionTypes.RESET_DELETE_USER,
});

export const setUserSelectedCompanyStart = () => ({
  type: actionTypes.SET_SELECT_USER_COMPANY_START,
});

export const setUserSelectedCompanySuccess = () => ({
  type: actionTypes.SET_SELECT_USER_COMPANY_SUCCESS,
});

export const setUserSelectedCompanyFail = (
  setUserSelectedCompanyError: HttpError,
) => ({
  type: actionTypes.SET_SELECT_USER_COMPANY_FAIL,
  setUserSelectedCompanyError,
});
