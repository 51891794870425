export const CREATE_NOTE_START = 'CREATE_NOTE_START';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAIL = 'CREATE_NOTE_FAIL';

export const DELETE_NOTE_START = 'DELETE_NOTE_START';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';

export const RESET_NOTE_STORE = 'RESET_NOTE_STORE';

export type NoteActionTypes =
  | typeof CREATE_NOTE_START
  | typeof CREATE_NOTE_SUCCESS
  | typeof CREATE_NOTE_FAIL
  | typeof DELETE_NOTE_START
  | typeof DELETE_NOTE_SUCCESS
  | typeof DELETE_NOTE_FAIL
  | typeof RESET_NOTE_STORE;
