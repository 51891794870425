export const FETCH_TIME_OFFS_LIST_START = 'FETCH_TIME_OFFS_LIST_START';
export const FETCH_TIME_OFFS_LIST_SUCCESS = 'FETCH_TIME_OFFS_LIST_SUCCESS';
export const FETCH_TIME_OFFS_LIST_FAIL = 'FETCH_TIME_OFFS_LIST_FAIL';

export const CREATE_TIME_OFF_START = 'CREATE_TIME_OFF_START';
export const CREATE_TIME_OFF_SUCCESS = 'CREATE_TIME_OFF_SUCCESS';
export const CREATE_TIME_OFF_FAIL = 'CREATE_TIME_OFF_FAIL';

export const DELETE_TIME_OFF_START = 'DELETE_TIME_OFF_START';
export const DELETE_TIME_OFF_SUCCESS = 'DELETE_TIME_OFF_SUCCESS';
export const DELETE_TIME_OFF_FAIL = 'DELETE_TIME_OFF_FAIL';

export const FETCH_TIME_OFF_START = 'FETCH_TIME_OFF_START';
export const FETCH_TIME_OFF_SUCCESS = 'FETCH_TIME_OFF_SUCCESS';
export const FETCH_TIME_OFF_FAIL = 'FETCH_TIME_OFF_FAIL';

export const UPDATE_TIME_OFF_START = 'UPDATE_TIME_OFF_START';
export const UPDATE_TIME_OFF_SUCCESS = 'UPDATE_TIME_OFF_SUCCESS';
export const UPDATE_TIME_OFF_FAIL = 'UPDATE_TIME_OFF_FAIL';

export const RESET_CREATE_AND_UPDATE_TIME_OFF =
  'RESET_CREATE_AND_UPDATE_TIME_OFF';

export const FETCH_CLIENT_TIME_OFFS_LIST_START =
  'FETCH_CLIENT_TIME_OFFS_LIST_START';
export const FETCH_CLIENT_TIME_OFFS_LIST_SUCCESS =
  'FETCH_CLIENT_TIME_OFFS_LIST_SUCCESS';
export const FETCH_CLIENT_TIME_OFFS_LIST_FAIL =
  'FETCH_CLIENT_TIME_OFFS_LIST_FAIL';

export const RESET_TIME_OFF_STORE = 'RESET_TEAM_STORE';

export type TimeOffActionTypes =
  | typeof FETCH_TIME_OFFS_LIST_START
  | typeof FETCH_TIME_OFFS_LIST_SUCCESS
  | typeof FETCH_TIME_OFFS_LIST_FAIL
  | typeof CREATE_TIME_OFF_START
  | typeof CREATE_TIME_OFF_SUCCESS
  | typeof CREATE_TIME_OFF_FAIL
  | typeof DELETE_TIME_OFF_START
  | typeof DELETE_TIME_OFF_SUCCESS
  | typeof DELETE_TIME_OFF_FAIL
  | typeof FETCH_TIME_OFF_START
  | typeof FETCH_TIME_OFF_SUCCESS
  | typeof FETCH_TIME_OFF_FAIL
  | typeof UPDATE_TIME_OFF_START
  | typeof UPDATE_TIME_OFF_SUCCESS
  | typeof UPDATE_TIME_OFF_FAIL
  | typeof FETCH_CLIENT_TIME_OFFS_LIST_START
  | typeof FETCH_CLIENT_TIME_OFFS_LIST_SUCCESS
  | typeof FETCH_CLIENT_TIME_OFFS_LIST_FAIL
  | typeof RESET_CREATE_AND_UPDATE_TIME_OFF
  | typeof RESET_TIME_OFF_STORE;
