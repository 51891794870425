export const UPDATE_COMPANY_SETTINGS_START = 'UPDATE_COMPANY_SETTINGS_START';
export const UPDATE_COMPANY_SETTINGS_SUCCESS =
  'UPDATE_COMPANY_SETTINGS_SUCCESS';
export const UPDATE_COMPANY_SETTINGS_FAIL = 'UPDATE_COMPANY_SETTINGS_FAIL';

export const UPDATE_COMPANY_FEATURES_START = 'UPDATE_COMPANY_FEATURES_START';
export const UPDATE_COMPANY_FEATURES_SUCCESS =
  'UPDATE_COMPANY_FEATURES_SUCCESS';
export const UPDATE_COMPANY_FEATURES_FAIL = 'UPDATE_COMPANY_FEATURES_FAIL';

export type CompanySettingsActionTypes =
  | typeof UPDATE_COMPANY_SETTINGS_START
  | typeof UPDATE_COMPANY_SETTINGS_SUCCESS
  | typeof UPDATE_COMPANY_SETTINGS_FAIL
  | typeof UPDATE_COMPANY_FEATURES_START
  | typeof UPDATE_COMPANY_FEATURES_SUCCESS
  | typeof UPDATE_COMPANY_FEATURES_FAIL;
