import * as actionTypes from './actionTypes';
import { ScheduleActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Schedule, SchedulesList } from '../../domain/Schedule';

export type ScheduleStateType = {
  schedulesList: Schedule[] | null;
  schedulesLoading: boolean;
  schedulesError: HttpError;
  schedulesListUpdateNeeded: boolean;
  clientSchedulesListUpdateNeeded: boolean;
  scheduleCreateLoading: boolean;
  scheduleCreateError: HttpError;
  scheduleCreateSuccess: boolean;
  createdSchedule: Schedule | null;
  scheduleUpdateLoading: boolean;
  scheduleUpdateError: HttpError;
  scheduleUpdateSuccess: boolean;
  scheduleDeleteLoading: boolean;
  scheduleDeleteSuccess: boolean;
  scheduleDeleteError: HttpError;
  schedule: Schedule | null;
  scheduleLoading: boolean;
  scheduleError: HttpError;
  scheduleEditUpdateNeeded: boolean;
  clientSchedulesList: SchedulesList | null;
  clientSchedulesLoading: boolean;
  clientSchedulesError: HttpError;
};

export type ScheduleActionType = ScheduleStateType & {
  type: ScheduleActionTypes;
};

export const initialState: ScheduleStateType = {
  schedulesList: null,
  schedulesLoading: true,
  schedulesError: null,
  schedulesListUpdateNeeded: false,
  scheduleCreateLoading: false,
  scheduleCreateError: null,
  scheduleCreateSuccess: false,
  createdSchedule: null,
  scheduleUpdateLoading: false,
  scheduleUpdateError: null,
  scheduleUpdateSuccess: false,
  scheduleDeleteLoading: false,
  scheduleDeleteError: null,
  scheduleDeleteSuccess: false,
  schedule: null,
  scheduleLoading: false,
  scheduleError: null,
  scheduleEditUpdateNeeded: false,
  clientSchedulesList: null,
  clientSchedulesLoading: false,
  clientSchedulesError: null,
  clientSchedulesListUpdateNeeded: false,
};

const fetchSchedulesStart = (state: ScheduleStateType): ScheduleStateType => ({
  ...state,
  schedulesLoading: true,
});

const fetchSchedulesSuccess = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  schedulesList: action.schedulesList,
  schedulesLoading: false,
  schedulesError: null,
  schedulesListUpdateNeeded: false,
});

const fetchSchedulesFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  schedulesError: action.schedulesError,
  schedulesLoading: false,
});

const fetchScheduleStart = (state: ScheduleStateType): ScheduleStateType => ({
  ...state,
  scheduleLoading: true,
  scheduleUpdateSuccess: false,
  scheduleCreateSuccess: false,
  scheduleCreateError: null,
  scheduleUpdateError: null,
});

const fetchScheduleSuccess = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  schedule: action.schedule,
  scheduleLoading: false,
  scheduleError: null,
});

const fetchScheduleFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  scheduleError: action.scheduleError,
  scheduleLoading: false,
});

const createScheduleStart = (state: ScheduleStateType): ScheduleStateType => ({
  ...state,
  scheduleCreateLoading: true,
});

const createScheduleSuccess = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  scheduleCreateLoading: false,
  scheduleCreateError: null,
  scheduleCreateSuccess: true,
  createdSchedule: action.createdSchedule,
});

const createScheduleFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  scheduleCreateLoading: false,
  scheduleCreateError: action.scheduleCreateError,
});

const updateScheduleStart = (state: ScheduleStateType): ScheduleStateType => ({
  ...state,
  scheduleUpdateLoading: true,
});

const updateScheduleSuccess = (
  state: ScheduleStateType,
): ScheduleStateType => ({
  ...state,
  scheduleUpdateLoading: false,
  scheduleUpdateError: null,
  scheduleUpdateSuccess: true,
  scheduleEditUpdateNeeded: true,
});

const updateScheduleFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  scheduleUpdateLoading: false,
  scheduleUpdateError: action.scheduleUpdateError,
});

const deleteScheduleStart = (state: ScheduleStateType): ScheduleStateType => ({
  ...state,
  scheduleDeleteLoading: true,
  scheduleDeleteSuccess: false,
});

const deleteScheduleSuccess = (
  state: ScheduleStateType,
): ScheduleStateType => ({
  ...state,
  scheduleDeleteLoading: false,
  scheduleDeleteError: null,
  scheduleDeleteSuccess: true,
  schedulesListUpdateNeeded: true,
});

const deleteScheduleFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  scheduleDeleteLoading: false,
  scheduleDeleteError: action.scheduleDeleteError,
});

const fetchClientSchedulesStart = (
  state: ScheduleStateType,
): ScheduleStateType => ({
  ...state,
  clientSchedulesLoading: true,
});

const fetchClientSchedulesSuccess = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  clientSchedulesList: action.clientSchedulesList,
  clientSchedulesLoading: false,
  clientSchedulesError: null,
  clientSchedulesListUpdateNeeded: false,
});

const fetchClientSchedulesFail = (
  state: ScheduleStateType,
  action: ScheduleActionType,
): ScheduleStateType => ({
  ...state,
  clientSchedulesError: action.schedulesError,
  clientSchedulesLoading: false,
});

const resetScheduleStore = (): ScheduleStateType => ({
  schedulesList: null,
  schedulesLoading: true,
  schedulesError: null,
  schedulesListUpdateNeeded: false,
  scheduleCreateLoading: false,
  scheduleCreateError: null,
  scheduleCreateSuccess: false,
  createdSchedule: null,
  scheduleUpdateLoading: false,
  scheduleUpdateError: null,
  scheduleUpdateSuccess: false,
  scheduleDeleteLoading: false,
  scheduleDeleteSuccess: false,
  scheduleDeleteError: null,
  schedule: null,
  scheduleLoading: false,
  scheduleError: null,
  scheduleEditUpdateNeeded: false,
  clientSchedulesList: null,
  clientSchedulesLoading: false,
  clientSchedulesError: null,
  clientSchedulesListUpdateNeeded: false,
});

const reducer = (state = initialState, action: ScheduleActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SCHEDULES_START:
      return fetchSchedulesStart(state);
    case actionTypes.FETCH_SCHEDULES_SUCCESS:
      return fetchSchedulesSuccess(state, action);
    case actionTypes.FETCH_SCHEDULES_FAIL:
      return fetchSchedulesFail(state, action);
    case actionTypes.FETCH_SCHEDULE_START:
      return fetchScheduleStart(state);
    case actionTypes.FETCH_SCHEDULE_SUCCESS:
      return fetchScheduleSuccess(state, action);
    case actionTypes.FETCH_SCHEDULE_FAIL:
      return fetchScheduleFail(state, action);
    case actionTypes.CREATE_SCHEDULE_START:
      return createScheduleStart(state);
    case actionTypes.CREATE_SCHEDULE_SUCCESS:
      return createScheduleSuccess(state, action);
    case actionTypes.CREATE_SCHEDULE_FAIL:
      return createScheduleFail(state, action);
    case actionTypes.UPDATE_SCHEDULE_START:
      return updateScheduleStart(state);
    case actionTypes.UPDATE_SCHEDULE_SUCCESS:
      return updateScheduleSuccess(state);
    case actionTypes.UPDATE_SCHEDULE_FAIL:
      return updateScheduleFail(state, action);
    case actionTypes.DELETE_SCHEDULE_START:
      return deleteScheduleStart(state);
    case actionTypes.DELETE_SCHEDULE_SUCCESS:
      return deleteScheduleSuccess(state);
    case actionTypes.DELETE_SCHEDULE_FAIL:
      return deleteScheduleFail(state, action);
    case actionTypes.RESET_SCHEDULE_STORE:
      return resetScheduleStore();
    case actionTypes.FETCH_CLIENT_SCHEDULES_START:
      return fetchClientSchedulesStart(state);
    case actionTypes.FETCH_CLIENT_SCHEDULES_SUCCESS:
      return fetchClientSchedulesSuccess(state, action);
    case actionTypes.FETCH_CLIENT_SCHEDULES_FAIL:
      return fetchClientSchedulesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
