import * as actionTypes from './actionTypes';
import { TranslationActionTypes } from './actionTypes';
import { TranslationsList } from '../../domain/Translation';
import { HttpError } from '../../config/Axios/axios-instance';

export type TranslationStateType = {
  translationsList: TranslationsList | null;
  translationsListLoading: boolean;
  translationsListError: HttpError;
};

export type TranslationActionType = TranslationStateType & {
  type: TranslationActionTypes;
};

export const initialState: TranslationStateType = {
  translationsList: null,
  translationsListLoading: true,
  translationsListError: null,
};

const fetchTranslationsStart = (
  state: TranslationStateType,
): TranslationStateType => ({
  ...state,
  translationsListLoading: true,
});

const fetchTranslationsSuccess = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  translationsList: action.translationsList,
  translationsListLoading: false,
  translationsListError: null,
});

const fetchTranslationsFail = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  translationsListError: action.translationsListError,
  translationsListLoading: false,
});

const reducer = (state = initialState, action: TranslationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATIONS_START:
      return fetchTranslationsStart(state);
    case actionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return fetchTranslationsSuccess(state, action);
    case actionTypes.FETCH_TRANSLATIONS_FAIL:
      return fetchTranslationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
