import * as actionTypes from './actionTypes';
import { EventActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  DayEventsList,
  EventsList,
  Event,
  ClientEventsList,
  EventUpdateOverview,
} from '../../domain/Event';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';
import moment from 'moment';
import _ from 'lodash';
import { updateEventList } from '../../utility/event/eventHelper';

export const DEFAULT_EVENT_LIST_PARAMS: ListParams = {
  ...DEFAULT_LIST_PARAMS,
  sortBy: 'event.date',
  search: '',
  eventStatus: null,
  priceType: null,
  sortDirection: 'ASC',
  page: 0,
  limit: 100,
  date: moment().format('YYYY-MM-DD'),
};

export type EventStateType = {
  eventsList: DayEventsList | null;
  eventsLoading: boolean;
  eventsError: HttpError;
  eventsListUpdateNeeded: boolean;
  event: Event | null;
  eventLoading: boolean;
  eventError: HttpError;
  employeeEventsList: EventsList | null;
  employeeEventsLoading: boolean;
  employeeEventsError: HttpError;
  employeeEventsListUpdateNeeded: boolean;
  eventCompleteLoading: boolean;
  eventCompleteError: HttpError;
  eventCompleteSuccess: boolean;
  eventUpdateLoading: boolean;
  eventUpdateError: HttpError;
  eventUpdateSuccess: boolean;
  changeEventTeamLoading: boolean;
  changeEventTeamError: HttpError;
  changeEventTeamSuccess: boolean;
  changeEventDateLoading: boolean;
  changeEventDateError: HttpError;
  changeEventDateSuccess: boolean;
  refreshEventInvoiceLoading: boolean;
  refreshEventInvoiceError: HttpError;
  refreshEventInvoiceSuccess: boolean;
  eventDeleteLoading: boolean;
  eventDeleteError: HttpError;
  clientEventsList: ClientEventsList | null;
  clientEventsLoading: boolean;
  clientEventsError: HttpError;
  clientEventsListUpdateNeeded: boolean;
  setEventsPositionsLoading: boolean;
  setEventsPositionsSuccess: boolean;
  setEventsPositionsError: HttpError;
  eventCancelLoading: boolean;
  eventCancelError: HttpError;
  eventCancelSuccess: boolean;
  eventUncancelLoading: boolean;
  eventUncancelError: HttpError;
  eventUncancelSuccess: boolean;
  sendEventReviewLoading: boolean;
  sendEventReviewSuccess: boolean;
  sendEventReviewError: HttpError;
  eventForReview: Event | null;
  eventForReviewLoading: boolean;
  eventForReviewSuccess: boolean;
  eventForReviewError: HttpError;
  changeEventMoneyCollectedLoading: boolean;
  changeEventMoneyCollectedError: HttpError;
  changeEventMoneyCollectedSuccess: boolean;
  eventFilter: ListParams;
  eventConfirmLoading: boolean;
  eventConfirmError: HttpError;
  eventConfirmSuccess: boolean;
  eventUncompleteLoading: boolean;
  eventUncompleteError: HttpError;
  eventUncompleteSuccess: boolean;
  windowPosition: number;
  eventUpdateOverviewLoading: boolean;
  eventUpdateOverviewSuccess: boolean;
  eventUpdateOverviewError: HttpError;
  eventUpdateOverview: EventUpdateOverview | null;
  eventListTabId: string | null;
  keepFilters: boolean;
};

export type EventActionType = EventStateType & {
  type: EventActionTypes;
};

export const initialState: EventStateType = {
  eventsList: null,
  eventsLoading: true,
  eventsError: null,
  eventsListUpdateNeeded: false,
  event: null,
  eventLoading: true,
  eventError: null,
  employeeEventsList: null,
  employeeEventsLoading: true,
  employeeEventsError: null,
  employeeEventsListUpdateNeeded: false,
  eventCompleteLoading: false,
  eventCompleteError: null,
  eventCompleteSuccess: false,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: false,
  changeEventTeamLoading: false,
  changeEventTeamError: null,
  changeEventTeamSuccess: false,
  changeEventDateLoading: false,
  changeEventDateError: null,
  changeEventDateSuccess: false,
  refreshEventInvoiceLoading: false,
  refreshEventInvoiceError: null,
  refreshEventInvoiceSuccess: false,
  eventDeleteLoading: false,
  eventDeleteError: null,
  clientEventsList: null,
  clientEventsLoading: true,
  clientEventsError: null,
  clientEventsListUpdateNeeded: false,
  setEventsPositionsSuccess: false,
  setEventsPositionsLoading: true,
  setEventsPositionsError: null,
  eventCancelLoading: false,
  eventCancelError: null,
  eventCancelSuccess: false,
  eventUncancelLoading: false,
  eventUncancelError: null,
  eventUncancelSuccess: false,
  sendEventReviewSuccess: false,
  sendEventReviewLoading: true,
  sendEventReviewError: null,
  eventForReview: null,
  eventForReviewLoading: true,
  eventForReviewSuccess: false,
  eventForReviewError: null,
  changeEventMoneyCollectedLoading: false,
  changeEventMoneyCollectedError: null,
  changeEventMoneyCollectedSuccess: false,
  eventConfirmLoading: false,
  eventConfirmError: null,
  eventConfirmSuccess: false,
  eventUncompleteLoading: false,
  eventUncompleteError: null,
  eventUncompleteSuccess: false,
  eventUpdateOverviewLoading: false,
  eventUpdateOverviewSuccess: false,
  eventUpdateOverviewError: null,
  eventUpdateOverview: null,
  windowPosition: 0,
  eventListTabId: null,
  eventFilter: DEFAULT_EVENT_LIST_PARAMS,
  keepFilters: false,
};

const fetchEventsStart = (state: EventStateType): EventStateType => {
  if (!_.isEqual(initialState.eventFilter, state.eventFilter)) {
    return {
      ...state,
      windowPosition: 0,
      eventsLoading: true,
    };
  }

  return {
    ...state,
    eventsLoading: true,
  };
};

const fetchEventsSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventsList: action.eventsList,
  eventsLoading: false,
  eventsError: null,
  eventsListUpdateNeeded: false,
  eventCompleteSuccess: false,
  keepFilters: false,
});

const fetchEventsFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventsError: action.eventsError,
  eventsLoading: false,
});

const fetchEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateOverviewLoading: false,
  eventLoading: true,
});

const fetchEventSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  event: action.event,
  eventLoading: false,
  eventError: null,
});

const fetchEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventError: action.eventError,
  eventLoading: false,
});

const fetchEmployeeEventsStart = (state: EventStateType): EventStateType => ({
  ...state,
  employeeEventsLoading: true,
});

const fetchEmployeeEventsSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  employeeEventsList: action.employeeEventsList,
  employeeEventsLoading: false,
  employeeEventsError: null,
  employeeEventsListUpdateNeeded: false,
  eventCompleteSuccess: false,
});

const fetchEmployeeEventsFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  employeeEventsError: action.employeeEventsError,
  employeeEventsLoading: false,
});

const completeEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventCompleteLoading: true,
  eventCompleteSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const completeEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventCompleteLoading: false,
  eventCompleteError: null,
  eventCompleteSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const completeEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventCompleteLoading: false,
  eventCompleteError: action.eventCompleteError,
});

const resetCompleteEvent = (state: EventStateType): EventStateType => ({
  ...state,
  eventCompleteLoading: false,
  eventCompleteError: null,
  eventCompleteSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const updateEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateLoading: true,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const updateEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const updateEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventUpdateLoading: false,
  eventUpdateError: action.eventCompleteError,
});

const resetEventUpdate = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const resetEventTeamChange = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventTeamLoading: false,
  changeEventTeamError: null,
  changeEventTeamSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const changeEventTeamStart = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventTeamLoading: true,
  employeeEventsListUpdateNeeded: false,
});

const changeEventTeamSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventTeamLoading: false,
  changeEventTeamError: null,
  changeEventTeamSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const changeEventTeamFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  changeEventTeamLoading: false,
  changeEventTeamError: action.changeEventTeamError,
});

const resetEventDateChange = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventDateLoading: false,
  changeEventDateError: null,
  changeEventDateSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const changeEventDateStart = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventDateLoading: true,
  employeeEventsListUpdateNeeded: false,
});

const changeEventDateSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  changeEventDateLoading: false,
  changeEventDateError: null,
  changeEventDateSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const changeEventDateFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  changeEventDateLoading: false,
  changeEventDateError: action.changeEventDateError,
});

const refreshEventInvoiceStart = (state: EventStateType): EventStateType => ({
  ...state,
  refreshEventInvoiceLoading: true,
  eventsListUpdateNeeded: false,
  refreshEventInvoiceSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const refreshEventInvoiceSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  refreshEventInvoiceLoading: false,
  refreshEventInvoiceError: null,
  refreshEventInvoiceSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const refreshEventInvoiceFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  refreshEventInvoiceLoading: false,
  refreshEventInvoiceError: action.refreshEventInvoiceError,
});

const deleteEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventDeleteLoading: true,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const deleteEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventDeleteLoading: false,
  eventDeleteError: null,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const deleteEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventDeleteLoading: false,
  eventDeleteError: action.eventDeleteError,
});

const fetchClientEventsStart = (state: EventStateType): EventStateType => ({
  ...state,
  clientEventsLoading: true,
});

const fetchClientEventsSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  clientEventsList: action.clientEventsList,
  clientEventsLoading: false,
  clientEventsError: null,
});

const fetchClientEventsFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  clientEventsError: action.clientEventsError,
  clientEventsLoading: false,
});

const setEventsPositionsStart = (state: EventStateType): EventStateType => ({
  ...state,
  setEventsPositionsLoading: true,
  setEventsPositionsSuccess: false,
});

const setEventsPositionsSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  setEventsPositionsLoading: false,
  setEventsPositionsError: null,
  employeeEventsListUpdateNeeded: true,
  setEventsPositionsSuccess: true,
});

const setEventsPositionsFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  setEventsPositionsError: action.clientEventsError,
  setEventsPositionsSuccess: false,
  setEventsPositionsLoading: false,
});

const cancelEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventCancelLoading: true,
  eventCancelSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const cancelEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventCancelLoading: false,
  eventCancelError: null,
  eventCancelSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const cancelEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventCancelError: action.eventCancelError,
  eventCancelLoading: false,
  eventCancelSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const uncancelEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventUncancelSuccess: false,
  eventUncancelLoading: true,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const uncancelEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventUncancelLoading: false,
  eventUncancelError: null,
  eventUncancelSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const uncancelEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventUncancelError: action.eventUncancelError,
  eventUncancelLoading: false,
  eventUncancelSuccess: false,
});

const requestEventReviewStart = (state: EventStateType): EventStateType => ({
  ...state,
  sendEventReviewLoading: true,
  sendEventReviewSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const requestEventReviewSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  sendEventReviewLoading: false,
  sendEventReviewError: null,
  sendEventReviewSuccess: true,
  employeeEventsListUpdateNeeded: true,
});

const requestEventReviewFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventCancelLoading: false,
  eventCancelError: action.eventCancelError,
  sendEventReviewError: action.sendEventReviewError,
  sendEventReviewSuccess: false,
  sendEventReviewLoading: false,
});

const fetchEventForReviewStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventForReviewLoading: true,
});

const fetchEventForReviewSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventForReview: action.eventForReview,
  eventForReviewLoading: false,
  eventForReviewError: null,
  eventForReviewSuccess: false,
  employeeEventsListUpdateNeeded: true,
});

const fetchEventForReviewFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventForReviewError: action.eventForReviewError,
  eventForReviewLoading: false,
});

const changeEventMoneyCollectedStart = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  changeEventMoneyCollectedLoading: true,
  changeEventMoneyCollectedSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const changeEventMoneyCollectedSuccess = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  changeEventMoneyCollectedLoading: false,
  changeEventMoneyCollectedError: null,
  changeEventMoneyCollectedSuccess: true,
  eventsListUpdateNeeded: true,
});

const changeEventMoneyCollectedFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  changeEventMoneyCollectedLoading: false,
  changeEventMoneyCollectedError: action.changeEventMoneyCollectedError,
});

const resetChangeEventMoneyCollected = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  changeEventMoneyCollectedLoading: false,
  changeEventMoneyCollectedError: null,
  changeEventMoneyCollectedSuccess: false,
  employeeEventsListUpdateNeeded: false,
});

const resetEventStore = (state: EventStateType): EventStateType => ({
  ...state,
  eventsList: null,
  eventsLoading: true,
  eventsError: null,
  eventsListUpdateNeeded: false,
  event: null,
  eventLoading: true,
  eventError: null,
  employeeEventsList: null,
  employeeEventsLoading: true,
  employeeEventsError: null,
  employeeEventsListUpdateNeeded: false,
  eventCompleteLoading: false,
  eventCompleteError: null,
  eventCompleteSuccess: false,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: false,
  changeEventTeamLoading: false,
  changeEventTeamError: null,
  changeEventTeamSuccess: false,
  changeEventDateLoading: false,
  changeEventDateError: null,
  changeEventDateSuccess: false,
  refreshEventInvoiceLoading: false,
  refreshEventInvoiceError: null,
  refreshEventInvoiceSuccess: false,
  eventDeleteLoading: false,
  eventDeleteError: null,
  clientEventsList: null,
  clientEventsLoading: true,
  clientEventsError: null,
  clientEventsListUpdateNeeded: false,
  setEventsPositionsSuccess: false,
  setEventsPositionsLoading: true,
  setEventsPositionsError: null,
  eventCancelLoading: false,
  eventCancelError: null,
  eventCancelSuccess: false,
  eventUncancelLoading: false,
  eventUncancelError: null,
  eventUncancelSuccess: false,
  sendEventReviewSuccess: false,
  sendEventReviewLoading: true,
  sendEventReviewError: null,
  eventForReview: null,
  eventForReviewLoading: true,
  eventForReviewSuccess: false,
  eventForReviewError: null,
  changeEventMoneyCollectedLoading: false,
  changeEventMoneyCollectedError: null,
  changeEventMoneyCollectedSuccess: false,
  eventConfirmLoading: false,
  eventConfirmError: null,
  eventConfirmSuccess: false,
});

const setEventFilter = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventFilter: action.eventFilter,
});

const resetEventFilter = (state: EventStateType): EventStateType => {
  if (state.keepFilters) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    eventFilter: DEFAULT_EVENT_LIST_PARAMS,
  };
};

const setKeepFilters = (state: EventStateType): EventStateType => ({
  ...state,
  keepFilters: true,
});

const confirmEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventConfirmLoading: true,
  eventConfirmSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const confirmEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventConfirmLoading: false,
  eventConfirmError: null,
  eventConfirmSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const confirmEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  setEventsPositionsError: action.eventConfirmError,
  eventConfirmSuccess: false,
  eventConfirmLoading: false,
});

const resetConfirmEvent = (state: EventStateType): EventStateType => ({
  ...state,
  eventConfirmLoading: false,
  eventConfirmError: null,
  eventConfirmSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const resetRefreshEventInvoice = (state: EventStateType): EventStateType => ({
  ...state,
  refreshEventInvoiceLoading: false,
  refreshEventInvoiceError: null,
  refreshEventInvoiceSuccess: false,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const resetRequestEventReview = (state: EventStateType): EventStateType => ({
  ...state,
  sendEventReviewLoading: true,
  sendEventReviewSuccess: false,
  employeeEventsListUpdateNeeded: false,
  eventsListUpdateNeeded: false,
});

const setWindowPosition = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  windowPosition: action.windowPosition,
});

const resetWindowPosition = (state: EventStateType): EventStateType => ({
  ...state,
  windowPosition: 0,
});

const uncompleteEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventUncompleteSuccess: false,
  eventUncompleteLoading: true,
  eventsListUpdateNeeded: false,
  employeeEventsListUpdateNeeded: false,
});

const uncompleteEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventUncompleteLoading: false,
  eventUncompleteError: null,
  eventUncompleteSuccess: true,
  eventsListUpdateNeeded: true,
  employeeEventsListUpdateNeeded: true,
});

const uncompleteEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventUncompleteError: action.eventUncompleteError,
  eventUncompleteLoading: false,
  eventUncompleteSuccess: false,
});

const updateEventOverviewStart = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => {
  const { eventUpdateOverview } = action;
  const { eventsList } = state;

  if (!eventUpdateOverview?.id || !eventsList?.result) {
    return {
      ...state,
      eventUpdateOverview,
      eventUpdateOverviewSuccess: false,
      eventUpdateOverviewLoading: true,
    };
  }

  const updatedEventList = updateEventList(
    eventsList.result,
    eventUpdateOverview,
  );

  return {
    ...state,
    eventUpdateOverviewSuccess: false,
    eventUpdateOverviewLoading: true,
    eventsList: {
      ...eventsList,
      result: updatedEventList,
    },
  };
};

const updateEventOverviewSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateOverview: null,
  eventUpdateOverviewLoading: false,
  eventUpdateOverviewError: null,
});

const updateEventOverviewFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventUpdateOverview: null,
  eventUpdateOverviewError: action.eventUpdateOverviewError,
  eventUpdateOverviewLoading: false,
  eventUpdateOverviewSuccess: false,
});

const setEventListTabId = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventListTabId: action.eventListTabId,
});

const resetEventListTabId = (state: EventStateType): EventStateType => ({
  ...state,
  eventListTabId: null,
});

const reducer = (state = initialState, action: EventActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS_START:
      return fetchEventsStart(state);
    case actionTypes.FETCH_EVENTS_SUCCESS:
      return fetchEventsSuccess(state, action);
    case actionTypes.FETCH_EVENTS_FAIL:
      return fetchEventsFail(state, action);
    case actionTypes.FETCH_EVENT_START:
      return fetchEventStart(state);
    case actionTypes.FETCH_EVENT_SUCCESS:
      return fetchEventSuccess(state, action);
    case actionTypes.FETCH_EVENT_FAIL:
      return fetchEventFail(state, action);
    case actionTypes.FETCH_EMPLOYEE_EVENTS_START:
      return fetchEmployeeEventsStart(state);
    case actionTypes.FETCH_EMPLOYEE_EVENTS_SUCCESS:
      return fetchEmployeeEventsSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEE_EVENTS_FAIL:
      return fetchEmployeeEventsFail(state, action);
    case actionTypes.COMPLETE_EVENT_START:
      return completeEventStart(state);
    case actionTypes.RESET_EVENT_TEAM_CHANGE:
      return resetEventTeamChange(state);
    case actionTypes.COMPLETE_EVENT_SUCCESS:
      return completeEventSuccess(state);
    case actionTypes.COMPLETE_EVENT_FAIL:
      return completeEventFail(state, action);
    case actionTypes.CHANGE_EVENT_TEAM_START:
      return changeEventTeamStart(state);
    case actionTypes.CHANGE_EVENT_TEAM_SUCCESS:
      return changeEventTeamSuccess(state);
    case actionTypes.CHANGE_EVENT_TEAM_FAIL:
      return changeEventTeamFail(state, action);
    case actionTypes.RESET_EVENT_DATE_CHANGE:
      return resetEventDateChange(state);
    case actionTypes.CHANGE_EVENT_DATE_START:
      return changeEventDateStart(state);
    case actionTypes.CHANGE_EVENT_DATE_SUCCESS:
      return changeEventDateSuccess(state);
    case actionTypes.CHANGE_EVENT_DATE_FAIL:
      return changeEventDateFail(state, action);
    case actionTypes.REFRESH_EVENT_INVOICE_START:
      return refreshEventInvoiceStart(state);
    case actionTypes.REFRESH_EVENT_INVOICE_SUCCESS:
      return refreshEventInvoiceSuccess(state);
    case actionTypes.REFRESH_EVENT_INVOICE_FAIL:
      return refreshEventInvoiceFail(state, action);
    case actionTypes.DELETE_EVENT_START:
      return deleteEventStart(state);
    case actionTypes.DELETE_EVENT_SUCCESS:
      return deleteEventSuccess(state);
    case actionTypes.DELETE_EVENT_FAIL:
      return deleteEventFail(state, action);
    case actionTypes.UPDATE_EVENT_START:
      return updateEventStart(state);
    case actionTypes.UPDATE_EVENT_SUCCESS:
      return updateEventSuccess(state);
    case actionTypes.UPDATE_EVENT_FAIL:
      return updateEventFail(state, action);
    case actionTypes.RESET_EVENT_UPDATE:
      return resetEventUpdate(state);
    case actionTypes.FETCH_CLIENT_EVENTS_START:
      return fetchClientEventsStart(state);
    case actionTypes.FETCH_CLIENT_EVENTS_SUCCESS:
      return fetchClientEventsSuccess(state, action);
    case actionTypes.FETCH_CLIENT_EVENTS_FAIL:
      return fetchClientEventsFail(state, action);
    case actionTypes.SET_EVENTS_POSITIONS_START:
      return setEventsPositionsStart(state);
    case actionTypes.SET_EVENTS_POSITIONS_SUCCESS:
      return setEventsPositionsSuccess(state);
    case actionTypes.SET_EVENTS_POSITIONS_FAIL:
      return setEventsPositionsFail(state, action);
    case actionTypes.CANCEL_EVENT_START:
      return cancelEventStart(state);
    case actionTypes.CANCEL_EVENT_SUCCESS:
      return cancelEventSuccess(state);
    case actionTypes.CANCEL_EVENT_FAIL:
      return cancelEventFail(state, action);
    case actionTypes.UNCANCEL_EVENT_START:
      return uncancelEventStart(state);
    case actionTypes.UNCANCEL_EVENT_SUCCESS:
      return uncancelEventSuccess(state);
    case actionTypes.UNCANCEL_EVENT_FAIL:
      return uncancelEventFail(state, action);
    case actionTypes.REQUEST_EVENT_REVIEW_START:
      return requestEventReviewStart(state);
    case actionTypes.REQUEST_EVENT_REVIEW_SUCCESS:
      return requestEventReviewSuccess(state);
    case actionTypes.REQUEST_EVENT_REVIEW_FAIL:
      return requestEventReviewFail(state, action);
    case actionTypes.FETCH_EVENT_FOR_REVIEW_START:
      return fetchEventForReviewStart(state);
    case actionTypes.FETCH_EVENT_FOR_REVIEW_SUCCESS:
      return fetchEventForReviewSuccess(state, action);
    case actionTypes.FETCH_EVENT_FOR_REVIEW_FAIL:
      return fetchEventForReviewFail(state, action);
    case actionTypes.RESET_EVENT_STORE:
      return resetEventStore(state);
    case actionTypes.SET_EVENT_FILTER:
      return setEventFilter(state, action);
    case actionTypes.CHANGE_EVENT_MONEY_COLLECTED_START:
      return changeEventMoneyCollectedStart(state);
    case actionTypes.CHANGE_EVENT_MONEY_COLLECTED_SUCCESS:
      return changeEventMoneyCollectedSuccess(state);
    case actionTypes.CHANGE_EVENT_MONEY_COLLECTED_FAIL:
      return changeEventMoneyCollectedFail(state, action);
    case actionTypes.RESET_EVENT_FILTER:
      return resetEventFilter(state);
    case actionTypes.RESET_EVENT_MONEY_COLLECTED:
      return resetChangeEventMoneyCollected(state);
    case actionTypes.CONFIRM_EVENT_START:
      return confirmEventStart(state);
    case actionTypes.CONFIRM_EVENT_SUCCESS:
      return confirmEventSuccess(state);
    case actionTypes.CONFIRM_EVENT_FAIL:
      return confirmEventFail(state, action);
    case actionTypes.RESET_CONFIRM_EVENT:
      return resetConfirmEvent(state);
    case actionTypes.RESET_COMPLETE_EVENT:
      return resetCompleteEvent(state);
    case actionTypes.RESET_REFRESH_EVENT_INVOICE:
      return resetRefreshEventInvoice(state);
    case actionTypes.RESET_REQUEST_EVENT_REVIEW:
      return resetRequestEventReview(state);
    case actionTypes.SET_WINDOW_POSITION:
      return setWindowPosition(state, action);
    case actionTypes.RESET_WINDOW_POSITION:
      return resetWindowPosition(state);
    case actionTypes.UNCOMPLETE_EVENT_START:
      return uncompleteEventStart(state);
    case actionTypes.UNCOMPLETE_EVENT_SUCCESS:
      return uncompleteEventSuccess(state);
    case actionTypes.UNCOMPLETE_EVENT_FAIL:
      return uncompleteEventFail(state, action);
    case actionTypes.UPDATE_EVENT_OVERVIEW_START:
      return updateEventOverviewStart(state, action);
    case actionTypes.UPDATE_EVENT_OVERVIEW_SUCCESS:
      return updateEventOverviewSuccess(state);
    case actionTypes.UPDATE_EVENT_OVERVIEW_FAIL:
      return updateEventOverviewFail(state, action);
    case actionTypes.SET_EVENT_LIST_TAB_ID:
      return setEventListTabId(state, action);
    case actionTypes.RESET_EVENT_LIST_TAB_ID:
      return resetEventListTabId(state);
    case actionTypes.SET_KEEP_FILTERS:
      return setKeepFilters(state);
    default:
      return state;
  }
};

export default reducer;
