import { Profile } from './Profile';

export enum UserRole {
  // eslint-disable-next-line no-unused-vars
  USER = 'USER',
  // eslint-disable-next-line no-unused-vars
  EMPLOYEE = 'EMPLOYEE',
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  ACCOUNTANT = 'ACCOUNTANT',
  // eslint-disable-next-line no-unused-vars
  OWNER = 'OWNER',
  // eslint-disable-next-line no-unused-vars
}

export interface User {
  id: number;
  createdAt: Date;
  email: string;
  role: UserRole;
  profile: Profile;
  selectedCompanyId: number | null;
}

export type UserList = {
  result: User[];
  total: number;
};
