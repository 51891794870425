import * as actionTypes from './actionTypes';
import { InvoicesActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Invoice, InvoiceList } from '../../domain/Invoice';
import { ListParams } from '../../hooks/useList/useList';

export type InvoiceStateType = {
  invoiceList: InvoiceList | null;
  invoicesLoading: boolean;
  invoicesError: HttpError;
  invoiceListUpdateNeeded: boolean;
  invoice: Invoice | null;
  invoiceLoading: boolean;
  invoiceError: HttpError;
  sendInvoiceLoading: boolean;
  sendInvoiceSuccess: boolean;
  sendInvoiceError: HttpError;
  setInvoicePaidLoading: boolean;
  setInvoicePaidSuccess: boolean;
  setInvoicePaidError: HttpError;
  createInvoiceLoading: boolean;
  createInvoiceSuccess: boolean;
  createInvoiceError: HttpError;
  updateInvoiceLoading: boolean;
  updateInvoiceSuccess: boolean;
  updateInvoiceError: HttpError;
  downloadInvoiceSuccess: boolean;
  downloadInvoiceLoading: boolean;
  downloadInvoiceError: HttpError;
  downloadClientInvoiceLoading: boolean;
  downloadClientInvoiceSuccess: boolean;
  downloadClientInvoiceError: HttpError;
  previewInvoiceSuccess: boolean;
  previewInvoiceLoading: boolean;
  previewInvoiceError: HttpError;
  clientInvoicesList: InvoiceList | null;
  clientInvoiceLoading: boolean;
  clientInvoiceError: HttpError;
  downloadMultipleInvoicesSuccess: boolean;
  downloadMultipleInvoicesLoading: boolean;
  downloadMultipleInvoicesError: HttpError;
  invoiceFilter: ListParams;
  windowPosition: number;
  createCreditInvoiceLoading: boolean;
  createCreditInvoiceSuccess: boolean;
  createCreditInvoiceError: HttpError;
  updateCreditInvoiceLoading: boolean;
  updateCreditInvoiceSuccess: boolean;
  updateCreditInvoiceError: HttpError;
  invoiceDeleteLoading: boolean;
  invoiceDeleteSuccess: boolean;
  invoiceDeleteError: HttpError;
  unsetInvoicePaidLoading: boolean;
  unsetInvoicePaidSuccess: boolean;
  unsetInvoicePaidError: HttpError;
  copyInvoiceLinkLoading: boolean;
  copyInvoiceLinkError: HttpError;
  copiedInvoice: Invoice | null;
};

export type InvoiceActionType = InvoiceStateType & {
  type: InvoicesActionTypes;
};

export const initialState: InvoiceStateType = {
  invoiceList: null,
  invoicesLoading: false,
  invoicesError: null,
  invoiceListUpdateNeeded: false,
  invoice: null,
  invoiceLoading: false,
  invoiceError: null,
  sendInvoiceLoading: false,
  sendInvoiceSuccess: false,
  sendInvoiceError: null,
  setInvoicePaidLoading: false,
  setInvoicePaidSuccess: false,
  setInvoicePaidError: null,
  createInvoiceLoading: false,
  createInvoiceSuccess: false,
  createInvoiceError: null,
  updateInvoiceLoading: false,
  updateInvoiceSuccess: false,
  updateInvoiceError: null,
  downloadInvoiceSuccess: false,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
  downloadClientInvoiceLoading: false,
  downloadClientInvoiceSuccess: false,
  downloadClientInvoiceError: null,
  previewInvoiceSuccess: false,
  previewInvoiceLoading: false,
  previewInvoiceError: null,
  clientInvoicesList: null,
  clientInvoiceLoading: false,
  clientInvoiceError: null,
  downloadMultipleInvoicesSuccess: false,
  downloadMultipleInvoicesLoading: false,
  downloadMultipleInvoicesError: null,
  windowPosition: 0,
  createCreditInvoiceLoading: false,
  createCreditInvoiceSuccess: false,
  createCreditInvoiceError: null,
  updateCreditInvoiceLoading: false,
  updateCreditInvoiceSuccess: false,
  updateCreditInvoiceError: null,
  unsetInvoicePaidLoading: false,
  unsetInvoicePaidSuccess: false,
  unsetInvoicePaidError: null,
  invoiceFilter: {
    sortBy: 'invoiceNumber',
    search: '',
    sortDirection: 'DESC',
    page: 0,
    limit: 100,
  },
  invoiceDeleteLoading: true,
  invoiceDeleteSuccess: false,
  invoiceDeleteError: null,
  copyInvoiceLinkLoading: true,
  copiedInvoice: null,
  copyInvoiceLinkError: null,
};

const fetchInvoicesStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  invoicesLoading: true,
});

const fetchInvoicesSuccess = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoiceList: action.invoiceList,
  invoicesLoading: false,
  invoicesError: null,
  invoiceListUpdateNeeded: false,
});

const fetchInvoicesFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoicesError: action.invoicesError,
  invoicesLoading: false,
});

const fetchInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  invoiceLoading: true,
  invoiceError: null,
});

const fetchInvoiceSuccess = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoice: action.invoice,
  invoiceLoading: false,
  invoiceError: null,
});

const fetchInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoiceError: action.invoiceError,
  invoiceLoading: false,
});

const sendInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  sendInvoiceLoading: true,
  sendInvoiceSuccess: false,
  sendInvoiceError: null,
  invoiceListUpdateNeeded: false,
});

const sendInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  sendInvoiceSuccess: true,
  invoiceListUpdateNeeded: true,
  sendInvoiceLoading: false,
  sendInvoiceError: null,
});

const sendInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  sendInvoiceError: action.sendInvoiceError,
  sendInvoiceLoading: false,
});

const setInvoicePaidStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  setInvoicePaidLoading: true,
  setInvoicePaidSuccess: false,
  setInvoicePaidError: null,
  invoiceListUpdateNeeded: false,
});

const setInvoicePaidSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  setInvoicePaidSuccess: true,
  invoiceListUpdateNeeded: true,
  setInvoicePaidLoading: false,
  setInvoicePaidError: null,
});

const setInvoicePaidFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  setInvoicePaidError: action.sendInvoiceError,
  setInvoicePaidLoading: false,
});

const createInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  createInvoiceLoading: true,
});

const createInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  createInvoiceLoading: false,
  createInvoiceError: null,
  createInvoiceSuccess: true,
  invoiceListUpdateNeeded: false,
});

const createInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  createInvoiceError: action.invoicesError,
  createInvoiceLoading: false,
});

const updateInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  updateInvoiceLoading: true,
});

const updateInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  updateInvoiceLoading: false,
  updateInvoiceError: null,
  updateInvoiceSuccess: true,
  invoiceListUpdateNeeded: false,
});

const updateInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  updateInvoiceError: action.invoicesError,
  updateInvoiceLoading: false,
});

const downloadClientInvoiceStart = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  downloadClientInvoiceLoading: true,
});

const downloadClientInvoiceSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  downloadClientInvoiceLoading: false,
  downloadClientInvoiceError: null,
  downloadClientInvoiceSuccess: true,
});

const downloadClientInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  downloadClientInvoiceError: action.downloadClientInvoiceError,
  downloadClientInvoiceSuccess: false,
  downloadClientInvoiceLoading: false,
});

const downloadInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  downloadInvoiceSuccess: false,
  downloadInvoiceLoading: true,
});

const downloadInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  downloadInvoiceSuccess: true,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
});

const downloadInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  downloadInvoiceSuccess: false,
  downloadInvoiceError: action.downloadInvoiceError,
  downloadInvoiceLoading: false,
});

const resetCreateOrUpdateInvoice = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  createInvoiceLoading: true,
  createInvoiceError: null,
  createInvoiceSuccess: false,
  updateInvoiceLoading: true,
  updateInvoiceError: null,
  updateInvoiceSuccess: false,
  updateCreditInvoiceLoading: true,
  updateCreditInvoiceError: null,
  updateCreditInvoiceSuccess: false,
});

const fetchClientInvoicesFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  clientInvoiceError: action.clientInvoiceError,
  clientInvoiceLoading: false,
});

const fetchClientInvoiceStart = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  clientInvoiceLoading: true,
  clientInvoiceError: null,
});

const fetchClientInvoiceSuccess = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  clientInvoicesList: action.clientInvoicesList,
  clientInvoiceLoading: false,
  clientInvoiceError: null,
});

const downloadMultipleInvoicesStart = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  downloadMultipleInvoicesSuccess: false,
  downloadMultipleInvoicesLoading: true,
});

const downloadMultipleInvoicesSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  downloadMultipleInvoicesSuccess: true,
  downloadMultipleInvoicesLoading: false,
  downloadMultipleInvoicesError: null,
});

const downloadMultipleInvoicesFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  downloadMultipleInvoicesSuccess: false,
  downloadMultipleInvoicesError: action.downloadMultipleInvoicesError,
  downloadMultipleInvoicesLoading: false,
});

const setInvoiceFilter = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoiceFilter: action.invoiceFilter,
});

const resetInvoiceFilter = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  invoiceFilter: {
    sortBy: 'invoiceNumber',
    search: '',
    sortDirection: 'DESC',
    page: 0,
    limit: 100,
  },
});

const setInvoiceWindowPosition = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  windowPosition: action.windowPosition,
});

const resetInvoiceWindowPosition = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  windowPosition: 0,
});

const createCreditInvoiceStart = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  createCreditInvoiceLoading: true,
});

const createCreditInvoiceSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  createCreditInvoiceLoading: false,
  createCreditInvoiceError: null,
  createCreditInvoiceSuccess: true,
  invoiceListUpdateNeeded: true,
});

const createCreditInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  createCreditInvoiceError: action.invoicesError,
  createCreditInvoiceLoading: false,
});

const resetCreateCreditInvoice = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  createCreditInvoiceLoading: true,
  createCreditInvoiceError: null,
  createCreditInvoiceSuccess: false,
  invoiceListUpdateNeeded: false,
});

const updateCreditInvoiceStart = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  updateCreditInvoiceLoading: true,
});

const updateCreditInvoiceSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  updateCreditInvoiceLoading: false,
  updateCreditInvoiceError: null,
  updateCreditInvoiceSuccess: true,
  invoiceListUpdateNeeded: false,
});

const updateCreditInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  updateCreditInvoiceError: action.updateCreditInvoiceError,
  updateCreditInvoiceLoading: false,
});

const deleteInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  invoiceDeleteLoading: true,
  invoiceDeleteError: null,
  invoiceDeleteSuccess: false,
  invoiceListUpdateNeeded: false,
});

const deleteInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  invoiceDeleteLoading: false,
  invoiceDeleteSuccess: true,
  invoiceDeleteError: null,
  invoiceListUpdateNeeded: true,
});

const deleteInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  invoiceDeleteError: action.invoiceDeleteError,
  invoiceDeleteLoading: false,
});

const unsetInvoicePaidStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  unsetInvoicePaidLoading: true,
  unsetInvoicePaidSuccess: false,
  unsetInvoicePaidError: null,
  invoiceListUpdateNeeded: false,
});

const unsetInvoicePaidSuccess = (
  state: InvoiceStateType,
): InvoiceStateType => ({
  ...state,
  unsetInvoicePaidSuccess: true,
  invoiceListUpdateNeeded: true,
  unsetInvoicePaidLoading: false,
  unsetInvoicePaidError: null,
});

const unsetInvoicePaidFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  unsetInvoicePaidError: action.unsetInvoicePaidError,
  unsetInvoicePaidLoading: false,
});

const copyInvoiceLinkStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  copyInvoiceLinkLoading: true,
  copiedInvoice: null,
  unsetInvoicePaidSuccess: false,
});

const copyInvoiceLinkSuccess = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  copiedInvoice: action.copiedInvoice,
  copyInvoiceLinkLoading: false,
  copyInvoiceLinkError: null,
});

const copyInvoiceLinkFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  copyInvoiceLinkError: action.copyInvoiceLinkError,
  copiedInvoice: null,
  copyInvoiceLinkLoading: false,
});

const reducer = (state = initialState, action: InvoiceActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_START:
      return fetchInvoicesStart(state);
    case actionTypes.FETCH_INVOICES_SUCCESS:
      return fetchInvoicesSuccess(state, action);
    case actionTypes.FETCH_INVOICES_FAIL:
      return fetchInvoicesFail(state, action);
    case actionTypes.FETCH_INVOICE_START:
      return fetchInvoiceStart(state);
    case actionTypes.FETCH_INVOICE_SUCCESS:
      return fetchInvoiceSuccess(state, action);
    case actionTypes.FETCH_INVOICE_FAIL:
      return fetchInvoiceFail(state, action);
    case actionTypes.SEND_INVOICE_START:
      return sendInvoiceStart(state);
    case actionTypes.SEND_INVOICE_SUCCESS:
      return sendInvoiceSuccess(state);
    case actionTypes.SEND_INVOICE_FAIL:
      return sendInvoiceFail(state, action);
    case actionTypes.SET_INVOICE_PAID_START:
      return setInvoicePaidStart(state);
    case actionTypes.SET_INVOICE_PAID_SUCCESS:
      return setInvoicePaidSuccess(state);
    case actionTypes.SET_INVOICE_PAID_FAIL:
      return setInvoicePaidFail(state, action);
    case actionTypes.CREATE_INVOICE_START:
      return createInvoiceStart(state);
    case actionTypes.CREATE_INVOICE_SUCCESS:
      return createInvoiceSuccess(state);
    case actionTypes.CREATE_INVOICE_FAIL:
      return createInvoiceFail(state, action);
    case actionTypes.UPDATE_INVOICE_START:
      return updateInvoiceStart(state);
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      return updateInvoiceSuccess(state);
    case actionTypes.UPDATE_INVOICE_FAIL:
      return updateInvoiceFail(state, action);
    case actionTypes.DOWNLOAD_INVOICE_START:
      return downloadInvoiceStart(state);
    case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
      return downloadInvoiceSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_FAIL:
      return downloadInvoiceFail(state, action);
    case actionTypes.DOWNLOAD_CLIENT_INVOICE_START:
      return downloadClientInvoiceStart(state);
    case actionTypes.DOWNLOAD_CLIENT_INVOICE_SUCCESS:
      return downloadClientInvoiceSuccess(state);
    case actionTypes.DOWNLOAD_CLIENT_INVOICE_FAIL:
      return downloadClientInvoiceFail(state, action);
    case actionTypes.REST_UPDATE_OR_CREATE_INVOICE:
      return resetCreateOrUpdateInvoice(state);
    case actionTypes.FETCH_CLIENT_INVOICES_START:
      return fetchClientInvoiceStart(state);
    case actionTypes.FETCH_CLIENT_INVOICES_SUCCESS:
      return fetchClientInvoiceSuccess(state, action);
    case actionTypes.FETCH_CLIENT_INVOICES_FAIL:
      return fetchClientInvoicesFail(state, action);
    case actionTypes.DOWNLOAD_MULTIPLE_INVOICES_START:
      return downloadMultipleInvoicesStart(state);
    case actionTypes.DOWNLOAD_MULTIPLE_INVOICES_SUCCESS:
      return downloadMultipleInvoicesSuccess(state);
    case actionTypes.DOWNLOAD_MULTIPLE_INVOICES_FAIL:
      return downloadMultipleInvoicesFail(state, action);
    case actionTypes.SET_INVOICE_FILTER:
      return setInvoiceFilter(state, action);
    case actionTypes.RESET_INVOICE_FILTER:
      return resetInvoiceFilter(state);
    case actionTypes.SET_INVOICE_WINDOW_POSITION:
      return setInvoiceWindowPosition(state, action);
    case actionTypes.RESET_INVOICE_WINDOW_POSITION:
      return resetInvoiceWindowPosition(state);
    case actionTypes.CREATE_CREDIT_INVOICE_START:
      return createCreditInvoiceStart(state);
    case actionTypes.CREATE_CREDIT_INVOICE_SUCCESS:
      return createCreditInvoiceSuccess(state);
    case actionTypes.CREATE_CREDIT_INVOICE_FAIL:
      return createCreditInvoiceFail(state, action);
    case actionTypes.REST_CREATE_CREDIT_INVOICE:
      return resetCreateCreditInvoice(state);
    case actionTypes.UPDATE_CREDIT_INVOICE_START:
      return updateCreditInvoiceStart(state);
    case actionTypes.UPDATE_CREDIT_INVOICE_SUCCESS:
      return updateCreditInvoiceSuccess(state);
    case actionTypes.UPDATE_CREDIT_INVOICE_FAIL:
      return updateCreditInvoiceFail(state, action);
    case actionTypes.DELETE_INVOICE_START:
      return deleteInvoiceStart(state);
    case actionTypes.DELETE_INVOICE_SUCCESS:
      return deleteInvoiceSuccess(state);
    case actionTypes.DELETE_INVOICE_FAIL:
      return deleteInvoiceFail(state, action);
    case actionTypes.UNSET_INVOICE_PAID_START:
      return unsetInvoicePaidStart(state);
    case actionTypes.UNSET_INVOICE_PAID_SUCCESS:
      return unsetInvoicePaidSuccess(state);
    case actionTypes.UNSET_INVOICE_PAID_FAIL:
      return unsetInvoicePaidFail(state, action);
    case actionTypes.COPY_INVOICE_LINK_START:
      return copyInvoiceLinkStart(state);
    case actionTypes.COPY_INVOICE_LINK_SUCCESS:
      return copyInvoiceLinkSuccess(state, action);
    case actionTypes.COPY_INVOICE_LINK_FAIL:
      return copyInvoiceLinkFail(state, action);
    default:
      return state;
  }
};

export default reducer;
