import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://86c482df1705476981ef541f998cad45@o1101437.ingest.sentry.io/6213497',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
