import * as actionTypes from './actionTypes';
import { LocationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Location, LocationsList } from '../../domain/Location';

export type LocationStateType = {
  locationsList: LocationsList | null;
  locationsLoading: boolean;
  locationsError: HttpError;
  locationsListUpdateNeeded: boolean;
  locationCreateLoading: boolean;
  locationCreateError: HttpError;
  locationCreateSuccess: boolean;
  createdLocation: Location | null;
  locationUpdateLoading: boolean;
  locationUpdateError: HttpError;
  locationUpdateSuccess: boolean;
  locationDeleteLoading: boolean;
  locationDeleteError: HttpError;
  location: Location | null;
  locationLoading: boolean;
  locationError: HttpError;
  clientLocationsList: LocationsList | null;
  clientLocationsLoading: boolean;
  clientLocationsError: HttpError;
  clientLocationSuccess: boolean;
  clientLocationListUpdateNeeded: boolean;
  locationEntranceUploadLoading: boolean;
  locationEntranceUploadError: HttpError;
  locationEntranceUploadSuccess: boolean;
  locationVideoUploadLoading: boolean;
  locationVideoUploadError: HttpError;
  locationVideoUploadSuccess: boolean;
};

export type LocationActionType = LocationStateType & {
  type: LocationActionTypes;
};

export const initialState: LocationStateType = {
  locationsList: null,
  locationsLoading: true,
  locationsError: null,
  locationsListUpdateNeeded: false,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
  createdLocation: null,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: false,
  locationDeleteLoading: false,
  locationDeleteError: null,
  location: null,
  locationLoading: false,
  locationError: null,
  clientLocationsList: null,
  clientLocationsLoading: false,
  clientLocationsError: null,
  clientLocationSuccess: false,
  clientLocationListUpdateNeeded: false,
  locationEntranceUploadLoading: false,
  locationEntranceUploadError: null,
  locationEntranceUploadSuccess: false,
  locationVideoUploadLoading: false,
  locationVideoUploadError: null,
  locationVideoUploadSuccess: false,
};

const fetchLocationsStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationsLoading: true,
});

const fetchLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsList: action.locationsList,
  locationsLoading: false,
  locationsError: null,
  locationsListUpdateNeeded: false,
});

const fetchLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationsError: action.locationsError,
  locationsLoading: false,
});

const fetchLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationLoading: true,
  locationUpdateSuccess: false,
  locationCreateSuccess: false,
  locationCreateError: null,
  locationUpdateError: null,
});

const fetchLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  location: action.location,
  locationLoading: false,
  locationError: null,
});

const fetchLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationError: action.locationError,
  locationLoading: false,
});

const createLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationCreateLoading: true,
});

const createLocationSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: true,
  createdLocation: action.createdLocation,
});

const createLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationCreateLoading: false,
  locationCreateError: action.locationCreateError,
});

const updateLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationUpdateLoading: true,
});

const updateLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: null,
  locationUpdateSuccess: true,
});

const updateLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationUpdateLoading: false,
  locationUpdateError: action.locationUpdateError,
});

const deleteLocationStart = (state: LocationStateType): LocationStateType => ({
  ...state,
  locationDeleteLoading: true,
});

const deleteLocationSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: null,
  locationsListUpdateNeeded: true,
  clientLocationListUpdateNeeded: true,
});

const deleteLocationFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: action.locationDeleteError,
});

const fetchClientLocationsStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  clientLocationsLoading: true,
});

const fetchClientLocationsSuccess = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  clientLocationsList: action.clientLocationsList,
  clientLocationsLoading: false,
  clientLocationsError: null,
});

const fetchClientLocationsFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  clientLocationsError: action.locationError,
  clientLocationsLoading: false,
});

const uploadLocationEntranceStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationEntranceUploadLoading: true,
});

const uploadLocationEntranceSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationEntranceUploadLoading: false,
  locationEntranceUploadError: null,
  locationEntranceUploadSuccess: true,
});

const uploadLocationEntranceFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationEntranceUploadLoading: false,
  locationEntranceUploadError: action.locationEntranceUploadError,
});

const resetCreateOrUpdateLocation = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationDeleteLoading: false,
  locationDeleteError: null,
  locationsListUpdateNeeded: false,
  locationCreateLoading: false,
  locationCreateError: null,
  locationCreateSuccess: false,
});

const uploadLocationVideoStart = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationVideoUploadLoading: true,
});

const uploadLocationVideoSuccess = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationVideoUploadLoading: false,
  locationVideoUploadError: null,
  locationVideoUploadSuccess: true,
});

const uploadLocationVideoFail = (
  state: LocationStateType,
  action: LocationActionType,
): LocationStateType => ({
  ...state,
  locationVideoUploadLoading: false,
  locationVideoUploadError: action.locationVideoUploadError,
});

const resetClientLocationsList = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  clientLocationsList: null,
});

const resetCreatedLocation = (state: LocationStateType): LocationStateType => ({
  ...state,
  createdLocation: null,
});

const resetLocationEntranceUpload = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationEntranceUploadLoading: false,
  locationEntranceUploadError: null,
  locationEntranceUploadSuccess: false,
});

const resetLocationVideoUpload = (
  state: LocationStateType,
): LocationStateType => ({
  ...state,
  locationVideoUploadLoading: false,
  locationVideoUploadError: null,
  locationVideoUploadSuccess: false,
});

const reducer = (state = initialState, action: LocationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_START:
      return fetchLocationsStart(state);
    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationsSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_FAIL:
      return fetchLocationsFail(state, action);
    case actionTypes.FETCH_LOCATION_START:
      return fetchLocationStart(state);
    case actionTypes.FETCH_LOCATION_SUCCESS:
      return fetchLocationSuccess(state, action);
    case actionTypes.FETCH_LOCATION_FAIL:
      return fetchLocationFail(state, action);
    case actionTypes.CREATE_LOCATION_START:
      return createLocationStart(state);
    case actionTypes.CREATE_LOCATION_SUCCESS:
      return createLocationSuccess(state, action);
    case actionTypes.CREATE_LOCATION_FAIL:
      return createLocationFail(state, action);
    case actionTypes.UPDATE_LOCATION_START:
      return updateLocationStart(state);
    case actionTypes.UPDATE_LOCATION_SUCCESS:
      return updateLocationSuccess(state);
    case actionTypes.UPDATE_LOCATION_FAIL:
      return updateLocationFail(state, action);
    case actionTypes.DELETE_LOCATION_START:
      return deleteLocationStart(state);
    case actionTypes.DELETE_LOCATION_SUCCESS:
      return deleteLocationSuccess(state);
    case actionTypes.DELETE_LOCATION_FAIL:
      return deleteLocationFail(state, action);
    case actionTypes.RESET_CREATE_AND_UPDATE_LOCATION:
      return resetCreateOrUpdateLocation(action);
    case actionTypes.FETCH_CLIENT_LOCATIONS_START:
      return fetchClientLocationsStart(state);
    case actionTypes.FETCH_CLIENT_LOCATIONS_SUCCESS:
      return fetchClientLocationsSuccess(state, action);
    case actionTypes.UPLOAD_LOCATION_ENTRANCE_START:
      return uploadLocationEntranceStart(state);
    case actionTypes.UPLOAD_LOCATION_ENTRANCE_SUCCESS:
      return uploadLocationEntranceSuccess(state);
    case actionTypes.UPLOAD_LOCATION_ENTRANCE_FAIL:
      return uploadLocationEntranceFail(state, action);
    case actionTypes.UPLOAD_LOCATION_VIDEO_START:
      return uploadLocationVideoStart(state);
    case actionTypes.UPLOAD_LOCATION_VIDEO_SUCCESS:
      return uploadLocationVideoSuccess(state);
    case actionTypes.UPLOAD_LOCATION_VIDEO_FAIL:
      return uploadLocationVideoFail(state, action);
    case actionTypes.RESET_UPLOAD_LOCATION_VIDEO:
      return resetLocationVideoUpload(state);
    case actionTypes.FETCH_CLIENT_LOCATIONS_FAIL:
      return fetchClientLocationsFail(state, action);
    case actionTypes.RESET_CLIENT_LOCATIONS_LIST:
      return resetClientLocationsList(state);
    case actionTypes.RESET_CREATED_LOCATION:
      return resetCreatedLocation(state);
    case actionTypes.RESET_UPLOAD_LOCATION_ENTRANCE:
      return resetLocationEntranceUpload(state);
    default:
      return state;
  }
};

export default reducer;
