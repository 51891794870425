import * as actionTypes from './actionTypes';
import { ReviewActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ReviewStateType = {
  createReviewLoading: boolean;
  createReviewSuccess: boolean;
  createReviewError: HttpError;
};

export type ReviewActionType = ReviewStateType & {
  type: ReviewActionTypes;
};

export const initialState: ReviewStateType = {
  createReviewLoading: true,
  createReviewSuccess: false,
  createReviewError: null,
};

const createReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  createReviewLoading: true,
});

const createReviewSuccess = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  createReviewLoading: false,
  createReviewError: null,
  createReviewSuccess: true,
});

const createReviewFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  createReviewError: action.createReviewError,
  createReviewLoading: false,
});

const reducer = (state = initialState, action: ReviewActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_REVIEW_START:
      return createReviewStart(state);
    case actionTypes.CREATE_REVIEW_SUCCESS:
      return createReviewSuccess(state);
    case actionTypes.CREATE_REVIEW_FAIL:
      return createReviewFail(state, action);
    default:
      return state;
  }
};

export default reducer;
