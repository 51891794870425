import React, { ChangeEvent, ReactNode, useEffect, useRef } from 'react';
import styles from './FileInput.module.scss';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import {
  faCheckCircle,
  faExclamationCircle,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Asset } from '../../domain/Asset';
import ClearIcon from '@material-ui/icons/Clear';
import { HttpError } from '../../config/Axios/axios-instance';

type Props = {
  name: string;
  multiple?: boolean;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  maxFileCount?: number;
  errors?: Array<string>;
  label?: string | ReactNode;
  helperText?: string;
  asset?: Asset | null;
  fileHelperText?: string;
};

const FileInput = ({
  onFileChange,
  value,
  name,
  maxFileCount,
  multiple,
  errors,
  label,
  helperText,
  asset,
  onImageDelete,
  onSetValidationErrors,
  fileHelperText,
}: Props) => {
  const intl = useIntl();

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const getIcon = (): IconProp => {
    if (errors && errors.length > 0) {
      return faExclamationCircle as IconProp;
    }

    if (value && (!errors || errors.length === 0)) {
      return faCheckCircle as IconProp;
    }

    return faFileImport as IconProp;
  };

  const renderAddFileButton = () => {
    const hasValue = Array.isArray(value) ? !!value.length : !!value;
    const uploadedCount = Array.isArray(value) && value.length;

    return (
      <div className={styles.innerFileSelectContainer}>
        <FontAwesomeIcon
          className={cx(styles.innerFileIcon, {
            [styles.errorFile]: errors && errors.length > 0,
            [styles.successFile]: hasValue && (!errors || errors.length === 0),
          })}
          icon={getIcon() as IconProp}
          fixedWidth
          size="2x"
        />
        <span className={styles.innerFileSelectLabel}>
          {hasValue &&
          (!errors || errors.length === 0) &&
          (!multiple || (maxFileCount && uploadedCount === maxFileCount))
            ? translate(intl, 'INPUTS.READY_TO_UPLOAD', 'Ready to upload')
            : translate(
                intl,
                multiple ? 'INPUTS.UPLOAD_IMAGES' : 'INPUTS.UPLOAD_IMAGE',
                multiple ? 'Upload images' : 'Upload image',
              )}
        </span>
      </div>
    );
  };

  const renderImage = (value: File | string, index: number) => {
    return (
      <div key={`image-${index}`} className={styles.imageContainer}>
        {multiple && (
          <div className={styles.delete}>
            <ClearIcon
              color="inherit"
              onClick={() => {
                onImageDelete?.(name, value);

                if (uploadInputRef?.current) {
                  uploadInputRef.current.value = '';
                }
              }}
            />
          </div>
        )}
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt={value.name} />
        ) : (
          <img src={value.toString()} alt={value?.toString() || ''} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      multiple &&
      maxFileCount &&
      Array.isArray(value) &&
      maxFileCount < value.length &&
      onSetValidationErrors
    ) {
      onSetValidationErrors([
        {
          field: name,
          message: translate(
            intl,
            'VALIDATION.TOO_MANY_FILES',
            'Too many files',
          ),
        },
      ]);
    }
  }, [value, multiple, maxFileCount]);

  return (
    <div className={styles.fileFieldContainer}>
      <div className={styles.fileField}>
        <input
          color="primary"
          type="file"
          onChange={onFileChange}
          ref={uploadInputRef}
          name={name}
          multiple={multiple}
          accept=".jpeg,.jpg,.png"
        />
        <div className={styles.fileContainer}>
          <div
            className={styles.fileSelectedContainer}
            onClick={handleUploadCLick}
          >
            {value && !multiple
              ? renderImage(value as File | string, 0)
              : renderAddFileButton()}
          </div>
        </div>
        <div className={styles.label}>
          <h5>{label}</h5>
          <p>{translate(intl, 'INPUTS.MAX_FILE_SIZE', 'Max file size 10MB')}</p>
          <p>
            {translate(
              intl,
              'INPUTS.ALLOWED_FILE_EXTENSIONS',
              'Only .png, .jpg, .jpeg, extensions are allowed',
            )}
          </p>
          {fileHelperText && <p>{translate(intl, fileHelperText, '')}</p>}
          {multiple && maxFileCount && (
            <p>
              {translate(intl, 'Max :max files allowed', '').replace(
                ':max',
                maxFileCount.toString(),
              )}
            </p>
          )}
          {!multiple && !Array.isArray(asset) && (
            <p className={styles.selectedFileLabel}>
              {value && value instanceof File && (
                <FontAwesomeIcon
                  icon={faFileImport as IconProp}
                  fixedWidth
                  size="sm"
                  className={styles.selectedFileIcon}
                />
              )}
              <span>
                {value && value instanceof File
                  ? value.name
                  : asset?.name ?? ''}
              </span>
            </p>
          )}
          {errors &&
            errors.map((error, index) => (
              <div className={styles.fileError} key={`file-error-${index}`}>
                {error}
              </div>
            ))}
          {helperText && <p>{helperText}</p>}
        </div>
      </div>
      {multiple && Array.isArray(value) && !!value.length && (
        <div className={styles.multipleImageContainer}>
          {Array.isArray(value) &&
            value.map((image, index) =>
              renderImage(image as File | string, index),
            )}
        </div>
      )}
    </div>
  );
};

export default FileInput;
