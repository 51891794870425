import React, { ReactNode, Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import Layout from '../../common/Layout/Layout';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import * as authService from '../../store/auth/service';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as languageService from '../../store/language/service';
import * as userService from '../../store/user/service';
import { Language } from '../../domain/Language';
import { IntlProvider } from 'react-intl';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { lazyWithRetry } from '../../utility/lazy/lazyWithRetry';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { User, UserRole } from '../../domain/User';

const Login = lazyWithRetry(() => import('../../pages/Login/Login'));
const EventsList = lazyWithRetry(() => import('../../pages/Events/Events'));
const EventEdit = lazyWithRetry(() => import('../../pages/Events/EventEdit'));
const ScheduleEdit = lazyWithRetry(
  () => import('../../pages/Schedules/ScheduleEdit'),
);
const ScheduleCreate = lazyWithRetry(
  () => import('../../pages/Schedules/ScheduleCreate'),
);
const EmployeeEvents = lazyWithRetry(
  () => import('../../pages/Events/EmployeeEvents'),
);
const Employees = lazyWithRetry(
  () => import('../../pages/Employees/Employees'),
);
const EmployeeCreate = lazyWithRetry(
  () => import('../../pages/Employees/EmployeeCreate/EmployeeCreate'),
);
const EmployeeEdit = lazyWithRetry(
  () => import('../../pages/Employees/EmployeeEdit/EmployeeEdit'),
);
const Clients = lazyWithRetry(() => import('../../pages/Clients/Clients'));
const ClientCreate = lazyWithRetry(
  () => import('../../pages/Clients/ClientCreate/ClientCreate'),
);
const ClientEdit = lazyWithRetry(
  () => import('../../pages/Clients/ClientEdit/ClientEdit'),
);
const Teams = lazyWithRetry(() => import('../../pages/Teams/Teams'));
const TeamCreate = lazyWithRetry(() => import('../../pages/Teams/TeamCreate'));
const TeamEdit = lazyWithRetry(() => import('../../pages/Teams/TeamEdit'));
const Cities = lazyWithRetry(() => import('../../pages/Cities/Cities'));
const CityCreate = lazyWithRetry(() => import('../../pages/Cities/CityCreate'));
const CityEdit = lazyWithRetry(() => import('../../pages/Cities/CityEdit'));
const Locations = lazyWithRetry(
  () => import('../../pages/Locations/Locations'),
);
const Invoice = lazyWithRetry(() => import('../../pages/Invoice/Invoice'));
const Review = lazyWithRetry(() => import('../../pages/Review/Review'));
const LocationCreate = lazyWithRetry(
  () => import('../../pages/Locations/LocationCreate'),
);
const LocationEdit = lazyWithRetry(
  () => import('../../pages/Locations/LocationEdit'),
);
const Invoices = lazyWithRetry(() => import('../../pages/Invoices/Invoices'));
const CreateInvoice = lazyWithRetry(
  () => import('../../pages/Invoices/InvoiceCreate'),
);
const UpdateInvoice = lazyWithRetry(
  () => import('../../pages/Invoices/InvoiceEdit/InvoiceEdit'),
);
const UsersPage = lazyWithRetry(() => import('../../pages/Users/UserList'));
const UserCreate = lazyWithRetry(() => import('../../pages/Users/UserCreate'));
const UserEdit = lazyWithRetry(() => import('../../pages/Users/UsersEdit'));
const CompanyPage = lazyWithRetry(
  () => import('../../pages/Company/Companies'),
);
const CompanyCreate = lazyWithRetry(
  () => import('../../pages/Company/CompanyCreate'),
);
const CompanyEdit = lazyWithRetry(
  () => import('../../pages/Company/CompanyEdit/CompanyEdit'),
);
const SmsTemplatesPage = lazyWithRetry(
  () => import('../../pages/SmsTemplates/SmsTemplates'),
);
const SmsTemplateCreatePage = lazyWithRetry(
  () => import('../../pages/SmsTemplates/SmsTemplateCreate'),
);
const SmsTemplateEditPage = lazyWithRetry(
  () => import('../../pages/SmsTemplates/SmsTemplateEdit'),
);
const TimeOffsPage = lazyWithRetry(
  () => import('../../pages/TimeOffs/TimeOffs'),
);
const TimeOffCreatePage = lazyWithRetry(
  () => import('../../pages/TimeOffs/TimeOffCreate'),
);
const TimeOffEditPage = lazyWithRetry(
  () => import('../../pages/TimeOffs/TimeOffEdit'),
);

export type Props = {
  isAuthenticatedState: boolean;
  isInitCompleted: boolean;
  onTryAutoSignup: () => void;
  languageLoading: boolean;
  onLanguageInit: (locale: string) => void;
  onFetchCurrentUser: () => void;
  language: Language | null;
  currentUser: User | null;
  selectedCompanyChanged: boolean;
  isCurrentUserLoading: boolean;
};

export const Router = ({
  isAuthenticatedState,
  isInitCompleted,
  onTryAutoSignup,
  onLanguageInit,
  onFetchCurrentUser,
  language,
  currentUser,
  selectedCompanyChanged,
  isCurrentUserLoading,
}: Props) => {
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  const isAuthenticated = isAuthenticatedState && currentUser;

  useEffect(() => {
    onFetchCurrentUser();
  }, [isAuthenticatedState]);

  useEffect(() => {
    onLanguageInit('en');
  }, [onLanguageInit]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const authenticatedRoutes = (role: string): ReactNode => {
    switch (role) {
      case UserRole.ADMIN:
        return (
          <Switch>
            <Route path="/employees/:id/edit" exact component={EmployeeEdit} />
            <Route path="/employees/new" exact component={EmployeeCreate} />
            <Route path="/employees" exact component={Employees} />
            <Route path="/clients/:id/edit" exact component={ClientEdit} />
            <Route path="/clients/new" exact component={ClientCreate} />
            <Route path="/clients" exact component={Clients} />

            <Route path="/cities/:id/edit" exact component={CityEdit} />
            <Route path="/cities/new" exact component={CityCreate} />
            <Route path="/cities" exact component={Cities} />

            <Route path="/teams/:id/edit" exact component={TeamEdit} />
            <Route path="/teams/new" exact component={TeamCreate} />
            <Route path="/teams" exact component={Teams} />

            <Route path="/locations/:id/edit" exact component={LocationEdit} />
            <Route path="/locations/new" exact component={LocationCreate} />
            <Route path="/locations" exact component={Locations} />

            <Route path="/events/:id/edit/" exact component={EventEdit} />
            <Route path="/events" exact component={EventsList} />

            <Route path="/schedule/:id/edit/" exact component={ScheduleEdit} />
            <Route path="/schedules/new" exact component={ScheduleCreate} />

            <Route
              path="/invoice-client/:assetName"
              exact
              component={Invoice}
            />

            <Route path="/invoices" exact component={Invoices} />

            <Route path="/invoices/create" exact component={CreateInvoice} />

            <Route path="/invoices/:id/edit" exact component={UpdateInvoice} />

            <Route path="/review/:requestToken" exact component={Review} />

            <Route path="/users" exact component={UsersPage} />

            <Route path="/users/new" exact component={UserCreate} />

            <Route path="/users/:id/edit" exact component={UserEdit} />

            <Route path="/companies" exact component={CompanyPage} />

            <Route path="/companies/new" exact component={CompanyCreate} />

            <Route path="/companies/:id/edit" exact component={CompanyEdit} />

            <Route path="/sms-templates" exact component={SmsTemplatesPage} />

            <Route
              path="/sms-templates/new"
              exact
              component={SmsTemplateCreatePage}
            />

            <Route
              path="/sms-templates/:id/edit"
              exact
              component={SmsTemplateEditPage}
            />

            <Route path="/time-offs" exact component={TimeOffsPage} />

            <Route path="/time-offs/new" exact component={TimeOffCreatePage} />

            <Route
              path="/time-offs/:id/edit"
              exact
              component={TimeOffEditPage}
            />

            <Route path="/client/:invoiceLink" exact component={Invoice} />

            <Redirect to="/events" />
          </Switch>
        );
      case UserRole.OWNER:
        return (
          <Switch>
            <Route path="/employees/:id/edit" exact component={EmployeeEdit} />
            <Route path="/employees/new" exact component={EmployeeCreate} />
            <Route path="/employees" exact component={Employees} />
            <Route path="/clients/:id/edit" exact component={ClientEdit} />
            <Route path="/clients/new" exact component={ClientCreate} />
            <Route path="/clients" exact component={Clients} />

            <Route path="/cities/:id/edit" exact component={CityEdit} />
            <Route path="/cities/new" exact component={CityCreate} />
            <Route path="/cities" exact component={Cities} />

            <Route path="/teams/:id/edit" exact component={TeamEdit} />
            <Route path="/teams/new" exact component={TeamCreate} />
            <Route path="/teams" exact component={Teams} />

            <Route path="/locations/:id/edit" exact component={LocationEdit} />
            <Route path="/locations/new" exact component={LocationCreate} />
            <Route path="/locations" exact component={Locations} />

            <Route path="/events/:id/edit/" exact component={EventEdit} />
            <Route path="/events" exact component={EventsList} />

            <Route path="/schedule/:id/edit/" exact component={ScheduleEdit} />
            <Route path="/schedules/new" exact component={ScheduleCreate} />

            <Route
              path="/invoice-client/:assetName"
              exact
              component={Invoice}
            />

            <Route path="/invoices" exact component={Invoices} />

            <Route path="/invoices/create" exact component={CreateInvoice} />

            <Route path="/invoices/:id/edit" exact component={UpdateInvoice} />

            <Route path="/review/:requestToken" exact component={Review} />

            <Route path="/sms-templates" exact component={SmsTemplatesPage} />

            <Route
              path="/sms-templates/new"
              exact
              component={SmsTemplateCreatePage}
            />

            <Route
              path="/sms-templates/:id/edit"
              exact
              component={SmsTemplateEditPage}
            />

            <Route path="/time-offs" exact component={TimeOffsPage} />

            <Route path="/time-offs/new" exact component={TimeOffCreatePage} />

            <Route
              path="/time-offs/:id/edit"
              exact
              component={TimeOffEditPage}
            />

            <Route path="/client/:invoiceLink" exact component={Invoice} />

            <Redirect to="/events" />
          </Switch>
        );
      case UserRole.EMPLOYEE:
        return (
          <Switch>
            <Route path="/events" exact component={EmployeeEvents} />
            <Redirect to="/events" />
          </Switch>
        );
      case UserRole.ACCOUNTANT:
        return (
          <Switch>
            <Route path="/invoices" exact component={Invoices} />

            <Route path="/invoices/create" exact component={CreateInvoice} />

            <Route path="/invoices/:id/edit" exact component={UpdateInvoice} />
            <Redirect to="/invoices" />
          </Switch>
        );
      default:
        return (
          <Switch>
            <Route
              path="/invoice-client/:assetName"
              exact
              component={Invoice}
            />
            <Route path="/review/:requestToken" exact component={Review} />
            <Route path="/login" exact component={Login} />
            <Redirect to="/login" />
          </Switch>
        );
    }
  };

  if (isCurrentUserLoading) {
    return <Loader isLoading isFullScreen />;
  }

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      {isInitCompleted ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? 'en'}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Layout isAuthenticated={isAuthenticated as boolean}>
              <Suspense fallback={<Loader isLoading isFullScreen />}>
                {isAuthenticatedState && currentUser ? (
                  authenticatedRoutes(currentUser.role)
                ) : (
                  <Switch>
                    <Route
                      path="/client/:invoiceLink"
                      exact
                      component={Invoice}
                    />
                    <Route
                      path="/review/:requestToken"
                      exact
                      component={Review}
                    />
                    <Route path="/login" exact component={Login} />
                    <Redirect to="/login" />
                  </Switch>
                )}
              </Suspense>
            </Layout>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticatedState: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  isInitCompleted: state.auth.isInitCompleted,
  languageLoading: state.language.languageLoading,
  language: state.language.language,
  currentUser: state.user.currentUser,
  selectedCompanyChanged: state.user.userSetSelectedCompanySuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onLanguageInit: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
