import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  ListItemText,
  TextField,
} from '@material-ui/core';
import styles from '../TextField/TextField.module.scss';
import cx from 'classnames';
import Button from '../Button/Button';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';

export type AutocompleteOptionProps = {
  label: string;
  value: string;
};

export type AutocompleteProps = {
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  errors?: string[];
  id?: string;
  label?: string;
  loading?: boolean;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: any, name: string, freeSolo: boolean) => void;
  options: AutocompleteOptionProps[];
  placeholder?: string;
  required?: boolean;
  value?: string | string[];
  multiple?: boolean;
  freeSolo?: boolean;
  disableClearable?: boolean;
  onCreate?: () => void;
  creatable?: boolean;
};

const Autocomplete = ({
  id,
  disabled,
  label,
  required,
  options = [],
  onChange,
  onCreate,
  name,
  onBlur,
  loading,
  errors,
  disableCloseOnSelect,
  placeholder,
  multiple = false,
  freeSolo = false,
  value,
  disableClearable = false,
  creatable,
}: AutocompleteProps) => {
  const intl = useIntl();

  const getSelectionValue = () => {
    if (Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    }

    if (multiple) {
      const values = value?.toString().split(',');
      return options.filter(
        (option) =>
          !!values?.find(
            (singleValue) =>
              singleValue?.toString() === option.value?.toString(),
          ),
      );
    }

    return options.find(
      (option) => option.value.toString() === value?.toString(),
    );
  };

  return (
    <MuiAutocomplete
      id={id}
      freeSolo={freeSolo}
      multiple={multiple}
      options={options}
      filterSelectedOptions
      getOptionLabel={(option: AutocompleteOptionProps) => option.label}
      getOptionDisabled={(option) => option.value === 'blocked'}
      value={getSelectionValue()}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      disableClearable={disableClearable}
      className={styles.autocomplete}
      onChange={(_, values) => {
        if (Array.isArray(values)) {
          return onChange?.(
            (values as AutocompleteOptionProps[]).map((value) => {
              if (typeof value === 'string') {
                return value;
              }

              return value.value;
            }),
            name,
            freeSolo,
          );
        }

        if (typeof values === 'object') {
          return onChange?.(values?.value || '', name, freeSolo);
        }

        onChange?.(values?.valueOf() || '', name, freeSolo);
      }}
      onBlur={onBlur}
      loading={loading}
      renderOption={(props, item) => (
        <li {...props} key={item.value}>
          <ListItemText>{item.label}</ListItemText>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          helperText={errors && errors.length > 0 ? errors[0] : ''}
          error={errors && errors.length > 0}
          required={required}
          name={name}
          className={cx(styles.textField)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {creatable && (
                  <Button buttonVariant="text" onClick={() => onCreate?.()}>
                    {translate(intl, 'AUTOCOMPLETE.CREATE', 'Create ')}
                  </Button>
                )}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
