import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useIntl } from 'react-intl';
import styles from './CompanySelectForm.module.scss';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import Alert from '../../../common/Alert/Alert';
import TextField from '../../../common/TextField/TextField';
import { useForm } from '../../../hooks/useForm/useForm';
import Button from '../../../common/Button/Button';
import { HttpError } from '../../../config/Axios/axios-instance';
import Form from '../../../common/Form/Form';
import { translate } from '../../../utility/messageTranslator/translate';
import Autocomplete from '../../../common/Autocomplete/Autocomplete';
import { Company } from '../../../domain/Company';
import * as companyService from '../../../store/company/service';
import { useHistory } from 'react-router-dom';
import { setUserSelectedCompany } from '../../../store/user/service';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  companyOptions: Company[];
  onCompanyFetch: () => void;
  setUserSelectedCompany: (companyId: number) => void;
  onSuccessSelect?: () => void;
  selectedCompany?: Company | null;
};

export const CompanySelectForm = ({
  isLoading,
  error,
  companyOptions,
  onCompanyFetch,
  onSuccessSelect,
  setUserSelectedCompany,
  selectedCompany,
}: Props) => {
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    onCompanyFetch();
  }, []);

  const companiesOptions = useMemo(
    () =>
      companyOptions.map((company: Company) => {
        return {
          value: company.id.toString(),
          label: company.name,
        };
      }) || [],
    [companyOptions],
  );

  const INPUTS = [
    {
      name: 'companyId',
      label: translate(
        intl,
        'COMPANY_SELECT_FORM.COMPANY_OPTIONS',
        'Company options',
      ),
      type: 'autocomplete',
      options: companiesOptions,
      value: selectedCompany?.id.toString() ?? '',
    },
  ];

  const handleSubmit = async (submitInputs: any) => {
    if (submitInputs?.companyId) {
      setUserSelectedCompany(Number(submitInputs?.companyId));
      onSuccessSelect?.();
      setTimeout(() => {
        history.push('/');
      }, 50);
    }
  };

  useEffect(() => {
    if (companiesOptions) {
      setNewInputObject('companyId', {
        options: companiesOptions,
      });
    }
  }, [companiesOptions]);

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    onTimeChange,
    onCheckboxChange,
    setNewInputObject,
  } = useForm(INPUTS, handleSubmit);

  useEffect(() => {
    if (companiesOptions) {
      setNewInputObject('companyId', {
        options: companiesOptions,
      });
    }
  }, [companiesOptions]);

  return (
    <div className={styles.companySelectForm}>
      <Form error={error} onSubmit={onSubmit}>
        <div className={styles.mainInputs}>
          {error && (
            <Alert variant="danger" capitalize={false}>
              {error}
            </Alert>
          )}
          {inputs.map((input) =>
            input.type === 'autocomplete' &&
            input.options &&
            input.options.length > 0 ? (
              <Autocomplete
                key={input.name}
                id={input.name}
                label={input.label}
                name={input.name}
                value={input.value?.toString() ?? ''}
                options={input.options || []}
                onChange={onSelectChange}
                onBlur={onLoseInputFocus}
                errors={input.validationErrors ?? []}
                disableClearable={input.disableClearable}
                multiple={input.multiple}
                freeSolo={input.freeSolo}
              />
            ) : (
              <TextField
                key={input.name}
                onChange={onInputChange}
                value={input.value?.toString() ?? ''}
                label={input.label ?? ''}
                errors={input.validationErrors ?? []}
                onBlur={onLoseInputFocus}
                name={input.name}
                type={input.type}
                options={input.options}
                onTimeChange={onTimeChange}
                onCheckboxChange={onCheckboxChange}
              />
            ),
          )}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="button"
            endIcon="&nbsp;"
            isLoading={isLoading}
          >
            {translate(
              intl,
              'COMPANY_SELECT_FORM.SELECT_COMPANY',
              'Select company',
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.company.companyOptionsError,
  isLoading: state.company.companyOptionsLoading,
  companyOptions: state.company.companyOptions,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyFetch: () => dispatch(companyService.fetchCompanyOptions()),
  setUserSelectedCompany: (selectCompanyId: number) =>
    dispatch(setUserSelectedCompany(selectCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectForm);
