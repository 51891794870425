export const FETCH_SCHEDULES_START = 'FETCH_SCHEDULES_START';
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';
export const FETCH_SCHEDULES_FAIL = 'FETCH_SCHEDULES_FAIL';

export const FETCH_SCHEDULE_START = 'FETCH_SCHEDULE_START';
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';
export const FETCH_SCHEDULE_FAIL = 'FETCH_SCHEDULE_FAIL';

export const CREATE_SCHEDULE_START = 'CREATE_SCHEDULE_START';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAIL = 'CREATE_SCHEDULE_FAIL';

export const UPDATE_SCHEDULE_START = 'UPDATE_SCHEDULES_START';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULES_SUCCESS';
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULES_FAIL';

export const DELETE_SCHEDULE_START = 'DELETE_SCHEDULE_START';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAIL = 'DELETE_SCHEDULE_FAIL';

export const FETCH_CLIENT_SCHEDULES_START = 'FETCH_CLIENT_SCHEDULES_START';
export const FETCH_CLIENT_SCHEDULES_SUCCESS = 'FETCH_CLIENT_SCHEDULES_SUCCESS';
export const FETCH_CLIENT_SCHEDULES_FAIL = 'FETCH_CLIENT_SCHEDULES_FAIL';

export const RESET_SCHEDULE_STORE = 'RESET_SCHEDULE_STORE';

export type ScheduleActionTypes =
  | typeof FETCH_SCHEDULES_START
  | typeof FETCH_SCHEDULES_SUCCESS
  | typeof FETCH_SCHEDULES_FAIL
  | typeof FETCH_SCHEDULE_START
  | typeof FETCH_SCHEDULE_SUCCESS
  | typeof FETCH_SCHEDULE_FAIL
  | typeof CREATE_SCHEDULE_START
  | typeof CREATE_SCHEDULE_SUCCESS
  | typeof CREATE_SCHEDULE_FAIL
  | typeof UPDATE_SCHEDULE_START
  | typeof UPDATE_SCHEDULE_SUCCESS
  | typeof UPDATE_SCHEDULE_FAIL
  | typeof DELETE_SCHEDULE_START
  | typeof DELETE_SCHEDULE_SUCCESS
  | typeof DELETE_SCHEDULE_FAIL
  | typeof RESET_SCHEDULE_STORE
  | typeof FETCH_CLIENT_SCHEDULES_START
  | typeof FETCH_CLIENT_SCHEDULES_SUCCESS
  | typeof FETCH_CLIENT_SCHEDULES_FAIL;
