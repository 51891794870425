import { ChangeEvent, useEffect, useState } from 'react';
import {
  getValidationError,
  ValidationRule,
} from '../../utility/validation/validation';
import _, { trim } from 'lodash';
import { useIntl } from 'react-intl';
import { HttpError } from '../../config/Axios/axios-instance';
import { Asset } from '../../domain/Asset';

const IGNORED_TRIM = ['password', 'file', 'autocomplete', 'video'];

export type LabelPlacement = 'end' | 'start' | 'top' | 'bottom';

export type FormInputBlueprint = {
  name: string;
  label?: string;
  type: string;
  validation?: Array<ValidationRule>;
  value?: string | string[] | number | File | File[] | null | any[];
  isHidden?: boolean;
  options?: Array<any>;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  capitalize?: boolean;
  locale?: string;
  disableClearable?: boolean;
  multiple?: boolean;
  freeSolo?: boolean;
  creatable?: boolean;
  alternativeNames?: string[];
  labelPlacement?: LabelPlacement;
  asset?: Asset | null;
  maxFileCount?: number;
};

export type FormInput = FormInputBlueprint & {
  isValidatable?: boolean;
  validationErrors?: Array<string>;
};

export type FormSubmitInput = {
  [key: string]: string;
};

export type FormBehavior = {
  submitOnChange?: boolean;
};

export const useForm = <T>(
  inputBlueprints: Array<FormInputBlueprint>,
  onFormSubmit?: (inputs: T) => void,
  isUpdateNeeded?: boolean,
  inputChangeCallback?: (
    newValue: string | string[] | undefined,
    name?: string,
  ) => void,
  dateInputChangeCallback?: (newValue: string) => void,
  formBehavior?: FormBehavior,
) => {
  const intl = useIntl();
  const [inputs, setInputs] = useState<Array<FormInput>>(inputBlueprints);

  useEffect(() => {
    if (isUpdateNeeded) {
      setInputs(inputBlueprints);
    }
  }, [isUpdateNeeded]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              value: prevInput.capitalize
                ? event.target.value.toUpperCase()
                : event.target.value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput },
      ),
    );

    inputChangeCallback && inputChangeCallback(undefined);
  };

  const onCheckboxChange = (name: string, value: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onTimeChange = (name: string, value: string) => {
    setInputs((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      );

      inputChangeCallback && inputChangeCallback(value);

      if (formBehavior?.submitOnChange) {
        onFormSubmit && onFormSubmit(getSubmitInputs(newInputs));
      }

      return newInputs;
    });

    dateInputChangeCallback && dateInputChangeCallback(value);
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const files = event.target.files ? Array.from(event.target.files) : [];

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? [...prevInput.value, ...files]
                  : files[0],
              isValidatable: true,
              validationErrors: files.length
                ? getValidationError(
                    prevInput.multiple ? files : files[0],
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onSelectChange = (
    value: string | string[],
    name?: string,
    freeSolo?: boolean,
  ) => {
    setInputs((prevState) =>
      prevState.map((prevInput) => {
        const getOptions = () => {
          const optionLenght = value.length - 1;

          const optionExists = prevInput.options?.find(
            (option) => option.value === value[optionLenght],
          );

          if (optionExists) {
            return prevInput?.options ? [...prevInput.options] : undefined;
          }

          if (freeSolo) {
            return prevInput.options
              ? [
                  ...prevInput.options,
                  { value: value[optionLenght], label: value[optionLenght] },
                ]
              : undefined;
          }

          return prevInput.options ? [...prevInput.options] : undefined;
        };

        return prevInput.name === name
          ? {
              ...prevInput,
              value,
              options: getOptions(),
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput };
      }),
    );
    inputChangeCallback && inputChangeCallback(value, name);
  };

  const onClearInput = (name: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onLoseInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              isValidatable: true,
              validationErrors: getValidationError(
                prevInput.value instanceof File
                  ? ''
                  : (IGNORED_TRIM.includes(prevInput.type)
                      ? prevInput.value
                      : trim(prevInput.value?.toString())) || '',
                prevInput.validation,
                intl,
              ),
            }
          : { ...prevInput },
      ),
    );
  };

  const setNewInputObject = (name: string, newValue: any) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              ...newValue,
            }
          : { ...prevInput },
      ),
    );
  };

  const onSubmit = (event?: ChangeEvent) => {
    event?.preventDefault();

    setInputs(
      inputs.map((input) => ({
        ...input,
        validationErrors: [],
      })),
    );

    const validatedInputs = inputs.map((input) => ({
      ...input,
      validationErrors:
        input.type === 'file'
          ? input.validationErrors
          : getValidationError(
              input.value instanceof File
                ? ''
                : (IGNORED_TRIM.includes(input.type)
                    ? input.value
                    : trim(input.value?.toString())) || '',
              input.validation,
              intl,
            ),
    }));

    if (
      _.find(
        validatedInputs,
        (validatedInput) => !_.isEmpty(validatedInput.validationErrors),
      )
    ) {
      setInputs(validatedInputs);
      return;
    }

    onFormSubmit &&
      onFormSubmit(
        Object.assign(
          {},
          ...inputs.map((input) => ({
            [input.name]: IGNORED_TRIM.includes(input.type)
              ? input.value
              : trim(input.value?.toString()),
          })),
        ),
      );
  };

  const getSubmitInputs = (submitInputs: Array<FormInput>) =>
    Object.assign(
      {},
      ...submitInputs.map((input) => ({
        [input.name]:
          IGNORED_TRIM.includes(input.type) || Array.isArray(input.value)
            ? input.value
            : trim(input.value?.toString()),
      })),
    );

  const onFileDelete = (name: string, file: string | File) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? prevInput.value.filter((val: string | File) => {
                      if (file instanceof File && val instanceof File) {
                        return file.name !== val.name;
                      }

                      return val !== file;
                    })
                  : '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onSetValidationErrors = (error: HttpError) => {
    if (_.isArray(error)) {
      error.forEach((fieldError) => {
        setInputs((prevState) =>
          prevState.map((prevInput) =>
            prevInput.name === fieldError.field ||
            prevInput.alternativeNames?.includes(fieldError.field)
              ? {
                  ...prevInput,
                  isValidatable: true,
                  validationErrors: [fieldError.message],
                }
              : { ...prevInput },
          ),
        );
      });
    }
  };

  return {
    inputs,
    onInputChange,
    onCheckboxChange,
    onTimeChange,
    onFileChange,
    onLoseInputFocus,
    onSubmit,
    onClearInput,
    setNewInputObject,
    onSelectChange,
    getSubmitInputs,
    onFileDelete,
    onSetValidationErrors,
  };
};
