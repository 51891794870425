export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';

export const FETCH_EVENT_START = 'FETCH_EVENT_START';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAIL = 'FETCH_EVENT_FAIL';

export const FETCH_EMPLOYEE_EVENTS_START = 'FETCH_EMPLOYEE_EVENTS_START';
export const FETCH_EMPLOYEE_EVENTS_SUCCESS = 'FETCH_EMPLOYEE_EVENTS_SUCCESS';
export const FETCH_EMPLOYEE_EVENTS_FAIL = 'FETCH_EMPLOYEE_EVENTS_FAIL';

export const FETCH_CLIENT_EVENTS_START = 'FETCH_CLIENT_EVENTS_START';
export const FETCH_CLIENT_EVENTS_SUCCESS = 'FETCH_CLIENT_EVENTS_SUCCESS';
export const FETCH_CLIENT_EVENTS_FAIL = 'FETCH_CLIENT_EVENTS_FAIL';

export const RESET_EVENT_UPDATE = 'RESET_EVENT_UPDATE';
export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const COMPLETE_EVENT_START = 'COMPLETE_EVENT_START';
export const COMPLETE_EVENT_SUCCESS = 'COMPLETE_EVENT_SUCCESS';
export const COMPLETE_EVENT_FAIL = 'COMPLETE_EVENT_FAIL';
export const RESET_COMPLETE_EVENT = 'RESET_COMPLETE_EVENT';

export const RESET_EVENT_TEAM_CHANGE = 'RESET_EVENT_TEAM_CHANGE';
export const CHANGE_EVENT_TEAM_START = 'CHANGE_EVENT_TEAM_START';
export const CHANGE_EVENT_TEAM_SUCCESS = 'CHANGE_EVENT_TEAM_SUCCESS';
export const CHANGE_EVENT_TEAM_FAIL = 'CHANGE_EVENT_TEAM_FAIL';

export const RESET_EVENT_DATE_CHANGE = 'RESEST_EVENT_DATE_CHANGE';
export const CHANGE_EVENT_DATE_START = 'CHANGE_EVENT_DATE_START';
export const CHANGE_EVENT_DATE_SUCCESS = 'CHANGE_EVENT_DATE_SUCCESS';
export const CHANGE_EVENT_DATE_FAIL = 'CHANGE_EVENT_DATE_FAIL';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const REFRESH_EVENT_INVOICE_START = 'REFRESH_EVENT_INVOICE_START';
export const REFRESH_EVENT_INVOICE_SUCCESS = 'REFRESH_EVENT_INVOICE_SUCCESS';
export const REFRESH_EVENT_INVOICE_FAIL = 'REFRESH_EVENT_INVOICE_FAIL';
export const RESET_REFRESH_EVENT_INVOICE = 'RESET_REFRESH_EVENT_INVOICE';

export const SET_EVENTS_POSITIONS_START = 'SET_EVENTS_POSITIONS_START';
export const SET_EVENTS_POSITIONS_SUCCESS = 'SET_EVENTS_POSITIONS_SUCCESS';
export const SET_EVENTS_POSITIONS_FAIL = 'SET_EVENTS_POSITIONS_FAIL';

export const CANCEL_EVENT_START = 'CANCEL_EVENT_START';
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_FAIL = 'CANCEL_EVENT_FAIL';

export const UNCANCEL_EVENT_START = 'UNCANCEL_EVENT_START';
export const UNCANCEL_EVENT_SUCCESS = 'UNCANCEL_EVENT_SUCCESS';
export const UNCANCEL_EVENT_FAIL = 'UNCANCEL_EVENT_FAIL';

export const UNCOMPLETE_EVENT_START = 'UNCOMPLETE_EVENT_START';
export const UNCOMPLETE_EVENT_SUCCESS = 'UNCOMPLETE_EVENT_SUCCESS';
export const UNCOMPLETE_EVENT_FAIL = 'UNCOMPLETE_EVENT_FAIL';

export const REQUEST_EVENT_REVIEW_START = 'REQUEST_EVENT_REVIEW_START';
export const REQUEST_EVENT_REVIEW_SUCCESS = 'REQUEST_EVENT_REVIEW_SUCCESS';
export const REQUEST_EVENT_REVIEW_FAIL = 'REQUEST_EVENT_REVIEW_FAIL';
export const RESET_REQUEST_EVENT_REVIEW = 'RESET_REQUEST_EVENT_REVIEW';

export const FETCH_EVENT_FOR_REVIEW_START = 'FETCH_EVENT_FOR_REVIEW_START';
export const FETCH_EVENT_FOR_REVIEW_SUCCESS = 'FETCH_EVENT_FOR_REVIEW_SUCCESS';
export const FETCH_EVENT_FOR_REVIEW_FAIL = 'FETCH_EVENT_FOR_REVIEW_FAIL';

export const CONFIRM_EVENT_START = 'CONFIRM_EVENT_START';
export const CONFIRM_EVENT_SUCCESS = 'CONFIRM_EVENT_SUCCESS';
export const CONFIRM_EVENT_FAIL = 'CONFIRM_EVENT_FAIL';
export const RESET_CONFIRM_EVENT = 'RESET_CONFIRM_EVENT';

export const SET_EVENT_FILTER = 'SET_EVENT_FILTER';
export const RESET_EVENT_FILTER = 'RESET_EVENT_FILTER';

export const CHANGE_EVENT_MONEY_COLLECTED_START =
  'CHANGE_EVENT_MONEY_COLLECTED_START';
export const CHANGE_EVENT_MONEY_COLLECTED_SUCCESS =
  'CHANGE_EVENT_MONEY_COLLECTED_SUCCESS';
export const CHANGE_EVENT_MONEY_COLLECTED_FAIL =
  'CHANGE_EVENT_MONEY_COLLECTED_FAIL';
export const RESET_EVENT_MONEY_COLLECTED = 'RESET_EVENT_MONEY_COLLECTED';

export const UPDATE_EVENT_OVERVIEW_START = 'UPDATE_EVENT_OVERVIEW_START';
export const UPDATE_EVENT_OVERVIEW_SUCCESS = 'UPDATE_EVENT_OVERVIEW_SUCCESS';
export const UPDATE_EVENT_OVERVIEW_FAIL = 'UPDATE_EVENT_OVERVIEW_FAIL';

export const SET_EVENT_LIST_TAB_ID = 'SET_EVENT_LIST_TAB_ID';
export const RESET_EVENT_LIST_TAB_ID = 'RESET_EVENT_LIST_TAB_ID';

export const SET_WINDOW_POSITION = 'SET_WINDOW_POSITION';
export const RESET_WINDOW_POSITION = 'RESET_WINDOW_POSITION';

export const SET_KEEP_FILTERS = 'SET_KEEP_FILTERS';

export const RESET_EVENT_STORE = 'RESET_EVENT_STORE';

export type EventActionTypes =
  | typeof FETCH_EVENTS_START
  | typeof FETCH_EVENTS_SUCCESS
  | typeof FETCH_EVENTS_FAIL
  | typeof FETCH_EVENT_START
  | typeof FETCH_EVENT_SUCCESS
  | typeof FETCH_EVENT_FAIL
  | typeof FETCH_EMPLOYEE_EVENTS_START
  | typeof FETCH_EMPLOYEE_EVENTS_SUCCESS
  | typeof FETCH_EMPLOYEE_EVENTS_FAIL
  | typeof FETCH_CLIENT_EVENTS_START
  | typeof FETCH_CLIENT_EVENTS_SUCCESS
  | typeof FETCH_CLIENT_EVENTS_FAIL
  | typeof COMPLETE_EVENT_START
  | typeof COMPLETE_EVENT_SUCCESS
  | typeof COMPLETE_EVENT_FAIL
  | typeof RESET_EVENT_UPDATE
  | typeof UPDATE_EVENT_START
  | typeof UPDATE_EVENT_SUCCESS
  | typeof UPDATE_EVENT_FAIL
  | typeof RESET_EVENT_TEAM_CHANGE
  | typeof RESET_EVENT_DATE_CHANGE
  | typeof CHANGE_EVENT_TEAM_START
  | typeof CHANGE_EVENT_TEAM_SUCCESS
  | typeof CHANGE_EVENT_TEAM_FAIL
  | typeof CHANGE_EVENT_DATE_START
  | typeof CHANGE_EVENT_DATE_SUCCESS
  | typeof CHANGE_EVENT_DATE_FAIL
  | typeof DELETE_EVENT_START
  | typeof DELETE_EVENT_SUCCESS
  | typeof DELETE_EVENT_FAIL
  | typeof REFRESH_EVENT_INVOICE_START
  | typeof REFRESH_EVENT_INVOICE_SUCCESS
  | typeof REFRESH_EVENT_INVOICE_FAIL
  | typeof SET_EVENTS_POSITIONS_START
  | typeof SET_EVENTS_POSITIONS_SUCCESS
  | typeof SET_EVENTS_POSITIONS_FAIL
  | typeof CANCEL_EVENT_START
  | typeof CANCEL_EVENT_SUCCESS
  | typeof CANCEL_EVENT_FAIL
  | typeof UNCANCEL_EVENT_START
  | typeof UNCANCEL_EVENT_SUCCESS
  | typeof UNCANCEL_EVENT_FAIL
  | typeof REQUEST_EVENT_REVIEW_START
  | typeof REQUEST_EVENT_REVIEW_SUCCESS
  | typeof REQUEST_EVENT_REVIEW_FAIL
  | typeof FETCH_EVENT_FOR_REVIEW_START
  | typeof FETCH_EVENT_FOR_REVIEW_SUCCESS
  | typeof FETCH_EVENT_FOR_REVIEW_FAIL
  | typeof RESET_EVENT_STORE
  | typeof SET_EVENT_FILTER
  | typeof RESET_EVENT_FILTER
  | typeof CHANGE_EVENT_MONEY_COLLECTED_START
  | typeof CHANGE_EVENT_MONEY_COLLECTED_SUCCESS
  | typeof CHANGE_EVENT_MONEY_COLLECTED_FAIL
  | typeof RESET_EVENT_MONEY_COLLECTED
  | typeof CONFIRM_EVENT_START
  | typeof CONFIRM_EVENT_SUCCESS
  | typeof CONFIRM_EVENT_FAIL
  | typeof UNCOMPLETE_EVENT_START
  | typeof UNCOMPLETE_EVENT_SUCCESS
  | typeof UNCOMPLETE_EVENT_FAIL
  | typeof UPDATE_EVENT_OVERVIEW_START
  | typeof UPDATE_EVENT_OVERVIEW_SUCCESS
  | typeof UPDATE_EVENT_OVERVIEW_FAIL
  | typeof RESET_CONFIRM_EVENT
  | typeof RESET_COMPLETE_EVENT
  | typeof RESET_REFRESH_EVENT_INVOICE
  | typeof RESET_REQUEST_EVENT_REVIEW
  | typeof SET_EVENT_LIST_TAB_ID
  | typeof RESET_EVENT_LIST_TAB_ID
  | typeof RESET_WINDOW_POSITION
  | typeof SET_KEEP_FILTERS
  | typeof SET_WINDOW_POSITION
  | typeof RESET_WINDOW_POSITION;
