export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL';

export const COMPLETE_PAYMENT_START = 'COMPLETE_PAYMENT_START';
export const COMPLETE_PAYMENT_SUCCESS = 'COMPLETE_PAYMENT_SUCCESS';
export const COMPLETE_PAYMENT_FAIL = 'COMPLETE_PAYMENT_FAIL';

export const FETCH_CLIENT_PAYMENTS_START = 'FETCH_CLIENT_PAYMENTS_START';
export const FETCH_CLIENT_PAYMENTS_SUCCESS = 'FETCH_CLIENT_PAYMENTS_SUCCESS';
export const FETCH_CLIENT_PAYMENTS_FAIL = 'FETCH_CLIENT_PAYMENTS_FAIL';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENTS_START
  | typeof FETCH_PAYMENTS_SUCCESS
  | typeof FETCH_PAYMENTS_FAIL
  | typeof COMPLETE_PAYMENT_START
  | typeof COMPLETE_PAYMENT_SUCCESS
  | typeof COMPLETE_PAYMENT_FAIL
  | typeof FETCH_CLIENT_PAYMENTS_START
  | typeof FETCH_CLIENT_PAYMENTS_SUCCESS
  | typeof FETCH_CLIENT_PAYMENTS_FAIL;
