import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import * as userService from '../../store/user/actions';
import * as companyService from '../../store/company/actions';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import clientReducer, { ClientStateType } from '../../store/client/reducer';
import cityReducer, { CityStateType } from '../../store/city/reducer';
import teamReducer, { TeamStateType } from '../../store/team/reducer';
import locationReducer, {
  LocationStateType,
} from '../../store/location/reducer';
import eventReducer, { EventStateType } from '../../store/event/reducer';
import scheduleReducer, {
  ScheduleStateType,
} from '../../store/schedule/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import noteReducer, { NoteStateType } from '../../store/note/reducer';
import additionalTaskReducer, {
  AdditionalTaskStateType,
} from '../../store/additionalTask/reducer';
import sendSmsMessageReducer, {
  SmsMessageStateType,
} from '../../store/sms-message/reducer';
import reviewReducer, { ReviewStateType } from '../../store/review/reducer';
import invoiceReducer, { InvoiceStateType } from '../../store/invoice/reducer';
import invoiceItemRecuder, {
  InvoiceItemStateType,
} from '../../store/invoiceItem/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import companySettingsRecuder, {
  CompanySettingsStateType,
} from '../../store/companySettings/reducer';
import smsTemplateRecuder, {
  SmsTemplateStateType,
} from '../../store/smsTemplate/reducer';
import timeOffRecuder, { TimeOffStateType } from '../../store/time-off/reducer';

export type StoreState = {
  auth: AuthStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  employee: EmployeeStateType;
  client: ClientStateType;
  city: CityStateType;
  team: TeamStateType;
  location: LocationStateType;
  event: EventStateType;
  schedule: ScheduleStateType;
  payment: PaymentStateType;
  note: NoteStateType;
  additionalTask: AdditionalTaskStateType;
  smsMessage: SmsMessageStateType;
  review: ReviewStateType;
  invoice: InvoiceStateType;
  invoiceItem: InvoiceItemStateType;
  user: UserStateType;
  company: CompanyStateType;
  companySettings: CompanySettingsStateType;
  smsTemplate: SmsTemplateStateType;
  timeOff: TimeOffStateType;
};

type Props = {
  children?: ReactNode;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  translation: translationReducer,
  language: languageReducer,
  employee: employeeReducer,
  client: clientReducer,
  city: cityReducer,
  team: teamReducer,
  location: locationReducer,
  event: eventReducer,
  schedule: scheduleReducer,
  payment: paymentReducer,
  note: noteReducer,
  additionalTask: additionalTaskReducer,
  smsMessage: sendSmsMessageReducer,
  review: reviewReducer,
  invoice: invoiceReducer,
  invoiceItem: invoiceItemRecuder,
  user: userReducer,
  company: companyReducer,
  companySettings: companySettingsRecuder,
  smsTemplate: smsTemplateRecuder,
  timeOff: timeOffRecuder,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store: Store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
      dispatch(userService.resetUserStoreLogout());
      dispatch(companyService.resetCompanyStore());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
