import * as actionTypes from './actionTypes';
import { EmployeeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  Employee,
  EmployeesList,
  EmployeeStatistics,
  SingleEmployeeStatistics,
} from '../../domain/Employee';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type EmployeeStateType = {
  employeesList: EmployeesList | null;
  employeesLoading: boolean;
  employeesError: HttpError;
  employeesStatistics: EmployeeStatistics[];
  employeesStatisticsLoading: boolean;
  employeesStatisticsError: HttpError;
  employeesListUpdateNeeded: boolean;
  employeeCreateLoading: boolean;
  employeeCreateError: HttpError;
  employeeCreateSuccess: boolean;
  createdEmployee: Employee | null;
  employeeUpdateLoading: boolean;
  employeeUpdateError: HttpError;
  employeeUpdateSuccess: boolean;
  employeeDeleteLoading: boolean;
  employeeDeleteError: HttpError;
  employee: Employee | null;
  employeeLoading: boolean;
  employeeError: HttpError;
  singleEmployeeStatistics: SingleEmployeeStatistics[];
  singleEmployeeStatisticsLoading: boolean;
  singleEmployeeStatisticsError: HttpError;
  employeeFilter: ListParams;
  windowPosition: number;
};

export type EmployeeActionType = EmployeeStateType & {
  type: EmployeeActionTypes;
};

export const initialState: EmployeeStateType = {
  employeesList: null,
  employeesLoading: true,
  employeesError: null,
  employeesStatistics: [],
  employeesStatisticsLoading: true,
  employeesStatisticsError: null,
  employeesListUpdateNeeded: false,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: false,
  createdEmployee: null,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: false,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employee: null,
  employeeLoading: false,
  employeeError: null,
  singleEmployeeStatistics: [],
  singleEmployeeStatisticsLoading: true,
  singleEmployeeStatisticsError: null,
  windowPosition: 0,
  employeeFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'firstName',
    search: '',
    sortDirection: 'ASC',
    page: 0,
    limit: 100,
  },
};

const fetchEmployeesStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeesLoading: true,
});

const fetchEmployeesSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesList: action.employeesList,
  employeesLoading: false,
  employeesError: null,
  employeesListUpdateNeeded: false,
});

const fetchEmployeesFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesError: action.employeesError,
  employeesLoading: false,
});

const fetchEmployeesStatisticsStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeesStatisticsLoading: true,
});

const fetchEmployeesStatisticsSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesStatistics: action.employeesStatistics,
  employeesStatisticsLoading: false,
  employeesStatisticsError: null,
});

const fetchEmployeesStatisticsFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesStatisticsError: action.employeesStatisticsError,
  employeesStatisticsLoading: false,
});

const fetchSingleEmployeeStatisticsStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  singleEmployeeStatisticsLoading: true,
});

const fetchSingleEmployeeStatisticsSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  singleEmployeeStatistics: action.singleEmployeeStatistics,
  singleEmployeeStatisticsLoading: false,
  singleEmployeeStatisticsError: null,
});

const fetchSingleEmployeeStatisticsFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  singleEmployeeStatisticsError: action.singleEmployeeStatisticsError,
  singleEmployeeStatisticsLoading: false,
});

const resetEmployeesStatistics = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeesStatistics: [],
  employeesStatisticsLoading: true,
  employeesStatisticsError: null,
});

const fetchEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeLoading: true,
  employeeUpdateSuccess: false,
  employeeCreateSuccess: false,
  employeeCreateError: null,
  employeeUpdateError: null,
});

const fetchEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employee: action.employee,
  employeeLoading: false,
  employeeError: null,
});

const fetchEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeError: action.employeeError,
  employeeLoading: false,
});

const createEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: true,
});

const createEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: true,
  createdEmployee: action.createdEmployee,
});

const createEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: action.employeeCreateError,
});

const updateEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: true,
});

const updateEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: true,
});

const updateEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: action.employeeUpdateError,
});

const deleteEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: true,
});

const deleteEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeesListUpdateNeeded: true,
});

const deleteEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: action.employeeDeleteError,
});

const setEmployeeFilter = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeFilter: action.employeeFilter,
});

const setWindowPosition = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  windowPosition: action.windowPosition,
});

const reducer = (state = initialState, action: EmployeeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES_START:
      return fetchEmployeesStart(state);
    case actionTypes.FETCH_EMPLOYEES_SUCCESS:
      return fetchEmployeesSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_FAIL:
      return fetchEmployeesFail(state, action);
    case actionTypes.FETCH_EMPLOYEES_STATISTICS_START:
      return fetchEmployeesStatisticsStart(state);
    case actionTypes.FETCH_EMPLOYEES_STATISTICS_SUCCESS:
      return fetchEmployeesStatisticsSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_STATISTICS_FAIL:
      return fetchEmployeesStatisticsFail(state, action);
    case actionTypes.RESET_EMPLOYEES_STATISTICS:
      return resetEmployeesStatistics(state);
    case actionTypes.FETCH_EMPLOYEE_START:
      return fetchEmployeeStart(state);
    case actionTypes.FETCH_EMPLOYEE_SUCCESS:
      return fetchEmployeeSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEE_FAIL:
      return fetchEmployeeFail(state, action);
    case actionTypes.CREATE_EMPLOYEE_START:
      return createEmployeeStart(state);
    case actionTypes.CREATE_EMPLOYEE_SUCCESS:
      return createEmployeeSuccess(state, action);
    case actionTypes.CREATE_EMPLOYEE_FAIL:
      return createEmployeeFail(state, action);
    case actionTypes.UPDATE_EMPLOYEE_START:
      return updateEmployeeStart(state);
    case actionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return updateEmployeeSuccess(state);
    case actionTypes.UPDATE_EMPLOYEE_FAIL:
      return updateEmployeeFail(state, action);
    case actionTypes.DELETE_EMPLOYEE_START:
      return deleteEmployeeStart(state);
    case actionTypes.DELETE_EMPLOYEE_SUCCESS:
      return deleteEmployeeSuccess(state);
    case actionTypes.DELETE_EMPLOYEE_FAIL:
      return deleteEmployeeFail(state, action);
    case actionTypes.FETCH_SINGLE_EMPLOYEE_STATISTICS_START:
      return fetchSingleEmployeeStatisticsStart(state);
    case actionTypes.FETCH_SINGLE_EMPLOYEE_STATISTICS_SUCCESS:
      return fetchSingleEmployeeStatisticsSuccess(state, action);
    case actionTypes.FETCH_SINGLE_EMPLOYEE_STATISTICS_FAIL:
      return fetchSingleEmployeeStatisticsFail(state, action);
    case actionTypes.SET_EMPLOYEE_FILTER:
      return setEmployeeFilter(state, action);
    case actionTypes.SET_EMPLOYEE_WINDOW_POSITION:
      return setWindowPosition(state, action);
    default:
      return state;
  }
};

export default reducer;
