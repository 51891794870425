import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CompanySettingsActionTypes } from './actionTypes';

export type CompanySettingsStateType = {
  companyUpdateSettingsLoading: boolean;
  companyUpdateSettingsSuccess: boolean;
  companyUpdateSettingsError: HttpError;
  companyUpdateFeaturesLoading: boolean;
  companyUpdateFeaturesSuccess: boolean;
  companyUpdateFeaturesError: HttpError;
};

export type CompanySettingsActionType = CompanySettingsStateType & {
  type: CompanySettingsActionTypes;
};

export const initialState: CompanySettingsStateType = {
  companyUpdateSettingsLoading: false,
  companyUpdateSettingsSuccess: false,
  companyUpdateSettingsError: null,
  companyUpdateFeaturesLoading: false,
  companyUpdateFeaturesSuccess: false,
  companyUpdateFeaturesError: null,
};

const updateCompanyMessagesStart = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateSettingsLoading: true,
});

const updateCompanyMessagesSuccess = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateSettingsLoading: false,
  companyUpdateSettingsSuccess: true,
});

const updateCompanyMessagesFail = (
  state: CompanySettingsStateType,
  action: CompanySettingsActionType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateSettingsError: action.companyUpdateSettingsError,
  companyUpdateSettingsLoading: false,
});

const updateCompanyFeaturesStart = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateFeaturesLoading: true,
});

const updateCompanyFeaturesSuccess = (
  state: CompanySettingsStateType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateFeaturesLoading: false,
  companyUpdateFeaturesSuccess: true,
});

const updateCompanyFeaturesFail = (
  state: CompanySettingsStateType,
  action: CompanySettingsActionType,
): CompanySettingsStateType => ({
  ...state,
  companyUpdateFeaturesError: action.companyUpdateFeaturesError,
  companyUpdateFeaturesLoading: false,
});

const reducer = (state = initialState, action: CompanySettingsActionType) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMPANY_SETTINGS_START:
      return updateCompanyMessagesStart(state);
    case actionTypes.UPDATE_COMPANY_SETTINGS_SUCCESS:
      return updateCompanyMessagesSuccess(state);
    case actionTypes.UPDATE_COMPANY_SETTINGS_FAIL:
      return updateCompanyMessagesFail(state, action);
    case actionTypes.UPDATE_COMPANY_FEATURES_START:
      return updateCompanyFeaturesStart(state);
    case actionTypes.UPDATE_COMPANY_FEATURES_SUCCESS:
      return updateCompanyFeaturesSuccess(state);
    case actionTypes.UPDATE_COMPANY_FEATURES_FAIL:
      return updateCompanyFeaturesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
