export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAIL = 'FETCH_TEAMS_FAIL';

export const FETCH_TEAM_START = 'FETCH_TEAM_START';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAIL = 'FETCH_TEAM_FAIL';

export const CREATE_TEAM_START = 'CREATE_TEAM_START';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAIL = 'CREATE_TEAM_FAIL';

export const UPDATE_TEAM_START = 'UPDATE_TEAMS_START';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAMS_SUCCESS';
export const UPDATE_TEAM_FAIL = 'UPDATE_TEAMS_FAIL';

export const DELETE_TEAM_START = 'DELETE_TEAM_START';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAIL = 'DELETE_TEAM_FAIL';

export const RESET_TEAM_STORE = 'RESET_TEAM_STORE';

export type TeamActionTypes =
  | typeof FETCH_TEAMS_START
  | typeof FETCH_TEAMS_SUCCESS
  | typeof FETCH_TEAMS_FAIL
  | typeof FETCH_TEAM_START
  | typeof FETCH_TEAM_SUCCESS
  | typeof FETCH_TEAM_FAIL
  | typeof CREATE_TEAM_START
  | typeof CREATE_TEAM_SUCCESS
  | typeof CREATE_TEAM_FAIL
  | typeof UPDATE_TEAM_START
  | typeof UPDATE_TEAM_SUCCESS
  | typeof UPDATE_TEAM_FAIL
  | typeof DELETE_TEAM_START
  | typeof DELETE_TEAM_SUCCESS
  | typeof DELETE_TEAM_FAIL
  | typeof RESET_TEAM_STORE;
