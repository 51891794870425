import React, { ChangeEvent, ReactNode, useRef } from 'react';
import styles from './VideoInput.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

type Props = {
  name: string;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  maxFileCount?: number;
  errors?: Array<string>;
  label?: string | ReactNode;
  helperText?: string;
  fileHelperText?: string;
};

const VideoInput = ({
  onFileChange,
  value,
  name,
  errors,
  label,
  helperText,
  fileHelperText,
}: Props) => {
  const intl = useIntl();

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const getIcon = (): IconProp => {
    if (errors && errors.length > 0) {
      return faExclamationCircle as IconProp;
    }

    if (value && (!errors || errors.length === 0)) {
      return faCheckCircle as IconProp;
    }

    return faFileImport as IconProp;
  };

  const renderAddFileButton = () => {
    const hasValue = Array.isArray(value) ? !!value.length : !!value;

    return (
      <div className={styles.innerFileSelectContainer}>
        <FontAwesomeIcon
          className={cx(styles.innerFileIcon, {
            [styles.errorFile]: errors && errors.length > 0,
            [styles.successFile]: hasValue && (!errors || errors.length === 0),
          })}
          icon={getIcon() as IconProp}
          fixedWidth
          size="2x"
        />
        <span className={styles.innerFileSelectLabel}>
          {translate(intl, 'INPUTS.READY_TO_UPLOAD', 'Ready to upload')}
        </span>
      </div>
    );
  };

  const renderVideoContaier = (
    value: File | File[] | string | string[] | undefined,
  ) => {
    if (value instanceof File) {
      return (
        <video className={styles.videoContainer} controls>
          <source src={URL.createObjectURL(value)} type="video/mp4" />
          {translate(
            intl,
            'INPUT.VIDEO_BROWSER_NOT_SUPPORTED',
            'Your browser does not support the video tag.',
          )}
        </video>
      );
    }

    if (value && typeof value === 'string') {
      return (
        <video className={styles.videoContainer} controls>
          <source src={value} type="video/mp4" />
          {translate(
            intl,
            'INPUT.VIDEO_BROWSER_NOT_SUPPORTED',
            'Your browser does not support the video tag.',
          )}
        </video>
      );
    }

    return renderAddFileButton();
  };

  return (
    <div className={styles.fileFieldContainer}>
      <div className={styles.fileField}>
        <input
          color="primary"
          type="file"
          onChange={onFileChange}
          ref={uploadInputRef}
          name={name}
          accept=".mp4,.mov,.avi,.mkv,.webm,.hevc,.h.264,.h.265"
        />
        <div className={styles.fileContainer}>
          <div
            className={styles.fileSelectedContainer}
            onClick={handleUploadCLick}
          >
            {renderVideoContaier(value)}
          </div>
        </div>
        <div className={styles.label}>
          <h5>{label}</h5>
          <p>
            {translate(
              intl,
              'INPUT.VIDEO_MAX_FILE_SIZE',
              'Max file size 100MB',
            )}
          </p>
          <p>
            {translate(
              intl,
              'INPUT.VIDEO_ALLOWED_FILE_EXTENSIONS',
              'Only .mp4, .mov, .webm, extensions are allowed',
            )}
          </p>
          {fileHelperText && <p>{translate(intl, fileHelperText, '')}</p>}
          {errors &&
            errors.map((error, index) => (
              <div className={styles.fileError} key={`file-error-${index}`}>
                {error}
              </div>
            ))}
          {helperText && <p>{helperText}</p>}
        </div>
      </div>
    </div>
  );
};

export default VideoInput;
