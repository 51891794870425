import * as actionTypes from './actionTypes';
import { TimeOffActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { TimeOff, TimeOffsList } from '../../domain/TimeOff';

export type TimeOffStateType = {
  timeOffList: TimeOffsList | null;
  timeOffListLoading: boolean;
  timeOffListError: HttpError;
  timeOffListUpdateNeeded: boolean;
  timeOffCreateLoading: boolean;
  timeOffCreateError: HttpError;
  timeOffCreateSuccess: boolean;
  timeOffDeleteLoading: boolean;
  timeOffDeleteError: HttpError;
  timeOffDeleteSuccess: boolean;
  createdTimeOff: TimeOff | null;
  timeOff: TimeOff | null;
  timeOffLoading: boolean;
  timeOffError: HttpError;
  timeOffUpdateLoading: boolean;
  timeOffUpdateError: HttpError;
  timeOffUpdateSuccess: boolean;
  clientTimeOffList: TimeOffsList | null;
  clientTimeOffListLoading: boolean;
  clientTimeOffListError: HttpError;
  clientTimeOffListUpdateNeeded: boolean;
};

export type TimeOffActionType = TimeOffStateType & {
  type: TimeOffActionTypes;
};

export const initialState: TimeOffStateType = {
  timeOffList: null,
  timeOffListLoading: false,
  timeOffListError: null,
  timeOffListUpdateNeeded: false,
  timeOffCreateLoading: false,
  timeOffCreateError: null,
  timeOffCreateSuccess: false,
  timeOffDeleteLoading: false,
  timeOffDeleteError: null,
  timeOffDeleteSuccess: false,
  createdTimeOff: null,
  timeOff: null,
  timeOffLoading: false,
  timeOffError: null,
  timeOffUpdateLoading: false,
  timeOffUpdateError: null,
  timeOffUpdateSuccess: false,
  clientTimeOffList: null,
  clientTimeOffListLoading: false,
  clientTimeOffListError: null,
  clientTimeOffListUpdateNeeded: false,
};

const fetchTimeOffsListStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffListUpdateNeeded: false,
  timeOffListLoading: true,
});

const fetchTimeOffsListSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffList: action.timeOffList,
  timeOffListLoading: false,
  timeOffListError: null,
});

const fetchTimeOffsListFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffListError: action.timeOffListError,
  timeOffListLoading: false,
});

const createTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: true,
});

const createTimeOffSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: false,
  timeOffCreateSuccess: true,
  clientTimeOffListUpdateNeeded: true,
  createdTimeOff: action.createdTimeOff,
});

const createTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: false,
  timeOffCreateError: action.timeOffCreateError,
});

const deleteTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: true,
});

const deleteTimeOffSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: false,
  timeOffDeleteSuccess: true,
  timeOffListUpdateNeeded: true,
  clientTimeOffListUpdateNeeded: true,
});

const deleteTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: false,
  timeOffDeleteError: action.timeOffDeleteError,
});

const fetchTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffLoading: true,
});

const fetchTimeOffSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOff: action.timeOff,
  timeOffLoading: false,
  timeOffError: null,
});

const fetchTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffError: action.timeOffError,
  timeOffLoading: false,
});

const updateTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffUpdateLoading: true,
  timeOffListUpdateNeeded: false,
  clientTimeOffListUpdateNeeded: false,
});

const updateTimeOffSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffUpdateSuccess: action.timeOffUpdateSuccess,
  timeOffUpdateLoading: false,
  timeOffUpdateError: null,
  timeOffListUpdateNeeded: true,
  clientTimeOffListUpdateNeeded: true,
});

const updateTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffUpdateError: action.timeOffUpdateError,
  timeOffUpdateLoading: false,
});

const fetchClientTimeOffsListStart = (
  state: TimeOffStateType,
): TimeOffStateType => ({
  ...state,
  clientTimeOffListUpdateNeeded: false,
  clientTimeOffListLoading: true,
});

const fetchClientTimeOffsListSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  clientTimeOffList: action.clientTimeOffList,
  clientTimeOffListLoading: false,
  clientTimeOffListError: null,
});

const fetchClientTimeOffsListFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  clientTimeOffListError: action.timeOffListError,
  clientTimeOffListLoading: false,
});

const resetCreateOrUpdateTimeOff = (
  state: TimeOffStateType,
): TimeOffStateType => ({
  ...state,
  timeOffUpdateLoading: false,
  timeOffUpdateError: null,
  timeOffUpdateSuccess: false,
  timeOffListUpdateNeeded: false,
  clientTimeOffListUpdateNeeded: false,
  timeOffCreateLoading: false,
  timeOffCreateError: null,
  timeOffCreateSuccess: false,
});

const resetTeamStore = (): TimeOffStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TimeOffActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TIME_OFFS_LIST_START:
      return fetchTimeOffsListStart(state);
    case actionTypes.FETCH_TIME_OFFS_LIST_SUCCESS:
      return fetchTimeOffsListSuccess(state, action);
    case actionTypes.FETCH_TIME_OFFS_LIST_FAIL:
      return fetchTimeOffsListFail(state, action);
    case actionTypes.CREATE_TIME_OFF_START:
      return createTimeOffStart(state);
    case actionTypes.CREATE_TIME_OFF_SUCCESS:
      return createTimeOffSuccess(state, action);
    case actionTypes.CREATE_TIME_OFF_FAIL:
      return createTimeOffFail(state, action);
    case actionTypes.DELETE_TIME_OFF_START:
      return deleteTimeOffStart(state);
    case actionTypes.DELETE_TIME_OFF_SUCCESS:
      return deleteTimeOffSuccess(state, action);
    case actionTypes.DELETE_TIME_OFF_FAIL:
      return deleteTimeOffFail(state, action);
    case actionTypes.FETCH_TIME_OFF_START:
      return fetchTimeOffStart(state);
    case actionTypes.FETCH_TIME_OFF_SUCCESS:
      return fetchTimeOffSuccess(state, action);
    case actionTypes.FETCH_TIME_OFF_FAIL:
      return fetchTimeOffFail(state, action);
    case actionTypes.UPDATE_TIME_OFF_START:
      return updateTimeOffStart(state);
    case actionTypes.UPDATE_TIME_OFF_SUCCESS:
      return updateTimeOffSuccess(state, action);
    case actionTypes.UPDATE_TIME_OFF_FAIL:
      return updateTimeOffFail(state, action);
    case actionTypes.FETCH_CLIENT_TIME_OFFS_LIST_START:
      return fetchClientTimeOffsListStart(state);
    case actionTypes.FETCH_CLIENT_TIME_OFFS_LIST_SUCCESS:
      return fetchClientTimeOffsListSuccess(state, action);
    case actionTypes.FETCH_CLIENT_TIME_OFFS_LIST_FAIL:
      return fetchClientTimeOffsListFail(state, action);
    case actionTypes.RESET_CREATE_AND_UPDATE_TIME_OFF:
      return resetCreateOrUpdateTimeOff(state);
    case actionTypes.RESET_TIME_OFF_STORE:
      return resetTeamStore();
    default:
      return state;
  }
};

export default reducer;
