import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CompanyActionTypes } from './actionTypes';
import { Company, CompanyList } from '../../domain/Company';

export type CompanyStateType = {
  companyList: CompanyList | null;
  companiesLoading: boolean;
  companiesError: HttpError;
  companyListUpdateNeeded: boolean;
  companyCreateLoading: boolean;
  companyCreateSuccess: boolean;
  createCompanyError: HttpError;
  company: Company | null;
  companyLoading: boolean;
  companySuccess: boolean;
  companyError: HttpError;
  companyUpdateLoading: boolean;
  companyUpdateSuccess: boolean;
  companyUpdateError: HttpError;
  companyOptions: Company[];
  companyOptionsLoading: boolean;
  companyOptionsError: HttpError;
  selectedCompanyId: number | null;
  companyDeleteLoading: boolean;
  companyDeleteSuccess: boolean;
  companyDeleteError: HttpError;
  selectedCompany: Company | null;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
};

export const initialState: CompanyStateType = {
  companyList: null,
  companiesLoading: true,
  companiesError: null,
  companyListUpdateNeeded: false,
  companyCreateLoading: true,
  companyCreateSuccess: false,
  createCompanyError: null,
  company: null,
  companyLoading: true,
  companySuccess: false,
  companyError: null,
  companyUpdateLoading: true,
  companyUpdateSuccess: false,
  companyUpdateError: null,
  companyOptions: [],
  companyOptionsLoading: true,
  companyOptionsError: null,
  companyDeleteError: null,
  companyDeleteLoading: true,
  companyDeleteSuccess: false,
  selectedCompanyId: localStorage.getItem('selectedCompany')
    ? Number(localStorage.getItem('selectedCompany'))
    : null,
  selectedCompany: null,
};

const fetchCompaniesStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companiesLoading: true,
  companyList: null,
});

const fetchCompaniesSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyList: action.companyList,
  companiesLoading: false,
  companiesError: null,
  companyListUpdateNeeded: false,
});

const fetchCompaniesFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesError: action.companiesError,
  companiesLoading: false,
});

const createCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: true,
});

const createCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateSuccess: true,
});

const createCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  createCompanyError: action.createCompanyError,
  companiesLoading: false,
});

const resetCreateOrUpdateCompany = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  createCompanyError: null,
  companyCreateLoading: true,
  companyCreateSuccess: false,
  companyUpdateSuccess: false,
});

const fetchCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyLoading: true,
  company: null,
});

const fetchCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  company: action.company,
  companyLoading: false,
  companySuccess: true,
  companyError: null,
});

const fetchCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyError: action.companyError,
  companyLoading: false,
});

const updateCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: true,
});

const updateCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateSuccess: true,
});

const updateCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyUpdateError: action.companyUpdateError,
  companyUpdateLoading: false,
});

const fetchCompanyOptionsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyOptionsLoading: true,
});

const fetchCompanyOptionsSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptions: action.companyOptions,
  companyOptionsLoading: false,
  companyOptionsError: null,
});

const fetchCompanyOptionsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptionsError: action.companyOptionsError,
  companyOptionsLoading: false,
});

const setSelectedCompanyId = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompanyId: action.selectedCompanyId,
});

const companyDeleteStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: true,
});

const companyDeleteSuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteSuccess: true,
  companyListUpdateNeeded: true,
});

const companyDeleteFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyDeleteError: action.companyDeleteError,
  companyDeleteLoading: false,
});

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
});

const resetCompanyStore = (): CompanyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_START:
      return fetchCompaniesStart(state);
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchCompaniesSuccess(state, action);
    case actionTypes.FETCH_COMPANIES_FAIL:
      return fetchCompaniesFail(state, action);
    case actionTypes.CREATE_COMPANY_START:
      return createCompanyStart(state);
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySuccess(state);
    case actionTypes.CREATE_COMPANY_FAIL:
      return createCompanyFail(state, action);
    case actionTypes.RESET_CREATE_AND_UPDATE_COMPANY:
      return resetCreateOrUpdateCompany(state);
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state);
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action);
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action);
    case actionTypes.UPDATE_COMPANY_START:
      return updateCompanyStart(state);
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return updateCompanySuccess(state);
    case actionTypes.UPDATE_COMPANY_FAIL:
      return updateCompanyFail(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_START:
      return fetchCompanyOptionsStart(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS:
      return fetchCompanyOptionsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_FAIL:
      return fetchCompanyOptionsFail(state, action);
    case actionTypes.SET_SELECTED_COMPANY_ID:
      return setSelectedCompanyId(state, action);
    case actionTypes.RESET_COMPANY_STORE:
      return resetCompanyStore();
    case actionTypes.DELETE_COMPANY_START:
      return companyDeleteStart(state);
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return companyDeleteSuccess(state);
    case actionTypes.DELETE_COMPANY_FAIL:
      return companyDeleteFail(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    default:
      return state;
  }
};

export default reducer;
