import * as actionTypes from './actionTypes';
import { InvoicesActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { InvoiceItem, InvoiceItemList } from '../../domain/InvoiceItem';

export type InvoiceItemStateType = {
  invoiceItem: InvoiceItem | null;
  invoiceItemLoading: boolean;
  invoiceItemError: HttpError;
  invoiceItemUpdateNeeded: boolean;
  createInvoiceItemLoading: boolean;
  createInvoiceItemSuccess: boolean;
  createInvoiceItemError: HttpError;
  updateInvoiceItemLoading: boolean;
  updateInvoiceItemSuccess: boolean;
  updateInvoiceItemError: HttpError;
  deleteInvoiceItemLoading: boolean;
  deleteInvoiceItemSuccess: boolean;
  deleteInvoiceItemError: HttpError;
  invoiceItemsList: InvoiceItemList | null;
  invoiceItemsListLoading: boolean;
  invoiceItemsListError: HttpError;
};

export type InvoiceItemActionType = InvoiceItemStateType & {
  type: InvoicesActionTypes;
};

export const initialState: InvoiceItemStateType = {
  invoiceItem: null,
  invoiceItemLoading: true,
  invoiceItemError: null,
  invoiceItemUpdateNeeded: false,
  createInvoiceItemLoading: true,
  createInvoiceItemSuccess: false,
  createInvoiceItemError: null,
  updateInvoiceItemLoading: true,
  updateInvoiceItemSuccess: false,
  updateInvoiceItemError: null,
  deleteInvoiceItemLoading: true,
  deleteInvoiceItemSuccess: false,
  deleteInvoiceItemError: null,
  invoiceItemsList: null,
  invoiceItemsListLoading: false,
  invoiceItemsListError: null,
};

const fetchInvoiceItemStart = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItemLoading: true,
  invoiceItem: null,
});

const fetchInvoiceItemSuccess = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItem: action.invoiceItem,
  invoiceItemLoading: false,
  invoiceItemError: null,
  invoiceItemUpdateNeeded: false,
});

const fetchInvoiceItemFail = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItemError: action.invoiceItemError,
  invoiceItemLoading: false,
});

const createInvoiceItemStart = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  createInvoiceItemLoading: true,
  createInvoiceItemError: null,
  createInvoiceItemSuccess: false,
});

const createInvoiceItemSuccess = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  createInvoiceItemLoading: false,
  createInvoiceItemSuccess: true,
  createInvoiceItemError: null,
  invoiceItemUpdateNeeded: true,
});

const createInvoiceItemFail = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  createInvoiceItemError: action.createInvoiceItemError,
  createInvoiceItemLoading: false,
});

const updateInvoiceItemStart = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  updateInvoiceItemLoading: true,
  updateInvoiceItemError: null,
  updateInvoiceItemSuccess: false,
  invoiceItemUpdateNeeded: false,
});

const updateInvoiceItemSuccess = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  updateInvoiceItemLoading: false,
  updateInvoiceItemSuccess: true,
  updateInvoiceItemError: null,
  invoiceItemUpdateNeeded: true,
});

const updateInvoiceItemFail = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  updateInvoiceItemError: action.updateInvoiceItemError,
  updateInvoiceItemLoading: false,
});

const deleteInvoiceItemStart = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  deleteInvoiceItemLoading: true,
  deleteInvoiceItemError: null,
  deleteInvoiceItemSuccess: false,
  invoiceItemUpdateNeeded: false,
});

const deleteInvoiceItemSuccess = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  deleteInvoiceItemLoading: false,
  deleteInvoiceItemSuccess: true,
  deleteInvoiceItemError: null,
  invoiceItemUpdateNeeded: true,
});

const deleteInvoiceItemFail = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  deleteInvoiceItemError: action.deleteInvoiceItemError,
  deleteInvoiceItemLoading: false,
});

const resetCreateAndUpdateInvoiceItem = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItem: null,
  invoiceItemLoading: true,
  invoiceItemError: null,
  invoiceItemUpdateNeeded: false,
  createInvoiceItemLoading: true,
  createInvoiceItemSuccess: false,
  createInvoiceItemError: null,
  updateInvoiceItemLoading: true,
  updateInvoiceItemSuccess: false,
  updateInvoiceItemError: null,
  deleteInvoiceItemLoading: true,
  deleteInvoiceItemSuccess: false,
  deleteInvoiceItemError: null,
});

const fetchInvoiceItemsStart = (
  state: InvoiceItemStateType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItemsListLoading: true,
  invoiceItemsList: null,
});

const fetchInvoiceItemsSuccess = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItemsListLoading: false,
  invoiceItemsList: action.invoiceItemsList,
  invoiceItemUpdateNeeded: false,
});

const fetchInvoiceItemsFail = (
  state: InvoiceItemStateType,
  action: InvoiceItemActionType,
): InvoiceItemStateType => ({
  ...state,
  invoiceItemsListError: action.invoiceItemsListError,
  invoiceItemsListLoading: false,
});

const reducer = (state = initialState, action: InvoiceItemActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICE_ITEM_START:
      return fetchInvoiceItemStart(state);
    case actionTypes.FETCH_INVOICE_ITEM_SUCCESS:
      return fetchInvoiceItemSuccess(state, action);
    case actionTypes.FETCH_INVOICE_ITEM_FAIL:
      return fetchInvoiceItemFail(state, action);
    case actionTypes.FETCH_INVOICE_ITEMS_START:
      return fetchInvoiceItemsStart(state);
    case actionTypes.FETCH_INVOICE_ITEMS_SUCCESS:
      return fetchInvoiceItemsSuccess(state, action);
    case actionTypes.FETCH_INVOICE_ITEMS_FAIL:
      return fetchInvoiceItemsFail(state, action);
    case actionTypes.CREATE_INVOICE_ITEM_START:
      return createInvoiceItemStart(state);
    case actionTypes.CREATE_INVOICE_ITEM_SUCCESS:
      return createInvoiceItemSuccess(state);
    case actionTypes.CREATE_INVOICE_ITEM_FAIL:
      return createInvoiceItemFail(state, action);
    case actionTypes.UPDATE_INVOICE_ITEM_START:
      return updateInvoiceItemStart(state);
    case actionTypes.UPDATE_INVOICE_ITEM_SUCCESS:
      return updateInvoiceItemSuccess(state);
    case actionTypes.UPDATE_INVOICE_ITEM_FAIL:
      return updateInvoiceItemFail(state, action);
    case actionTypes.DELETE_INVOICE_ITEM_START:
      return deleteInvoiceItemStart(state);
    case actionTypes.DELETE_INVOICE_ITEM_SUCCESS:
      return deleteInvoiceItemSuccess(state);
    case actionTypes.DELETE_INVOICE_ITEM_FAIL:
      return deleteInvoiceItemFail(state, action);
    case actionTypes.RESET_CREATE_AND_UPDATE_INVOICE_ITEM:
      return resetCreateAndUpdateInvoiceItem(state);
    default:
      return state;
  }
};

export default reducer;
