import { Schedule } from './Schedule';
import { Note } from './Note';
import { AdditionalTask } from './AdditionalTask';
import { Location } from './Location';
import { TimeOff } from './TimeOff';
import { SmsRequest } from './SmsRequest';

export enum OccuranceType {
  // eslint-disable-next-line no-unused-vars
  None,
  // eslint-disable-next-line no-unused-vars
  Daily,
  // eslint-disable-next-line no-unused-vars
  Weekly,
}

export enum PriceType {
  // eslint-disable-next-line no-unused-vars
  Fixed,
  // eslint-disable-next-line no-unused-vars
  Hourly,
}

export enum EventStatus {
  // eslint-disable-next-line no-unused-vars
  Scheduled,
  // eslint-disable-next-line no-unused-vars
  Completed,
  // eslint-disable-next-line no-unused-vars
  Cancelled,
  // eslint-disable-next-line no-unused-vars
  Paid,
  // eslint-disable-next-line no-unused-vars
  Pending,
}

export enum EventDateStatus {
  // eslint-disable-next-line no-unused-vars
  FUTURE = 'FUTURE',
  // eslint-disable-next-line no-unused-vars
  PAST = 'PAST',
}

export enum EventTypes {
  // eslint-disable-next-line no-unused-vars
  CLEANING_INTERIOR = 'CLEANING_INTERIOR',
  // eslint-disable-next-line no-unused-vars
  CLEANING_CARPET = 'CLEANING_CARPET',
}

export interface Event {
  id: number;
  name: string;
  date: Date;
  time: string;
  duration?: string;
  type: string;
  comment: string;
  status: EventStatus;
  scheduleId: number;
  scheduleInvoiceNeeded: boolean;
  scheduleOccuranceType: OccuranceType;
  schedulePriceType: PriceType;
  clientId: number;
  clientName: string;
  cityId: number;
  cityName: string;
  teamId: number;
  teamName: string;
  schedule: Schedule;
  notes: Note[];
  additionalTasks: AdditionalTask[];
  position: number;
  reviewRequestToken: string;
  reviewId: number;
  balance: number;
  moneyCollected: number;
  price: number | null;
  priceType: PriceType;
  invoiceNeeded: boolean;
  clientPhone: string;
  location: Location;
  timeOffs: TimeOff[];
  startTime?: string;
  smsRequest?: SmsRequest;
  teamMembers: number;
  cleaningType: EventTypes;
  isDropOff: boolean;
}

export interface DayEvent {
  teamId: number;
  name: string;
  withTeam: boolean;
  events: Event[];
}

export interface DayEventsList {
  result: DayEvent[];
  pages: number;
  total: number;
}

export interface EventsList {
  result: Event[];
  pages: number;
}

export interface ClientEvent {
  id: number;
  name: string;
  date: Date;
  time: string;
  duration: string;
  price: number;
  teamName: string;
  location: Location;
  city: string;
  moneyCollected: number;
  status: EventStatus;
  invoiceNeeded: boolean;
  priceType: PriceType;
  additionalTasks: AdditionalTask[];
  clientName: string;
  reviewId: number;
  reviewRequestToken: string;
  cityName: string;
  teamId: number;
  schedule: Schedule;
  timeOffs: TimeOff[];
  startTime?: string;
  smsRequest?: SmsRequest;
  cleaningType: EventTypes;
  isDropOff: boolean;
  notes: Note[];
}

export interface ClientEventsList {
  result: ClientEvent[];
  pages: number;
  total: number;
}

export interface EventUpdateOverview {
  id: number;
  startTime: string;
  teamName?: string;
  teamMembers: number;
}
