import * as actionTypes from './actionTypes';
import { SmsTemplateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { SmsTemplatesList, SmsTemplate } from '../../domain/SmsTemplate';

export type SmsTemplateStateType = {
  smsTemplatesList: SmsTemplatesList | null;
  smsTemplatesLoading: boolean;
  smsTemplatesError: HttpError;
  smsTemplatesListUpdateNeeded: boolean;
  createdSmsTemplate: SmsTemplate | null;
  smsTemplateCreateLoading: boolean;
  smsTemplateCreateError: HttpError;
  smsTemplateCreateSuccess: boolean;
  smsTemplate: SmsTemplate | null;
  smsTemplateLoading: boolean;
  smsTemplateError: HttpError;
  smsTemplateUpdateLoading: boolean;
  smsTemplateUpdateError: HttpError;
  smsTemplateUpdateSuccess: boolean;
  smsTemplateDeleteLoading: boolean;
  smsTemplateDeleteSuccess: boolean;
  smsTemplateDeleteError: HttpError;
  allSmsTemplates: SmsTemplate[] | [];
  allSmsTemplatesLoading: boolean;
  allSmsTemplatesError: HttpError;
};

export type SmsTemplateActionType = SmsTemplateStateType & {
  type: SmsTemplateActionTypes;
};

export const initialState: SmsTemplateStateType = {
  smsTemplatesList: null,
  smsTemplatesLoading: false,
  smsTemplatesError: null,
  smsTemplatesListUpdateNeeded: false,
  smsTemplateCreateLoading: false,
  smsTemplateCreateError: null,
  smsTemplateCreateSuccess: false,
  createdSmsTemplate: null,
  smsTemplate: null,
  smsTemplateLoading: false,
  smsTemplateError: null,
  smsTemplateUpdateLoading: false,
  smsTemplateUpdateError: null,
  smsTemplateUpdateSuccess: false,
  smsTemplateDeleteLoading: false,
  smsTemplateDeleteSuccess: false,
  smsTemplateDeleteError: null,
  allSmsTemplates: [],
  allSmsTemplatesLoading: false,
  allSmsTemplatesError: null,
};

const fetchSmsTemplatesStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplatesLoading: true,
});

const fetchSmsTemplatesSuccess = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplatesList: action.smsTemplatesList,
  smsTemplatesLoading: false,
  smsTemplatesError: null,
});

const fetchSmsTemplatesFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplatesError: action.smsTemplatesError,
  smsTemplatesLoading: false,
});

const createSmsTemplateStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateCreateLoading: true,
});

const createSmsTemplateSuccess = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateCreateLoading: false,
  smsTemplateCreateSuccess: true,
  createdSmsTemplate: action.createdSmsTemplate,
});

const createSmsTemplateFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateCreateLoading: false,
  smsTemplateCreateError: action.smsTemplateCreateError,
});

const fetchSmsTemplateStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateLoading: true,
});

const fetchSmsTemplateSuccess = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplate: action.smsTemplate,
  smsTemplateLoading: false,
});

const fetchSmsTemplateFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateError: action.smsTemplateError,
  smsTemplateLoading: false,
});

const updateSmsTemplateStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateUpdateLoading: true,
});

const updateSmsTemplateSuccess = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateUpdateLoading: false,
  smsTemplateUpdateSuccess: true,
});

const updateSmsTemplateFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateUpdateLoading: false,
  smsTemplateUpdateError: action.smsTemplateUpdateError,
});

const resetSmsTemplateStore = (): SmsTemplateStateType => ({
  ...initialState,
});

const deleteSmsTemplateStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateDeleteLoading: true,
});

const deleteSmsTemplateSuccess = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateDeleteLoading: false,
  smsTemplateDeleteError: null,
  smsTemplateDeleteSuccess: true,
  smsTemplatesListUpdateNeeded: true,
});

const deleteSmsTemplateFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateDeleteLoading: false,
  smsTemplateDeleteError: action.smsTemplateDeleteError,
});

const resetSmsTemplateDelete = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  smsTemplateDeleteLoading: false,
  smsTemplateDeleteError: null,
  smsTemplateDeleteSuccess: false,
  smsTemplatesListUpdateNeeded: false,
});

const fetchAllSmsTemplatesStart = (
  state: SmsTemplateStateType,
): SmsTemplateStateType => ({
  ...state,
  allSmsTemplatesLoading: true,
});

const fetchAllSmsTemplatesSuccess = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  allSmsTemplates: action.allSmsTemplates,
  allSmsTemplatesLoading: false,
});

const fetchAllSmsTemplatesFail = (
  state: SmsTemplateStateType,
  action: SmsTemplateActionType,
): SmsTemplateStateType => ({
  ...state,
  allSmsTemplatesError: action.allSmsTemplatesError,
  allSmsTemplatesLoading: false,
});

const reducer = (state = initialState, action: SmsTemplateActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SMS_TEMPLATES_START:
      return fetchSmsTemplatesStart(state);
    case actionTypes.FETCH_SMS_TEMPLATES_SUCCESS:
      return fetchSmsTemplatesSuccess(state, action);
    case actionTypes.FETCH_SMS_TEMPLATES_FAIL:
      return fetchSmsTemplatesFail(state, action);
    case actionTypes.CREATE_SMS_TEMPLATE_START:
      return createSmsTemplateStart(state);
    case actionTypes.CREATE_SMS_TEMPLATE_SUCCESS:
      return createSmsTemplateSuccess(state, action);
    case actionTypes.CREATE_SMS_TEMPLATE_FAIL:
      return createSmsTemplateFail(state, action);
    case actionTypes.FETCH_SMS_TEMPLATE_START:
      return fetchSmsTemplateStart(state);
    case actionTypes.FETCH_SMS_TEMPLATE_SUCCESS:
      return fetchSmsTemplateSuccess(state, action);
    case actionTypes.FETCH_SMS_TEMPLATE_FAIL:
      return fetchSmsTemplateFail(state, action);
    case actionTypes.UPDATE_SMS_TEMPLATE_START:
      return updateSmsTemplateStart(state);
    case actionTypes.UPDATE_SMS_TEMPLATE_SUCCESS:
      return updateSmsTemplateSuccess(state);
    case actionTypes.UPDATE_SMS_TEMPLATE_FAIL:
      return updateSmsTemplateFail(state, action);
    case actionTypes.DELETE_SMS_TEMPLATE_START:
      return deleteSmsTemplateStart(state);
    case actionTypes.DELETE_SMS_TEMPLATE_SUCCESS:
      return deleteSmsTemplateSuccess(state);
    case actionTypes.DELETE_SMS_TEMPLATE_FAIL:
      return deleteSmsTemplateFail(state, action);
    case actionTypes.RESET_SMS_TEMPLATE_DELETE:
      return resetSmsTemplateDelete(state);
    case actionTypes.FETCH_ALL_SMS_TEMPLATES_START:
      return fetchAllSmsTemplatesStart(state);
    case actionTypes.FETCH_ALL_SMS_TEMPLATES_SUCCESS:
      return fetchAllSmsTemplatesSuccess(state, action);
    case actionTypes.FETCH_ALL_SMS_TEMPLATES_FAIL:
      return fetchAllSmsTemplatesFail(state, action);
    case actionTypes.RESET_SMS_TEMPLATES_STORE:
      return resetSmsTemplateStore();
    default:
      return state;
  }
};

export default reducer;
