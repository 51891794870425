export const FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAIL = 'FETCH_EMPLOYEES_FAIL';

export const FETCH_EMPLOYEES_STATISTICS_START =
  'FETCH_EMPLOYEES_STATISTICS_START';
export const FETCH_EMPLOYEES_STATISTICS_SUCCESS =
  'FETCH_EMPLOYEES_STATISTICS_SUCCESS';
export const FETCH_EMPLOYEES_STATISTICS_FAIL =
  'FETCH_EMPLOYEES_STATISTICS_FAIL';

export const FETCH_SINGLE_EMPLOYEE_STATISTICS_START =
  'FETCH_SINGLE_EMPLOYEE_STATISTICS_START';
export const FETCH_SINGLE_EMPLOYEE_STATISTICS_SUCCESS =
  'FETCH_SINGLE_EMPLOYEE_STATISTICS_SUCCESS';
export const FETCH_SINGLE_EMPLOYEE_STATISTICS_FAIL =
  'FETCH_SINGLE_EMPLOYEE_STATISTICS_FAIL';

export const RESET_EMPLOYEES_STATISTICS = 'RESET_EMPLOYEES_STATISTICS';

export const FETCH_EMPLOYEE_START = 'FETCH_EMPLOYEE_START';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAIL = 'FETCH_EMPLOYEE_FAIL';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL';

export const UPDATE_EMPLOYEE_START = 'UPDATE_EMPLOYEES_START';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEES_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEES_FAIL';

export const DELETE_EMPLOYEE_START = 'DELETE_EMPLOYEE_START';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';

export const SET_EMPLOYEE_FILTER = 'SET_EMPLOYEE_FILTER';
export const SET_EMPLOYEE_WINDOW_POSITION = 'SET_EMPLOYEE_WINDOW_POSITION';

export type EmployeeActionTypes =
  | typeof FETCH_EMPLOYEES_START
  | typeof FETCH_EMPLOYEES_SUCCESS
  | typeof FETCH_EMPLOYEES_FAIL
  | typeof FETCH_EMPLOYEES_STATISTICS_START
  | typeof FETCH_EMPLOYEES_STATISTICS_SUCCESS
  | typeof FETCH_EMPLOYEES_STATISTICS_FAIL
  | typeof RESET_EMPLOYEES_STATISTICS
  | typeof FETCH_EMPLOYEE_START
  | typeof FETCH_EMPLOYEE_SUCCESS
  | typeof FETCH_EMPLOYEE_FAIL
  | typeof CREATE_EMPLOYEE_START
  | typeof CREATE_EMPLOYEE_SUCCESS
  | typeof CREATE_EMPLOYEE_FAIL
  | typeof UPDATE_EMPLOYEE_START
  | typeof UPDATE_EMPLOYEE_SUCCESS
  | typeof UPDATE_EMPLOYEE_FAIL
  | typeof DELETE_EMPLOYEE_START
  | typeof DELETE_EMPLOYEE_SUCCESS
  | typeof DELETE_EMPLOYEE_FAIL
  | typeof FETCH_SINGLE_EMPLOYEE_STATISTICS_START
  | typeof FETCH_SINGLE_EMPLOYEE_STATISTICS_SUCCESS
  | typeof FETCH_SINGLE_EMPLOYEE_STATISTICS_FAIL
  | typeof SET_EMPLOYEE_FILTER
  | typeof SET_EMPLOYEE_WINDOW_POSITION;
