import * as actionTypes from './actionTypes';
import { NoteActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Note } from '../../domain/Note';

export type NoteStateType = {
  noteCreateLoading: boolean;
  noteCreateError: HttpError;
  noteCreateSuccess: boolean;
  noteDeleteLoading: boolean;
  noteDeleteError: HttpError;
  noteDeleteSuccess: boolean;
  createdNote: Note | null;
};

export type NoteActionType = NoteStateType & {
  type: NoteActionTypes;
};

export const initialState: NoteStateType = {
  noteCreateLoading: false,
  noteCreateError: null,
  noteCreateSuccess: false,
  noteDeleteLoading: false,
  noteDeleteError: null,
  noteDeleteSuccess: false,
  createdNote: null,
};

const createNoteStart = (state: NoteStateType): NoteStateType => ({
  ...state,
  noteCreateLoading: true,
  noteCreateSuccess: false,
});

const createNoteSuccess = (
  state: NoteStateType,
  action: NoteActionType,
): NoteStateType => ({
  ...state,
  noteCreateLoading: false,
  noteCreateError: null,
  noteCreateSuccess: true,
  createdNote: action.createdNote,
});

const createNoteFail = (
  state: NoteStateType,
  action: NoteActionType,
): NoteStateType => ({
  ...state,
  noteCreateLoading: false,
  noteCreateError: action.noteCreateError,
});

const deleteNoteStart = (state: NoteStateType): NoteStateType => ({
  ...state,
  noteDeleteLoading: true,
  noteDeleteSuccess: false,
});

const deleteNoteSuccess = (state: NoteStateType): NoteStateType => ({
  ...state,
  noteDeleteLoading: false,
  noteDeleteError: null,
  noteDeleteSuccess: true,
});

const deleteNoteFail = (
  state: NoteStateType,
  action: NoteActionType,
): NoteStateType => ({
  ...state,
  noteDeleteLoading: false,
  noteDeleteError: action.noteCreateError,
});

const resetNoteStore = (): NoteStateType => ({
  noteDeleteLoading: false,
  noteDeleteError: null,
  noteDeleteSuccess: false,
  noteCreateLoading: false,
  noteCreateError: null,
  noteCreateSuccess: false,
  createdNote: null,
});

const reducer = (state = initialState, action: NoteActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_NOTE_START:
      return createNoteStart(state);
    case actionTypes.CREATE_NOTE_SUCCESS:
      return createNoteSuccess(state, action);
    case actionTypes.CREATE_NOTE_FAIL:
      return createNoteFail(state, action);
    case actionTypes.DELETE_NOTE_START:
      return deleteNoteStart(state);
    case actionTypes.DELETE_NOTE_SUCCESS:
      return deleteNoteSuccess(state);
    case actionTypes.DELETE_NOTE_FAIL:
      return deleteNoteFail(state, action);
    case actionTypes.RESET_NOTE_STORE:
      return resetNoteStore();
    default:
      return state;
  }
};

export default reducer;
