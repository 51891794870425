import React, { ChangeEvent } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField as MuiTextField,
} from '@material-ui/core';

import styles from './TextField.module.scss';
import cx from 'classnames';
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import TimePicker from '@material-ui/lab/TimePicker';
import moment from 'moment';
import Alert from '../Alert/Alert';
import { enGB } from 'date-fns/esm/locale';
import FileInput from '../FileInput/FileInput';
import { Asset } from '../../domain/Asset';
import { HttpError } from '../../config/Axios/axios-instance';
import VideoInput from '../VideoInput/VideoInput';

type Props = {
  onChange: (event: ChangeEvent<any>) => void;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onCheckboxChange?: (name: string, value: string) => void;
  onRadioChange?: (name: string, value: string) => void;
  onTimeChange?: (name: string, value: string) => void;
  onBlur?: (event: ChangeEvent<any>) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  value?: string | string[] | File | File[];
  label?: string;
  errors?: Array<string>;
  name: string;
  type?: string;
  placeholder?: string;
  inputProps?: any;
  className?: string;
  options?: Array<{
    value: string;
    label: string;
    disabled?: boolean;
    checked?: boolean;
  }>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
  minDate?: string;
  maxFileCount?: number;
  multiple?: boolean;
  asset?: Asset | null;
  fileHelperText?: string;
};

export const DEFAULT_LOCALE = 'LT';
export const EN_LOCALE = 'EN';
export const RU_LOCALE = 'RU';

export type AvailableLocale =
  | typeof DEFAULT_LOCALE
  | typeof EN_LOCALE
  | typeof RU_LOCALE;

const TextField = ({
  onChange,
  onBlur,
  value,
  label,
  errors,
  name,
  type,
  placeholder,
  inputProps,
  className,
  onFileChange,
  onRadioChange,
  onCheckboxChange,
  options,
  labelPlacement,
  onTimeChange,
  disabled,
  helperText,
  clearable,
  minDate,
  onImageDelete,
  maxFileCount,
  multiple,
  asset,
  onSetValidationErrors,
  fileHelperText,
}: Props) => {
  if (type === 'file') {
    return (
      <FileInput
        value={value as File | File[] | string | string[] | undefined}
        name={name}
        helperText={helperText}
        label={label}
        onFileChange={onFileChange}
        maxFileCount={maxFileCount}
        multiple={multiple}
        errors={errors}
        asset={asset}
        onImageDelete={onImageDelete}
        onSetValidationErrors={onSetValidationErrors}
        fileHelperText={fileHelperText}
      />
    );
  }

  if (type === 'video') {
    return (
      <VideoInput
        value={value as File | File[] | string | string[] | undefined}
        name={name}
        helperText={helperText}
        label={label}
        onFileChange={onFileChange}
        maxFileCount={maxFileCount}
        errors={errors}
      />
    );
  }

  if (type === 'currency') {
    return (
      <>
        {helperText && <Alert variant="warning">{helperText}</Alert>}
        <MuiTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={errors && errors.length > 0}
          variant="outlined"
          name={name}
          helperText={errors && errors.length > 0 ? errors[0] : ''}
          className={cx(styles.textField, styles.currencyInput, className)}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Kr</InputAdornment>
            ),
          }}
        />
      </>
    );
  }

  if (type === 'time') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          value={
            typeof value === 'string' ? moment(value, 'HH:mm').toDate() : ''
          }
          onChange={(newValue) => {
            onTimeChange &&
              onTimeChange(name, moment(newValue?.toString()).format('HH:mm'));
          }}
          label={label}
          disabled={disabled}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.timeInput, className)}
              onBlur={onBlur}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'staticdate') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          label={label}
          value={value}
          className={styles.staticDatePicker}
          onChange={(newValue) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'datetime') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
        <DatePicker
          views={['year', 'month', 'day']}
          label={label}
          value={value ? value.toString() : null}
          onChange={(newValue) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          clearable={clearable}
          mask="____-__-__"
          disabled={disabled}
          inputFormat="dd-MM-yyyy"
          minDate={minDate ? new Date(minDate) : null}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControlLabel
        className={styles.switcherContainer}
        labelPlacement={labelPlacement}
        control={
          <Switch
            name={name}
            checked={value === '1'}
            onChange={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'radio' && options) {
    return (
      <FormControl className={styles.radioContainer}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup name={name} defaultValue={options[0].value}>
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                checked={option.checked}
                disabled={option.disabled}
                control={
                  <Radio
                    onChange={() =>
                      onRadioChange &&
                      onRadioChange(name, option.value as string)
                    }
                  />
                }
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }

  if (type === 'select') {
    return (
      <MuiTextField
        value={value}
        select
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant="outlined"
        name={name}
        helperText={errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type="number"
        placeholder={placeholder}
        disabled={disabled}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) ?? []}
      </MuiTextField>
    );
  }

  return (
    <MuiTextField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={errors && errors.length > 0}
      variant="outlined"
      name={name}
      helperText={errors && errors.length > 0 ? errors[0] : ''}
      className={cx(styles.textField, className)}
      type={type}
      placeholder={placeholder}
      InputProps={inputProps}
      multiline={type === 'textarea'}
      rows={5}
      disabled={disabled}
    />
  );
};

export default TextField;
