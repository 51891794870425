export const CREATE_ADDITIONAL_TASK_START = 'CREATE_ADDITIONAL_TASK_START';
export const CREATE_ADDITIONAL_TASK_SUCCESS = 'CREATE_ADDITIONAL_TASK_SUCCESS';
export const CREATE_ADDITIONAL_TASK_FAIL = 'CREATE_ADDITIONAL_TASK_FAIL';

export const DELETE_ADDITIONAL_TASK_START = 'DELETE_ADDITIONAL_TASK_START';
export const DELETE_ADDITIONAL_TASK_SUCCESS = 'DELETE_ADDITIONAL_TASK_SUCCESS';
export const DELETE_ADDITIONAL_TASK_FAIL = 'DELETE_ADDITIONAL_TASK_FAIL';

export const RESET_ADDITIONAL_TASK_STORE = 'RESET_NOTE_STORE';

export type AdditioanlTaskActionTypes =
  | typeof CREATE_ADDITIONAL_TASK_START
  | typeof CREATE_ADDITIONAL_TASK_SUCCESS
  | typeof CREATE_ADDITIONAL_TASK_FAIL
  | typeof RESET_ADDITIONAL_TASK_STORE
  | typeof DELETE_ADDITIONAL_TASK_START
  | typeof DELETE_ADDITIONAL_TASK_SUCCESS
  | typeof DELETE_ADDITIONAL_TASK_FAIL;
