import axios from '../../config/Axios/axios-instance';
import {
  createUserFail,
  createUserStart,
  createUserSuccess,
  deleteUserFail,
  deleteUserStart,
  deleteUserSuccess,
  fetchCurrentUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchUserFail,
  fetchUserListFail,
  fetchUserListStart,
  fetchUserListSuccess,
  fetchUserStart,
  fetchUserSuccess,
  resetCreateAndUpdateUser,
  resetDeleteUser,
  setUserSelectedCompanyFail,
  setUserSelectedCompanyStart,
  setUserSelectedCompanySuccess,
  updateUserFail,
  updateUserStart,
  updateUserSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { UserRole } from '../../domain/User';

const API_URL = '/users';

export type UserCreateRequest = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
};

export type UserUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  role: UserRole;
};

export const fetchUserList = (params?: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUserListStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUserListSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserListFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const createUser =
  (inputs: UserCreateRequest) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then(() => {
        dispatch(createUserSuccess());
        showToast('User was successfully created!', 'success');
        dispatch(resetCreateAndUpdateUser());
      })
      .catch((err) => {
        dispatch(createUserFail(err?.response?.data?.error));
        showToast(err?.response?.data?.message, 'error');
      });
  };

export const deleteUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(deleteUserStart());
  return axios
    .delete(`${API_URL}/${id}`)
    .then(() => {
      dispatch(deleteUserSuccess());
      showToast('User was successfully deleted!', 'success');
      dispatch(resetDeleteUser());
    })
    .catch((err) => {
      dispatch(deleteUserFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const fetchUser = (userId: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${userId}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const updateUser =
  (inputs: UserUpdateRequest) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    const { id, ...updatedUser } = inputs;
    return axios
      .patch(`${API_URL}/${id}`, {
        ...updatedUser,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast('User was successfully updated!', 'success');
        dispatch(resetCreateAndUpdateUser());
      })
      .catch((err) => {
        dispatch(updateUserFail(err?.response?.data?.error));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());
  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};

export const setUserSelectedCompany =
  (companyId: number) => (dispatch: Dispatch) => {
    dispatch(setUserSelectedCompanyStart());
    return axios
      .patch(`${API_URL}/set-selected-company/${companyId}`)
      .then(() => {
        dispatch(setUserSelectedCompanySuccess());
      })
      .catch((err) => {
        dispatch(setUserSelectedCompanyFail(err?.response?.data?.error));
        showToast(err?.response?.data?.message, 'error');
      });
  };
