import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';
import Button from '../../common/Button/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import cx from 'classnames';

type Props = {
  onConfirm?: () => void;
  onClose: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  explanationText?: string | undefined;
  title?: string;
  children?: ReactNode;
  className?: string;
};

const Modal = ({
  isOpen,
  onConfirm,
  onClose,
  explanationText,
  title,
  isLoading,
  children,
  className,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={cx(styles.modalContainer, className)}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
        {title}
        <IconButton onClick={onClose} className={styles.modalCloseButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {explanationText && (
          <DialogContentText id="alert-dialog-description">
            {explanationText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {onConfirm && (
        <DialogActions>
          <Button isConfirmationButton onClick={onClose}>
            {translate(intl, 'CONFIRMATION_DIALOG.CANCEL', 'Cancel')}
          </Button>
          <Button
            isConfirmationButton
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {translate(intl, 'CONFIRMATION_DIALOG.CONFIRM', 'Confirm')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
