import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: string | null;
  loginFacebookLoading: boolean;
  loginFacebookError: string | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  loginFacebookLoading: false,
  loginFacebookError: null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginFacebookStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginFacebookLoading: true,
});

const loginFacebookSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginFacebookError: null,
  loginFacebookLoading: false,
  isInitCompleted: true,
});

const loginFacebookFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginFacebookError: action.loginFacebookError,
  loginFacebookLoading: false,
  isInitCompleted: true,
});

const logout = (): AuthStateType => ({
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  isInitCompleted: true,
  loginFacebookLoading: false,
  loginFacebookError: null,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.LOGIN_FACEBOOK_START:
      return loginFacebookStart(state);
    case actionTypes.LOGIN_FACEBOOK_SUCCESS:
      return loginFacebookSuccess(state);
    case actionTypes.LOGIN_FACEBOOK_FAIL:
      return loginFacebookFail(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
