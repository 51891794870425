import React, { useEffect, useMemo } from 'react';
import styles from './ErrorFallback.module.scss';
import {
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const NEW_VERSION_ERRORS = [
  // eslint-disable-next-line max-len
  /Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
  /Loading chunk [\d]+ failed/,
  /undefined is not an object (evaluating 'e.response.data')/,
];

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
  const intl = useIntl();

  const isNewVersionError = useMemo(
    () =>
      NEW_VERSION_ERRORS.find((newVersionError) =>
        newVersionError.test(error.toString()),
      ),
    [error],
  );

  useEffect(() => {
    if (!isNewVersionError) {
      return;
    }

    setTimeout(() => {
      resetErrorBoundary();
    }, 3000);
  }, [isNewVersionError]);

  const getMessage = () => {
    if (isNewVersionError) {
      return translate(
        intl,
        'ERROR_FALLBACK.NEW_VERSION_IS_AVAILABLE',
        'New website version is available! You will be redirected in 3 seconds...',
      );
    }

    return translate(
      intl,
      'ERROR_FALLBACK.UNKNOWN_ERROR',
      'An unknown error occurred. Please try again.',
    );
  };

  return (
    <div className={styles.errorFallback}>
      <FontAwesomeIcon
        className={cx(styles.icon, { [styles.errorIcon]: !isNewVersionError })}
        icon={
          isNewVersionError
            ? (faExclamationCircle as IconProp)
            : (faExclamationTriangle as IconProp)
        }
        size="4x"
      />
      <div className={styles.errorMessage}>{getMessage()}</div>
      <div className={styles.refresh} onClick={() => resetErrorBoundary()}>
        {translate(intl, 'ERROR_FALLBACK.REFRESH', 'Click here to refresh')}
      </div>
    </div>
  );
};

export default ErrorFallback;
