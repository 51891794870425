import {
  EventStatus,
  Event,
  PriceType,
  ClientEvent,
  DayEvent,
  EventUpdateOverview,
} from '../../domain/Event';
import { translate } from '../messageTranslator/translate';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { AdditionalTask } from '../../domain/AdditionalTask';
import { SingleEmployeeStatistics } from '../../domain/Employee';

type TotalPriceType = 'TOTAL' | 'INVOICED' | 'NON_INVOICED';

export const getStatusName = (status: EventStatus, intl: IntlShape): string => {
  switch (status) {
    case EventStatus.Pending:
      return translate(intl, 'EVENTS_TABLE.STATUS_PENDING', 'Pending');
    case EventStatus.Cancelled:
      return translate(intl, 'EVENTS_TABLE.STATUS_CANCELLED', 'Cancelled');
    case EventStatus.Completed:
      return translate(intl, 'EVENTS_TABLE.STATUS_COMPLETED', 'Completed');
    case EventStatus.Scheduled:
      return translate(intl, 'EVENTS_TABLE.STATUS_SCHEDULED', 'Confirmed');
    case EventStatus.Paid:
      return translate(intl, 'EVENTS_TABLE.STATUS_Paid', 'Paid');
    default:
      return '';
  }
};

export const getStatusVariant = (status: EventStatus): string => {
  switch (status) {
    case EventStatus.Pending:
      return 'warning';
    case EventStatus.Cancelled:
      return 'error';
    case EventStatus.Completed:
      return 'success';
    case EventStatus.Scheduled:
      return 'info';
    case EventStatus.Paid:
      return 'success';
    default:
      return '';
  }
};

export const getFormattedEventEnd = (event: Event) =>
  moment(event.time, 'HH:mm')
    .add(moment.duration(event.duration))
    .format('HH:mm');

export const getEventTotalPrice = (
  price: string,
  priceType: PriceType,
  additionalTasks: AdditionalTask[],
): string => {
  if (priceType === PriceType.Hourly) {
    if (additionalTasks.length) {
      return `${price} Kr/hr + ${_.sumBy(additionalTasks, (task) =>
        Number(task.price),
      )} Kr.`;
    }

    return `${price} Kr/hr.`;
  }

  if (additionalTasks.length) {
    return `${price} Kr. + ${_.sumBy(additionalTasks, (task) =>
      Number(task.price),
    )} Kr.`;
  }

  return `${price} Kr.`;
};

export const getFormattedEmployeeEventTotalMoneyCollected = (
  employeeStatistics: SingleEmployeeStatistics[],
): string => {
  const result = _.sumBy(employeeStatistics, (statistics) =>
    Number(statistics.event.moneyCollected),
  );

  return `${result} Kr.`;
};

export const getFormattedEmployeeEventsTotalPrice = (
  employeeStatistics: SingleEmployeeStatistics[],
  type: TotalPriceType,
): string => {
  const result = _.sumBy(
    employeeStatistics.filter((statistics) => {
      if (type === 'TOTAL') {
        return statistics.event.invoiceNeeded;
      }

      if (type === 'NON_INVOICED') {
        return !statistics.event.invoiceNeeded;
      }

      return true;
    }),
    (statistics) =>
      getEmployeeEventsPrice(
        Number(statistics.event.price),
        statistics.event.priceType,
        statistics.event.additionalTasks,
      ),
  );

  return `${result} Kr.`;
};

const getEmployeeEventsPrice = (
  price: number,
  priceType: PriceType,
  additionalTasks: AdditionalTask[],
): number => {
  if (priceType === PriceType.Hourly) {
    if (additionalTasks.length) {
      return price + _.sumBy(additionalTasks, (task) => Number(task.price));
    }

    return price;
  }

  if (additionalTasks.length) {
    return price + _.sumBy(additionalTasks, (task) => Number(task.price));
  }

  return price;
};

const getEventDurationRatio = (event: Event | ClientEvent): number => {
  if (!event.duration) {
    return 0;
  }

  const hours = event.duration.split(':')[0];
  const minutes = event.duration.split(':')[1];

  const result = Number(hours) + (Number(minutes) / 100) * 1.67;

  return result;
};

export const getEventPrice = (event: Event | ClientEvent): number | null => {
  if (event.price === null) {
    return null;
  }

  const totalAdditionalTaskPrice = _.sumBy(event.additionalTasks, (task) =>
    Number(task.price),
  );

  const eventDurationRatio = getEventDurationRatio(event);

  if (event.priceType === PriceType.Hourly) {
    return (
      Number(event.price) * eventDurationRatio +
      Number(totalAdditionalTaskPrice)
    );
  }

  return Number(event.price) + Number(totalAdditionalTaskPrice);
};

export const getEventDuration = (event: Event | ClientEvent): string => {
  if (!event.duration) {
    return '';
  }

  const startTime = moment(event.duration, 'HH:mm');

  for (const task of event.additionalTasks) {
    startTime.add(task.duration);
  }

  return startTime.format('HH:mm').toString();
};

export const getEventStartTime = (event: Event | ClientEvent): string => {
  return event.startTime
    ? moment(event.startTime, 'HH:mm').format('HH:mm').toString()
    : '';
};

export const getEventComment = (event: Event): string => {
  if (event?.schedule?.location?.comment && event.comment)
    return `${event?.schedule?.location?.comment}, ${event.comment}`;

  if (event?.schedule?.location?.comment)
    return event?.schedule?.location?.comment;

  return event.comment;
};

export const isFutureEvent = (eventDate: Date): boolean =>
  moment(eventDate).startOf('day').isAfter(moment().startOf('day'));

const updateEventIfMatched = (
  event: Event,
  updateInfo: EventUpdateOverview,
): Event => {
  if (event.id === updateInfo.id) {
    return {
      ...event,
      teamName: updateInfo.teamName ?? '',
      startTime: updateInfo.startTime,
      teamMembers: updateInfo.teamMembers,
    };
  }
  return event;
};

const addEventToExistingTeam = (
  eventLists: DayEvent[],
  updatedEvent: Event,
): boolean => {
  const teamToFind = eventLists.find((team) => team.withTeam);
  if (!teamToFind) return false;

  teamToFind.events.push(updatedEvent);
  return true;
};

const addEventToNewTeam = (
  eventLists: DayEvent[],
  updatedEvent: Event,
): void => {
  const newTeam = {
    teamId: 0,
    withTeam: true,
    name: '',
    events: [updatedEvent],
  };

  eventLists.push(newTeam);
};

const rearrangeEventsInList = (
  eventLists: DayEvent[],
  updatedEvent: Event,
  updateInfo: EventUpdateOverview,
): DayEvent[] => {
  const currentList = eventLists.find((list) =>
    list.events.some((event) => event.id === updateInfo.id),
  );

  if (!currentList) {
    return eventLists;
  }

  currentList.events = currentList.events.filter(
    (event) => event.id !== updateInfo.id,
  );

  const targetList = eventLists.find(
    (list) => list.name === updateInfo.teamName,
  );

  if (targetList) {
    targetList.events.push(updatedEvent);
    return eventLists;
  }

  if (!updateInfo.teamName) {
    const addedToExistingTeam = addEventToExistingTeam(
      eventLists,
      updatedEvent,
    );

    if (!addedToExistingTeam) {
      addEventToNewTeam(eventLists, updatedEvent);
    }
  }

  return eventLists;
};

export const updateEventList = (
  eventLists: DayEvent[],
  updateInfo: EventUpdateOverview,
): DayEvent[] => {
  let updatedEvent: Event | null = null;

  const updatedEventList = eventLists.map((list) => ({
    ...list,
    events: list.events.map((event) => {
      const updated = updateEventIfMatched(event, updateInfo);

      if (updated !== event) {
        updatedEvent = updated;
      }

      return updated;
    }),
  }));

  if (updatedEvent) {
    return rearrangeEventsInList(updatedEventList, updatedEvent, updateInfo);
  }

  return updatedEventList;
};

export const getEventsTotalTime = (events: Event[]) => {
  const eventTotalTime = events.map((event) => {
    const totalEventTime = moment.duration(event.duration);

    event.additionalTasks.forEach((task) => {
      totalEventTime.add(moment.duration(task.duration));
    });

    return totalEventTime;
  });

  const totalDuration = moment.duration();
  eventTotalTime.forEach((duration) => {
    totalDuration.add(duration);
  });

  const formattedTotalDuration = moment
    .utc(totalDuration.asMilliseconds())
    .format('HH:mm');

  return formattedTotalDuration;
};
