export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const LOGIN_FACEBOOK_START = 'LOGIN_FACEBOOK_START';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAIL = 'LOGIN_FACEBOOK_FAIL';

export const LOGOUT = 'LOGOUT';

export type AuthActionTypes =
  | typeof LOGIN_GOOGLE_START
  | typeof LOGIN_GOOGLE_SUCCESS
  | typeof LOGIN_GOOGLE_FAIL
  | typeof LOGIN_FACEBOOK_START
  | typeof LOGIN_FACEBOOK_SUCCESS
  | typeof LOGIN_FACEBOOK_FAIL
  | typeof LOGOUT;
