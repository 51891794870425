export const FETCH_SMS_TEMPLATES_START = 'FETCH_SMS_TEMPLATES_START';
export const FETCH_SMS_TEMPLATES_SUCCESS = 'FETCH_SMS_TEMPLATES_SUCCESS';
export const FETCH_SMS_TEMPLATES_FAIL = 'FETCH_SMS_TEMPLATES_FAIL';

export const CREATE_SMS_TEMPLATE_START = 'CREATE_SMS_TEMPLATE_START';
export const CREATE_SMS_TEMPLATE_SUCCESS = 'CREATE_SMS_TEMPLATE_SUCCESS';
export const CREATE_SMS_TEMPLATE_FAIL = 'CREATE_SMS_TEMPLATE_FAIL';

export const FETCH_SMS_TEMPLATE_START = 'FETCH_SMS_TEMPLATE_START';
export const FETCH_SMS_TEMPLATE_SUCCESS = 'FETCH_SMS_TEMPLATE_SUCCESS';
export const FETCH_SMS_TEMPLATE_FAIL = 'FETCH_SMS_TEMPLATE_FAIL';

export const UPDATE_SMS_TEMPLATE_START = 'UPDATE_SMS_TEMPLATE_START';
export const UPDATE_SMS_TEMPLATE_SUCCESS = 'UPDATE_SMS_TEMPLATE_SUCCESS';
export const UPDATE_SMS_TEMPLATE_FAIL = 'UPDATE_SMS_TEMPLATE_FAIL';

export const DELETE_SMS_TEMPLATE_START = 'DELETE_SMS_TEMPLATE_START';
export const DELETE_SMS_TEMPLATE_SUCCESS = 'DELETE_SMS_TEMPLATE_SUCCESS';
export const DELETE_SMS_TEMPLATE_FAIL = 'DELETE_SMS_TEMPLATE_FAIL';
export const RESET_SMS_TEMPLATE_DELETE = 'RESET_SMS_TEMPLATE_DELETE';

export const FETCH_ALL_SMS_TEMPLATES_START = 'FETCH_ALL_SMS_TEMPLATES_START';
export const FETCH_ALL_SMS_TEMPLATES_SUCCESS =
  'FETCH_ALL_SMS_TEMPLATES_SUCCESS';
export const FETCH_ALL_SMS_TEMPLATES_FAIL = 'FETCH_ALL_SMS_TEMPLATES_FAIL';

export const RESET_SMS_TEMPLATES_STORE = 'RESET_SMS_TEMPLATES_SEND';

export type SmsTemplateActionTypes =
  | typeof FETCH_SMS_TEMPLATES_START
  | typeof FETCH_SMS_TEMPLATES_SUCCESS
  | typeof FETCH_SMS_TEMPLATES_FAIL
  | typeof CREATE_SMS_TEMPLATE_START
  | typeof CREATE_SMS_TEMPLATE_SUCCESS
  | typeof CREATE_SMS_TEMPLATE_FAIL
  | typeof FETCH_SMS_TEMPLATE_START
  | typeof FETCH_SMS_TEMPLATE_SUCCESS
  | typeof FETCH_SMS_TEMPLATE_FAIL
  | typeof UPDATE_SMS_TEMPLATE_START
  | typeof UPDATE_SMS_TEMPLATE_SUCCESS
  | typeof UPDATE_SMS_TEMPLATE_FAIL
  | typeof DELETE_SMS_TEMPLATE_START
  | typeof DELETE_SMS_TEMPLATE_SUCCESS
  | typeof DELETE_SMS_TEMPLATE_FAIL
  | typeof RESET_SMS_TEMPLATE_DELETE
  | typeof FETCH_ALL_SMS_TEMPLATES_START
  | typeof FETCH_ALL_SMS_TEMPLATES_SUCCESS
  | typeof FETCH_ALL_SMS_TEMPLATES_FAIL
  | typeof RESET_SMS_TEMPLATES_STORE;
