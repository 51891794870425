export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';

export const FETCH_LOCATION_START = 'FETCH_LOCATION_START';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATIONS_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATIONS_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATIONS_FAIL';

export const UPLOAD_LOCATION_ENTRANCE_START = 'UPLOAD_LOCATION_ENTRANCE_START';
export const UPLOAD_LOCATION_ENTRANCE_SUCCESS =
  'UPLOAD_LOCATION_ENTRANCE_SUCCESS';
export const UPLOAD_LOCATION_ENTRANCE_FAIL = 'UPLOAD_LOCATION_ENTRANCE_FAIL';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const FETCH_CLIENT_LOCATIONS_START = 'FETCH_CLIENT_LOCATIONS_START';
export const FETCH_CLIENT_LOCATIONS_SUCCESS = 'FETCH_CLIENT_LOCATIONS_SUCCESS';
export const FETCH_CLIENT_LOCATIONS_FAIL = 'FETCH_CLIENT_LOCATIONS_FAIL';

export const UPLOAD_LOCATION_VIDEO_START = 'UPLOAD_LOCATION_VIDEO_START';
export const UPLOAD_LOCATION_VIDEO_SUCCESS = 'UPLOAD_LOCATION_VIDEO_SUCCESS';
export const UPLOAD_LOCATION_VIDEO_FAIL = 'UPLOAD_LOCATION_VIDEO_FAIL';
export const RESET_UPLOAD_LOCATION_VIDEO = 'RESET_UPLOAD_LOCATION_VIDEO';

export const RESET_UPLOAD_LOCATION_ENTRANCE = 'RESET_UPLOAD_LOCATION_ENTRANCE';

export const RESET_CREATE_AND_UPDATE_LOCATION =
  'RESET_CREATE_AND_UPDATE_LOCATION';

export const RESET_CLIENT_LOCATIONS_LIST = 'RESET_CLIENT_LOCATIONS_LIST';

export const RESET_CREATED_LOCATION = 'RESET_CREATED_LOCATION';

export type LocationActionTypes =
  | typeof FETCH_LOCATIONS_START
  | typeof FETCH_LOCATIONS_SUCCESS
  | typeof FETCH_LOCATIONS_FAIL
  | typeof FETCH_LOCATION_START
  | typeof FETCH_LOCATION_SUCCESS
  | typeof FETCH_LOCATION_FAIL
  | typeof CREATE_LOCATION_START
  | typeof CREATE_LOCATION_SUCCESS
  | typeof CREATE_LOCATION_FAIL
  | typeof UPDATE_LOCATION_START
  | typeof UPDATE_LOCATION_SUCCESS
  | typeof UPDATE_LOCATION_FAIL
  | typeof DELETE_LOCATION_START
  | typeof DELETE_LOCATION_SUCCESS
  | typeof DELETE_LOCATION_FAIL
  | typeof FETCH_CLIENT_LOCATIONS_START
  | typeof FETCH_CLIENT_LOCATIONS_SUCCESS
  | typeof FETCH_CLIENT_LOCATIONS_FAIL
  | typeof UPLOAD_LOCATION_ENTRANCE_START
  | typeof UPLOAD_LOCATION_ENTRANCE_SUCCESS
  | typeof UPLOAD_LOCATION_ENTRANCE_FAIL
  | typeof RESET_UPLOAD_LOCATION_ENTRANCE
  | typeof RESET_CREATE_AND_UPDATE_LOCATION
  | typeof RESET_CLIENT_LOCATIONS_LIST
  | typeof RESET_CREATED_LOCATION
  | typeof UPLOAD_LOCATION_VIDEO_START
  | typeof UPLOAD_LOCATION_VIDEO_SUCCESS
  | typeof UPLOAD_LOCATION_VIDEO_FAIL
  | typeof RESET_UPLOAD_LOCATION_VIDEO;
