import * as actionTypes from './actionTypes';
import { SmsMessageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type SmsMessageStateType = {
  sendSmsMessagesLoading: boolean;
  sendSmsMessagesError: HttpError;
  sendSmsMessagesSuccess: boolean;
};

export type SmsMessageActionType = SmsMessageStateType & {
  type: SmsMessageActionTypes;
};

export const initialState: SmsMessageStateType = {
  sendSmsMessagesLoading: false,
  sendSmsMessagesError: null,
  sendSmsMessagesSuccess: false,
};

const sendSmsMessagesStart = (
  state: SmsMessageStateType,
): SmsMessageStateType => ({
  ...state,
  sendSmsMessagesLoading: true,
});

const sendSmsMessagesSuccess = (
  state: SmsMessageStateType,
): SmsMessageStateType => ({
  ...state,
  sendSmsMessagesLoading: false,
  sendSmsMessagesError: null,
  sendSmsMessagesSuccess: true,
});

const sendSmsMessagesFail = (
  state: SmsMessageStateType,
  action: SmsMessageActionType,
): SmsMessageStateType => ({
  ...state,
  sendSmsMessagesError: action.sendSmsMessagesError,
  sendSmsMessagesLoading: false,
});

const resetSendSmsMessages = (
  state: SmsMessageStateType,
): SmsMessageStateType => ({
  ...state,
  sendSmsMessagesLoading: false,
  sendSmsMessagesSuccess: false,
  sendSmsMessagesError: null,
});

const reducer = (state = initialState, action: SmsMessageActionType) => {
  switch (action.type) {
    case actionTypes.SEND_SMS_MESSAGES_START:
      return sendSmsMessagesStart(state);
    case actionTypes.SEND_SMS_MESSAGES_SUCCESS:
      return sendSmsMessagesSuccess(state);
    case actionTypes.SEND_SMS_MESSAGES_FAIL:
      return sendSmsMessagesFail(state, action);
    case actionTypes.RESET_SMS_MESSAGES_SEND:
      return resetSendSmsMessages(state);
    default:
      return state;
  }
};

export default reducer;
