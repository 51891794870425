import * as actionTypes from './actionTypes';
import { CityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { City, CitiesList } from '../../domain/City';

export type CityStateType = {
  citiesList: CitiesList | null;
  citiesLoading: boolean;
  citiesError: HttpError;
  citiesListUpdateNeeded: boolean;
  cityCreateLoading: boolean;
  cityCreateError: HttpError;
  cityCreateSuccess: boolean;
  createdCity: City | null;
  cityUpdateLoading: boolean;
  cityUpdateError: HttpError;
  cityUpdateSuccess: boolean;
  cityDeleteLoading: boolean;
  cityDeleteError: HttpError;
  city: City | null;
  cityLoading: boolean;
  cityError: HttpError;
  cityEditUpdateNeeded: boolean;
};

export type CityActionType = CityStateType & {
  type: CityActionTypes;
};

export const initialState: CityStateType = {
  citiesList: null,
  citiesLoading: true,
  citiesError: null,
  citiesListUpdateNeeded: false,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: false,
  createdCity: null,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: false,
  cityDeleteLoading: false,
  cityDeleteError: null,
  city: null,
  cityLoading: false,
  cityError: null,
  cityEditUpdateNeeded: false,
};

const fetchCitiesStart = (state: CityStateType): CityStateType => ({
  ...state,
  citiesLoading: true,
});

const fetchCitiesSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesList: action.citiesList,
  citiesLoading: false,
  citiesError: null,
  citiesListUpdateNeeded: false,
});

const fetchCitiesFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesError: action.citiesError,
  citiesLoading: false,
});

const fetchCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityLoading: true,
  cityUpdateSuccess: false,
  cityCreateSuccess: false,
  cityCreateError: null,
  cityUpdateError: null,
});

const fetchCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  city: action.city,
  cityLoading: false,
  cityError: null,
});

const fetchCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityError: action.cityError,
  cityLoading: false,
});

const createCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityCreateLoading: true,
});

const createCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: true,
  createdCity: action.createdCity,
});

const createCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: action.cityCreateError,
});

const updateCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: true,
});

const updateCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: true,
  cityEditUpdateNeeded: true,
});

const updateCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: action.cityUpdateError,
});

const deleteCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: true,
});

const deleteCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteError: null,
  citiesListUpdateNeeded: true,
});

const deleteCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteError: action.cityDeleteError,
});

const resetCreateAndDeleteCity = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: false,
  cityEditUpdateNeeded: false,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: false,
});

const reducer = (state = initialState, action: CityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CITIES_START:
      return fetchCitiesStart(state);
    case actionTypes.FETCH_CITIES_SUCCESS:
      return fetchCitiesSuccess(state, action);
    case actionTypes.FETCH_CITIES_FAIL:
      return fetchCitiesFail(state, action);
    case actionTypes.FETCH_CITY_START:
      return fetchCityStart(state);
    case actionTypes.FETCH_CITY_SUCCESS:
      return fetchCitySuccess(state, action);
    case actionTypes.FETCH_CITY_FAIL:
      return fetchCityFail(state, action);
    case actionTypes.CREATE_CITY_START:
      return createCityStart(state);
    case actionTypes.CREATE_CITY_SUCCESS:
      return createCitySuccess(state, action);
    case actionTypes.CREATE_CITY_FAIL:
      return createCityFail(state, action);
    case actionTypes.UPDATE_CITY_START:
      return updateCityStart(state);
    case actionTypes.UPDATE_CITY_SUCCESS:
      return updateCitySuccess(state);
    case actionTypes.UPDATE_CITY_FAIL:
      return updateCityFail(state, action);
    case actionTypes.DELETE_CITY_START:
      return deleteCityStart(state);
    case actionTypes.DELETE_CITY_SUCCESS:
      return deleteCitySuccess(state);
    case actionTypes.DELETE_CITY_FAIL:
      return deleteCityFail(state, action);
    case actionTypes.RESET_CREATE_AND_UPDATE_CITY:
      return resetCreateAndDeleteCity(state);
    default:
      return state;
  }
};

export default reducer;
