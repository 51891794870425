export const FETCH_INVOICE_ITEM_START = 'FETCH_INVOICE_ITEM_START';
export const FETCH_INVOICE_ITEM_SUCCESS = 'FETCH_INVOICE_ITEM_SUCCESS';
export const FETCH_INVOICE_ITEM_FAIL = 'FETCH_INVOICE_ITEM_FAIL';

export const FETCH_INVOICE_ITEMS_START = 'FETCH_INVOICE_ITEMS_START';
export const FETCH_INVOICE_ITEMS_SUCCESS = 'FETCH_INVOICE_ITEMS_SUCCESS';
export const FETCH_INVOICE_ITEMS_FAIL = 'FETCH_INVOICE_ITEMS_FAIL';

export const CREATE_INVOICE_ITEM_START = 'CREATE_INVOICE_ITEM_START';
export const CREATE_INVOICE_ITEM_SUCCESS = 'CREATE_INVOICE_ITEM_SUCCESS';
export const CREATE_INVOICE_ITEM_FAIL = 'CREATE_INVOICE_ITEM_FAIL';

export const UPDATE_INVOICE_ITEM_START = 'UPDATE_INVOICE_ITEM_START';
export const UPDATE_INVOICE_ITEM_SUCCESS = 'UPDATE_INVOICE_ITEM_SUCCESS';
export const UPDATE_INVOICE_ITEM_FAIL = 'UPDATE_INVOICE_ITEM_FAIL';

export const DELETE_INVOICE_ITEM_START = 'DELETE_INVOICE_ITEM_START';
export const DELETE_INVOICE_ITEM_SUCCESS = 'DELETE_INVOICE_ITEM_SUCCESS';
export const DELETE_INVOICE_ITEM_FAIL = 'DELETE_INVOICE_ITEM_FAIL';

export const RESET_CREATE_AND_UPDATE_INVOICE_ITEM =
  'RESET_CREATE_AND_UPDATE_INVOICE_ITEM';

export type InvoicesActionTypes =
  | typeof FETCH_INVOICE_ITEM_START
  | typeof FETCH_INVOICE_ITEM_SUCCESS
  | typeof FETCH_INVOICE_ITEM_FAIL
  | typeof FETCH_INVOICE_ITEMS_START
  | typeof FETCH_INVOICE_ITEMS_SUCCESS
  | typeof FETCH_INVOICE_ITEMS_FAIL
  | typeof CREATE_INVOICE_ITEM_START
  | typeof CREATE_INVOICE_ITEM_SUCCESS
  | typeof CREATE_INVOICE_ITEM_FAIL
  | typeof UPDATE_INVOICE_ITEM_START
  | typeof UPDATE_INVOICE_ITEM_SUCCESS
  | typeof UPDATE_INVOICE_ITEM_FAIL
  | typeof DELETE_INVOICE_ITEM_START
  | typeof DELETE_INVOICE_ITEM_SUCCESS
  | typeof DELETE_INVOICE_ITEM_FAIL
  | typeof RESET_CREATE_AND_UPDATE_INVOICE_ITEM;
