import * as actionTypes from './actionTypes';
import { AdditioanlTaskActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { AdditionalTask } from '../../domain/AdditionalTask';

export type AdditionalTaskStateType = {
  additionalTaskCreateLoading: boolean;
  additionalTaskCreateError: HttpError;
  additionalTaskCreateSuccess: boolean;
  createAdditionalTask: AdditionalTask | null;
  additionalTaskDeleteLoading: boolean;
  additionalTaskDeleteError: HttpError;
  additionalTaskListUpdateNeeded: boolean;
};

export type AdditionalTaskActionType = AdditionalTaskStateType & {
  type: AdditioanlTaskActionTypes;
};

export const initialState: AdditionalTaskStateType = {
  additionalTaskCreateLoading: false,
  additionalTaskCreateError: null,
  additionalTaskCreateSuccess: false,
  createAdditionalTask: null,
  additionalTaskDeleteLoading: false,
  additionalTaskDeleteError: null,
  additionalTaskListUpdateNeeded: false,
};

const createAdditionalTaskStart = (
  state: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskCreateLoading: true,
  additionalTaskCreateSuccess: false,
});

const createAdditionalTaskSuccess = (
  state: AdditionalTaskStateType,
  action: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskCreateLoading: false,
  additionalTaskCreateError: null,
  additionalTaskCreateSuccess: true,
  createAdditionalTask: action.createAdditionalTask,
});

const createAdditionalTaskFail = (
  state: AdditionalTaskStateType,
  action: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskCreateLoading: false,
  additionalTaskCreateError: action.additionalTaskCreateError,
});

const resetAdditionalTaskStore = (
  state: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskCreateLoading: false,
  additionalTaskCreateError: null,
  additionalTaskCreateSuccess: false,
  createAdditionalTask: null,
  additionalTaskDeleteLoading: false,
  additionalTaskDeleteError: null,
  additionalTaskListUpdateNeeded: false,
});

const deleteAdditionalTaskStart = (
  state: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskDeleteLoading: true,
});

const deleteAdditionalTaskSuccess = (
  state: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskDeleteLoading: false,
  additionalTaskDeleteError: null,
  additionalTaskListUpdateNeeded: true,
});

const deleteAdditionalTaskFail = (
  state: AdditionalTaskStateType,
  action: AdditionalTaskStateType,
): AdditionalTaskStateType => ({
  ...state,
  additionalTaskDeleteLoading: false,
  additionalTaskDeleteError: action.additionalTaskDeleteError,
});

const reducer = (state = initialState, action: AdditionalTaskActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ADDITIONAL_TASK_START:
      return createAdditionalTaskStart(state);
    case actionTypes.CREATE_ADDITIONAL_TASK_SUCCESS:
      return createAdditionalTaskSuccess(state, action);
    case actionTypes.CREATE_ADDITIONAL_TASK_FAIL:
      return createAdditionalTaskFail(state, action);
    case actionTypes.RESET_ADDITIONAL_TASK_STORE:
      return resetAdditionalTaskStore(state);
    case actionTypes.DELETE_ADDITIONAL_TASK_START:
      return deleteAdditionalTaskStart(state);
    case actionTypes.DELETE_ADDITIONAL_TASK_SUCCESS:
      return deleteAdditionalTaskSuccess(state);
    case actionTypes.DELETE_ADDITIONAL_TASK_FAIL:
      return deleteAdditionalTaskFail(state, action);
    default:
      return state;
  }
};

export default reducer;
