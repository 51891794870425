export const SEND_SMS_MESSAGES_START = 'SEND_SMS_MESSAGES_START';
export const SEND_SMS_MESSAGES_SUCCESS = 'SEND_SMS_MESSAGES_SUCCESS';
export const SEND_SMS_MESSAGES_FAIL = 'SEND_SMS_MESSAGES_FAIL';
export const RESET_SMS_MESSAGES_SEND = 'RESET_SMS_MESSAGES_SEND';

export type SmsMessageActionTypes =
  | typeof SEND_SMS_MESSAGES_START
  | typeof SEND_SMS_MESSAGES_SUCCESS
  | typeof SEND_SMS_MESSAGES_FAIL
  | typeof RESET_SMS_MESSAGES_SEND;
