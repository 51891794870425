import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserList, User } from '../../domain/User';

export type UserStateType = {
  userList: UserList | null;
  userListUpdateNeeded: boolean;
  userListLoading: boolean;
  userListError: HttpError;
  userCreateLoading: boolean;
  userCreateSuccess: boolean;
  userCreateError: HttpError;
  userUpdateLoading: boolean;
  userUpdateSuccess: boolean;
  userUpdateError: HttpError;
  userDeleteLoading: boolean;
  userDeleteSuccess: boolean;
  userDeleteError: HttpError;
  userLoading: boolean;
  userSuccess: boolean;
  userError: HttpError;
  user: User | null;
  currentUser: User | null;
  currentUserLoading: boolean;
  userSetSelectedCompanyLoading: boolean;
  userSetSelectedCompanySuccess: boolean;
  userSetSelectedCompanyError: HttpError;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  userList: null,
  userListUpdateNeeded: false,
  userListLoading: true,
  userListError: null,
  userCreateLoading: true,
  userCreateSuccess: false,
  userCreateError: null,
  userDeleteLoading: true,
  userDeleteSuccess: false,
  userDeleteError: null,
  userLoading: true,
  userSuccess: false,
  userError: null,
  user: null,
  userUpdateError: null,
  userUpdateLoading: true,
  userUpdateSuccess: false,
  currentUser: null,
  currentUserLoading: false,
  userSetSelectedCompanyLoading: true,
  userSetSelectedCompanySuccess: false,
  userSetSelectedCompanyError: null,
};

const fetchUserListStart = (state: UserStateType): UserStateType => ({
  ...state,
  userListLoading: true,
});

const fetchUserListSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userList: action.userList,
  userListLoading: false,
  userListError: null,
  userListUpdateNeeded: false,
});

const fetchUserListFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userListError: action.userListError,
  userListLoading: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateSuccess: true,
  userCreateError: null,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateError: action.userCreateError,
  userCreateLoading: false,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteSuccess: true,
  userDeleteLoading: false,
  userDeleteError: null,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteError: action.userDeleteError,
  userDeleteLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
  userUpdateSuccess: false,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateSuccess: true,
  userUpdateError: null,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateError: action.userDeleteError,
  userUpdateLoading: false,
});

const resetCreateAndUpdateUser = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
  userCreateSuccess: false,
  userCreateError: null,
  userUpdateLoading: true,
  userUpdateSuccess: false,
  userUpdateError: null,
});

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const resetDeleteUser = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteSuccess: false,
  userDeleteLoading: true,
  userDeleteError: null,
});

const resetUserStoreLogout = (): UserStateType => ({
  ...initialState,
});

const userSetSelectedCompanyStart = (state: UserStateType): UserStateType => {
  window.location.reload();

  return {
    ...state,
    userSetSelectedCompanyLoading: true,
    userSetSelectedCompanySuccess: false,
  };
};

const userSetSelectedCompanySuccess = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userSetSelectedCompanyLoading: false,
  userSetSelectedCompanySuccess: true,
  userSetSelectedCompanyError: null,
});

const userSetSelectedCompanyFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userSetSelectedCompanyError: action.userSetSelectedCompanyError,
  userSetSelectedCompanyLoading: false,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_LIST_START:
      return fetchUserListStart(state);
    case actionTypes.FETCH_USER_LIST_SUCCESS:
      return fetchUserListSuccess(state, action);
    case actionTypes.FETCH_USER_LIST_FAIL:
      return fetchUserListFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.RESET_CREATE_AND_UPDATE_USER:
      return resetCreateAndUpdateUser(state);
    case actionTypes.RESET_USER_STORE_LOGOUT:
      return resetUserStoreLogout();
    case actionTypes.RESET_DELETE_USER:
      return resetDeleteUser(state);
    case actionTypes.SET_SELECT_USER_COMPANY_START:
      return userSetSelectedCompanyStart(state);
    case actionTypes.SET_SELECT_USER_COMPANY_SUCCESS:
      return userSetSelectedCompanySuccess(state);
    case actionTypes.SET_SELECT_USER_COMPANY_FAIL:
      return userSetSelectedCompanyFail(state, action);
    default:
      return state;
  }
};

export default reducer;
