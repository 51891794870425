export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const RESET_CREATE_AND_UPDATE_USER = 'RESET_CREATE_AND_UPDATE_USER';

export const RESET_USER_STORE_LOGOUT = 'RESET_USER_STORE_LOGOUT';

export const RESET_DELETE_USER = 'RESET_DELETE_USER';

export const SET_SELECT_USER_COMPANY_START = 'SET_SELECT_USER_COMPANY_START';
export const SET_SELECT_USER_COMPANY_SUCCESS =
  'SET_SELECT_USER_COMPANY_SUCCESS';
export const SET_SELECT_USER_COMPANY_FAIL = 'SET_SELECT_USER_COMPANY_FAIL';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_USER_LIST_START
  | typeof FETCH_USER_LIST_SUCCESS
  | typeof FETCH_USER_LIST_FAIL
  | typeof CREATE_USER_START
  | typeof CREATE_USER_SUCCESS
  | typeof CREATE_USER_FAIL
  | typeof DELETE_USER_START
  | typeof DELETE_USER_SUCCESS
  | typeof DELETE_USER_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof RESET_CREATE_AND_UPDATE_USER
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof RESET_USER_STORE_LOGOUT
  | typeof RESET_DELETE_USER
  | typeof SET_SELECT_USER_COMPANY_START
  | typeof SET_SELECT_USER_COMPANY_SUCCESS
  | typeof SET_SELECT_USER_COMPANY_FAIL;
