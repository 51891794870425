import * as actionTypes from './actionTypes';
import { ClientActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Client, ClientsList } from '../../domain/Client';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type ClientStateType = {
  clientsList: ClientsList | null;
  clientsLoading: boolean;
  clientsError: HttpError;
  clientsListUpdateNeeded: boolean;
  clientCreateLoading: boolean;
  clientCreateError: HttpError;
  clientCreateSuccess: boolean;
  createdClient: Client | null;
  clientUpdateLoading: boolean;
  clientUpdateError: HttpError;
  clientUpdateSuccess: boolean;
  clientDeleteLoading: boolean;
  clientDeleteError: HttpError;
  client: Client | null;
  clientLoading: boolean;
  clientError: HttpError;
  clientEditUpdateNeeded: boolean;
  clientsWithPhoneList: Client[] | null;
  clientsWithPhoneLoading: boolean;
  clientsWithPhoneError: HttpError;
  allCompanyClients: Client[] | [];
  allCompanyClientsLoading: boolean;
  allCompanyClientsError: HttpError;
  clientFilter: ListParams;
  windowPosition: number;
  setClientStatusActiveLoading: boolean;
  setClientStatusActiveError: HttpError;
  setClientStatusArchivedLoading: boolean;
  setClientStatusArchivedError: HttpError;
};

export type ClientActionType = ClientStateType & {
  type: ClientActionTypes;
};

export const initialState: ClientStateType = {
  clientsList: null,
  clientsLoading: true,
  clientsError: null,
  clientsListUpdateNeeded: false,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: false,
  createdClient: null,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: false,
  clientDeleteLoading: false,
  clientDeleteError: null,
  client: null,
  clientLoading: false,
  clientError: null,
  clientEditUpdateNeeded: false,
  clientsWithPhoneList: null,
  clientsWithPhoneLoading: true,
  clientsWithPhoneError: null,
  allCompanyClients: [],
  allCompanyClientsLoading: true,
  allCompanyClientsError: null,
  windowPosition: 0,
  clientFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'client.createdAt',
    search: '',
    sortDirection: 'DESC',
    page: 0,
    limit: 100,
    teamIds: null,
  },
  setClientStatusActiveLoading: false,
  setClientStatusActiveError: null,
  setClientStatusArchivedLoading: false,
  setClientStatusArchivedError: null,
};

const fetchClientsStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientsLoading: true,
  clientsList: null,
});

const fetchClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsList: action.clientsList,
  clientsLoading: false,
  clientsError: null,
  clientsListUpdateNeeded: false,
});

const fetchClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsError: action.clientsError,
  clientsLoading: false,
});

const fetchClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientLoading: true,
  clientUpdateSuccess: false,
  clientCreateSuccess: false,
  clientCreateError: null,
  clientUpdateError: null,
});

const fetchClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  client: action.client,
  clientLoading: false,
  clientError: null,
});

const fetchClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientError: action.clientError,
  clientLoading: false,
});

const createClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientCreateLoading: true,
});

const createClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: true,
  createdClient: action.createdClient,
});

const createClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: action.clientCreateError,
});

const updateClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: true,
});

const updateClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: true,
  clientEditUpdateNeeded: true,
});

const updateClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: action.clientUpdateError,
});

const deleteClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: true,
});

const deleteClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientsListUpdateNeeded: true,
});

const deleteClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: action.clientDeleteError,
});

const fetchClientsWithPhoneStart = (
  state: ClientStateType,
): ClientStateType => ({
  ...state,
  clientsWithPhoneLoading: true,
});

const fetchClientsWithPhoneSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsWithPhoneList: action.clientsWithPhoneList,
  clientsWithPhoneLoading: false,
  clientsWithPhoneError: null,
});

const fetchClientsWithPhoneFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsWithPhoneError: action.clientsError,
  clientsWithPhoneLoading: false,
});

const setClientFilter = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientFilter: action.clientFilter,
});

const resetClientFilter = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientFilter: {
    sortBy: 'client.createdAt',
    search: '',
    sortDirection: 'DESC',
    page: 0,
    limit: 100,
    teamIds: null,
  },
});

const resetCreatedClient = (state: ClientStateType): ClientStateType => ({
  ...state,
  createdClient: null,
});

const resetCreateClient = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: false,
});

const setWindowPosition = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  windowPosition: action.windowPosition,
});

const fetchAllCompanyClientsStart = (
  state: ClientStateType,
): ClientStateType => ({
  ...state,
  allCompanyClientsLoading: true,
});

const fetchAllCompanyClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allCompanyClients: action.allCompanyClients,
  allCompanyClientsLoading: false,
});

const fetchAllCompanyClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allCompanyClientsError: action.allCompanyClientsError,
  allCompanyClientsLoading: false,
});

const setClientStatusActiveStart = (
  state: ClientStateType,
): ClientStateType => ({
  ...state,
  setClientStatusActiveLoading: true,
});

const setClientStatusActiveSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  setClientStatusActiveLoading: false,
  clientsListUpdateNeeded: true,
});

const setClientStatusActiveFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  setClientStatusActiveLoading: false,
  setClientStatusActiveError: action.setClientStatusArchivedError,
});

const setClientStatusArchivedStart = (
  state: ClientStateType,
): ClientStateType => ({
  ...state,
  setClientStatusArchivedLoading: true,
});

const setClientStatusArchivedSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  setClientStatusArchivedLoading: false,
  clientsListUpdateNeeded: true,
});

const setClientStatusArchivedFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  setClientStatusArchivedLoading: false,
  setClientStatusArchivedError: action.setClientStatusArchivedError,
});

const resetWindowPosition = (state: ClientStateType): ClientStateType => ({
  ...state,
  windowPosition: 0,
});

const reducer = (state = initialState, action: ClientActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CLIENTS_START:
      return fetchClientsStart(state);
    case actionTypes.FETCH_CLIENTS_SUCCESS:
      return fetchClientsSuccess(state, action);
    case actionTypes.FETCH_CLIENTS_FAIL:
      return fetchClientsFail(state, action);
    case actionTypes.FETCH_CLIENT_START:
      return fetchClientStart(state);
    case actionTypes.FETCH_CLIENT_SUCCESS:
      return fetchClientSuccess(state, action);
    case actionTypes.FETCH_CLIENT_FAIL:
      return fetchClientFail(state, action);
    case actionTypes.CREATE_CLIENT_START:
      return createClientStart(state);
    case actionTypes.CREATE_CLIENT_SUCCESS:
      return createClientSuccess(state, action);
    case actionTypes.CREATE_CLIENT_FAIL:
      return createClientFail(state, action);
    case actionTypes.UPDATE_CLIENT_START:
      return updateClientStart(state);
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return updateClientSuccess(state);
    case actionTypes.UPDATE_CLIENT_FAIL:
      return updateClientFail(state, action);
    case actionTypes.DELETE_CLIENT_START:
      return deleteClientStart(state);
    case actionTypes.DELETE_CLIENT_SUCCESS:
      return deleteClientSuccess(state);
    case actionTypes.DELETE_CLIENT_FAIL:
      return deleteClientFail(state, action);
    case actionTypes.FETCH_CLIENTS_WITH_PHONE_START:
      return fetchClientsWithPhoneStart(state);
    case actionTypes.FETCH_CLIENTS_WITH_PHONE_SUCCESS:
      return fetchClientsWithPhoneSuccess(state, action);
    case actionTypes.FETCH_CLIENTS_WITH_PHONE_FAIL:
      return fetchClientsWithPhoneFail(state, action);
    case actionTypes.FETCH_ALL_COMPANY_CLIENTS_START:
      return fetchAllCompanyClientsStart(state);
    case actionTypes.FETCH_ALL_COMPANY_CLIENTS_SUCCESS:
      return fetchAllCompanyClientsSuccess(state, action);
    case actionTypes.FETCH_ALL_COMPANY_CLIENTS_FAIL:
      return fetchAllCompanyClientsFail(state, action);
    case actionTypes.SET_CLIENT_FILTER:
      return setClientFilter(state, action);
    case actionTypes.SET_CLIENT_STATUS_ACTIVE_START:
      return setClientStatusActiveStart(state);
    case actionTypes.SET_CLIENT_STATUS_ACTIVE_SUCCESS:
      return setClientStatusActiveSuccess(state, action);
    case actionTypes.SET_CLIENT_STATUS_ACTIVE_FAIL:
      return setClientStatusActiveFail(state, action);
    case actionTypes.SET_CLIENT_STATUS_ARCHIVED_START:
      return setClientStatusArchivedStart(state);
    case actionTypes.SET_CLIENT_STATUS_ARCHIVED_SUCCESS:
      return setClientStatusArchivedSuccess(state, action);
    case actionTypes.SET_CLIENT_STATUS_ARCHIVED_FAIL:
      return setClientStatusArchivedFail(state, action);
    case actionTypes.RESET_CLIENT_FILTER:
      return resetClientFilter(state);
    case actionTypes.RESET_CREATED_CLIENT:
      return resetCreatedClient(state);
    case actionTypes.RESET_CREATE_CLIENT:
      return resetCreateClient(state);
    case actionTypes.SET_CLIENT_WINDOW_POSITION:
      return setWindowPosition(state, action);
    case actionTypes.RESET_CLIENT_WINDOW_POSITION:
      return resetWindowPosition(state);
    default:
      return state;
  }
};

export default reducer;
