import * as actionTypes from './actionTypes';
import { TeamActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { TeamsList, Team } from '../../domain/Team';

export type TeamStateType = {
  teamsList: TeamsList | null;
  teamsLoading: boolean;
  teamsError: HttpError;
  teamsListUpdateNeeded: boolean;
  teamCreateLoading: boolean;
  teamCreateError: HttpError;
  teamCreateSuccess: boolean;
  createdTeam: Team | null;
  teamUpdateLoading: boolean;
  teamUpdateError: HttpError;
  teamUpdateSuccess: boolean;
  teamDeleteLoading: boolean;
  teamDeleteError: HttpError;
  team: Team | null;
  teamLoading: boolean;
  teamError: HttpError;
};

export type TeamActionType = TeamStateType & {
  type: TeamActionTypes;
};

export const initialState: TeamStateType = {
  teamsList: null,
  teamsLoading: true,
  teamsError: null,
  teamsListUpdateNeeded: false,
  teamCreateLoading: false,
  teamCreateError: null,
  teamCreateSuccess: false,
  createdTeam: null,
  teamUpdateLoading: false,
  teamUpdateError: null,
  teamUpdateSuccess: false,
  teamDeleteLoading: false,
  teamDeleteError: null,
  team: null,
  teamLoading: false,
  teamError: null,
};

const fetchTeamsStart = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamsLoading: true,
});

const fetchTeamsSuccess = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamsList: action.teamsList,
  teamsLoading: false,
  teamsError: null,
  teamsListUpdateNeeded: false,
});

const fetchTeamsFail = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamsError: action.teamsError,
  teamsLoading: false,
});

const fetchTeamStart = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamLoading: true,
  teamUpdateSuccess: false,
  teamCreateSuccess: false,
  teamCreateError: null,
  teamUpdateError: null,
});

const fetchTeamSuccess = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  team: action.team,
  teamLoading: false,
  teamError: null,
});

const fetchTeamFail = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamError: action.teamError,
  teamLoading: false,
});

const createTeamStart = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamCreateLoading: true,
});

const createTeamSuccess = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamCreateLoading: false,
  teamCreateError: null,
  teamCreateSuccess: true,
  createdTeam: action.createdTeam,
});

const createTeamFail = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamCreateLoading: false,
  teamCreateError: action.teamCreateError,
});

const updateTeamStart = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamUpdateLoading: true,
});

const updateTeamSuccess = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamUpdateLoading: false,
  teamUpdateError: null,
  teamUpdateSuccess: true,
});

const updateTeamFail = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamUpdateLoading: false,
  teamUpdateError: action.teamUpdateError,
});

const deleteTeamStart = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamDeleteLoading: true,
});

const deleteTeamSuccess = (state: TeamStateType): TeamStateType => ({
  ...state,
  teamDeleteLoading: false,
  teamDeleteError: null,
  teamsListUpdateNeeded: true,
});

const deleteTeamFail = (
  state: TeamStateType,
  action: TeamActionType,
): TeamStateType => ({
  ...state,
  teamDeleteLoading: false,
  teamDeleteError: action.teamDeleteError,
});

const resetTeamStore = (): TeamStateType => ({
  teamsList: null,
  teamsLoading: true,
  teamsError: null,
  teamsListUpdateNeeded: false,
  teamCreateLoading: false,
  teamCreateError: null,
  teamCreateSuccess: false,
  createdTeam: null,
  teamUpdateLoading: false,
  teamUpdateError: null,
  teamUpdateSuccess: false,
  teamDeleteLoading: false,
  teamDeleteError: null,
  team: null,
  teamLoading: false,
  teamError: null,
});

const reducer = (state = initialState, action: TeamActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TEAMS_START:
      return fetchTeamsStart(state);
    case actionTypes.FETCH_TEAMS_SUCCESS:
      return fetchTeamsSuccess(state, action);
    case actionTypes.FETCH_TEAMS_FAIL:
      return fetchTeamsFail(state, action);
    case actionTypes.FETCH_TEAM_START:
      return fetchTeamStart(state);
    case actionTypes.FETCH_TEAM_SUCCESS:
      return fetchTeamSuccess(state, action);
    case actionTypes.FETCH_TEAM_FAIL:
      return fetchTeamFail(state, action);
    case actionTypes.CREATE_TEAM_START:
      return createTeamStart(state);
    case actionTypes.CREATE_TEAM_SUCCESS:
      return createTeamSuccess(state, action);
    case actionTypes.CREATE_TEAM_FAIL:
      return createTeamFail(state, action);
    case actionTypes.UPDATE_TEAM_START:
      return updateTeamStart(state);
    case actionTypes.UPDATE_TEAM_SUCCESS:
      return updateTeamSuccess(state);
    case actionTypes.UPDATE_TEAM_FAIL:
      return updateTeamFail(state, action);
    case actionTypes.DELETE_TEAM_START:
      return deleteTeamStart(state);
    case actionTypes.DELETE_TEAM_SUCCESS:
      return deleteTeamSuccess(state);
    case actionTypes.DELETE_TEAM_FAIL:
      return deleteTeamFail(state, action);
    case actionTypes.RESET_TEAM_STORE:
      return resetTeamStore();
    default:
      return state;
  }
};

export default reducer;
