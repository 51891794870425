export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';

export const FETCH_CLIENT_START = 'FETCH_CLIENT_START';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_CLIENT_FAIL = 'FETCH_CLIENT_FAIL';

export const CREATE_CLIENT_START = 'CREATE_CLIENT_START';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';

export const UPDATE_CLIENT_START = 'UPDATE_CLIENTS_START';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENTS_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENTS_FAIL';

export const DELETE_CLIENT_START = 'DELETE_CLIENT_START';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

export const FETCH_CLIENTS_WITH_PHONE_START = 'FETCH_CLIENTS_WITH_PHONE_START';
export const FETCH_CLIENTS_WITH_PHONE_SUCCESS =
  'FETCH_CLIENTS_WITH_PHONE_SUCCESS';
export const FETCH_CLIENTS_WITH_PHONE_FAIL = 'FETCH_CLIENTS_WITH_PHONE_FAIL';

export const FETCH_ALL_COMPANY_CLIENTS_START =
  'FETCH_ALL_COMPANY_CLIENTS_START';
export const FETCH_ALL_COMPANY_CLIENTS_SUCCESS =
  'FETCH_ALL_COMPANY_CLIENTS_SUCCESS';
export const FETCH_ALL_COMPANY_CLIENTS_FAIL = 'FETCH_ALL_COMPANY_CLIENTS_FAIL';

export const SET_CLIENT_STATUS_ACTIVE_START = 'SET_CLIENT_STATUS_ACTIVE_START';
export const SET_CLIENT_STATUS_ACTIVE_SUCCESS =
  'SET_CLIENT_STATUS_ACTIVE_SUCCESS';
export const SET_CLIENT_STATUS_ACTIVE_FAIL = 'SET_CLIENT_STATUS_ACTIVE_FAIL';

export const SET_CLIENT_STATUS_ARCHIVED_START =
  'SET_CLIENT_STATUS_ARCHIVED_START';
export const SET_CLIENT_STATUS_ARCHIVED_SUCCESS =
  'SET_CLIENT_STATUS_ARCHIVED_SUCCESS';
export const SET_CLIENT_STATUS_ARCHIVED_FAIL =
  'SET_CLIENT_STATUS_ARCHIVED_FAIL';

export const SET_CLIENT_FILTER = 'SET_CLIENT_FILTER';
export const RESET_CLIENT_FILTER = 'RESET_CLIENT_FILTER';

export const RESET_CREATED_CLIENT = 'RESET_CREATED_CLIENT';

export const RESET_CREATE_CLIENT = 'RESET_CREATE_CLIENT';

export const SET_CLIENT_WINDOW_POSITION = 'SET_CLIENT_WINDOW_POSITION';
export const RESET_CLIENT_WINDOW_POSITION = 'RESET_CLIENT_WINDOW_POSITION';

export type ClientActionTypes =
  | typeof FETCH_CLIENTS_START
  | typeof FETCH_CLIENTS_SUCCESS
  | typeof FETCH_CLIENTS_FAIL
  | typeof SET_CLIENT_STATUS_ARCHIVED_START
  | typeof SET_CLIENT_STATUS_ARCHIVED_SUCCESS
  | typeof SET_CLIENT_STATUS_ARCHIVED_FAIL
  | typeof SET_CLIENT_STATUS_ACTIVE_START
  | typeof SET_CLIENT_STATUS_ACTIVE_SUCCESS
  | typeof SET_CLIENT_STATUS_ACTIVE_FAIL
  | typeof FETCH_CLIENT_START
  | typeof FETCH_CLIENT_SUCCESS
  | typeof FETCH_CLIENT_FAIL
  | typeof CREATE_CLIENT_START
  | typeof CREATE_CLIENT_SUCCESS
  | typeof CREATE_CLIENT_FAIL
  | typeof UPDATE_CLIENT_START
  | typeof UPDATE_CLIENT_SUCCESS
  | typeof UPDATE_CLIENT_FAIL
  | typeof DELETE_CLIENT_START
  | typeof DELETE_CLIENT_SUCCESS
  | typeof DELETE_CLIENT_FAIL
  | typeof FETCH_CLIENTS_WITH_PHONE_START
  | typeof FETCH_CLIENTS_WITH_PHONE_SUCCESS
  | typeof FETCH_CLIENTS_WITH_PHONE_FAIL
  | typeof SET_CLIENT_FILTER
  | typeof RESET_CLIENT_FILTER
  | typeof RESET_CREATED_CLIENT
  | typeof RESET_CREATE_CLIENT
  | typeof FETCH_ALL_COMPANY_CLIENTS_START
  | typeof FETCH_ALL_COMPANY_CLIENTS_SUCCESS
  | typeof FETCH_ALL_COMPANY_CLIENTS_FAIL
  | typeof SET_CLIENT_WINDOW_POSITION
  | typeof RESET_CLIENT_WINDOW_POSITION;
