import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  faSuitcase,
  faUsers,
  faUser,
  faMap,
  faReceipt,
  faBuilding,
  faCalendar,
  faEnvelope,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Company } from '../../domain/Company';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../components/Company/CompanySelectForm/CompanySelectForm';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../store/company/service';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { connect } from 'react-redux';
import { UserRole, User } from '../../domain/User';
import { setSelectedCompany } from '../../store/company/actions';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  companyOptions: Company[];
  onCompanyOptionsFetch: () => void;
  currentUser: User | null;
  onSetSelectedCompany: (selectedCompany: Company) => void;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon: IconProp;
  roles: UserRole[];
};

export type NavigationGroup = {
  label: string;
  roles: UserRole[];
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 1200;

const IGNORED_ROUTES = ['/review', '/invoice-client'];

const Layout = ({
  children,
  isAuthenticated,
  companyOptions,
  onCompanyOptionsFetch,
  currentUser,
  onSetSelectedCompany,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  const { width } = useWindowSize();
  const routeLocation = useLocation();
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (currentUser?.role === UserRole.OWNER ||
        currentUser?.role === UserRole.ADMIN)
    ) {
      onCompanyOptionsFetch();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.selectedCompanyId && companyOptions.length === 0) {
      return;
    }

    const selectedCompany = companyOptions.find(
      (companyOption) => companyOption.id === currentUser?.selectedCompanyId,
    );

    setCompany(selectedCompany);

    if (selectedCompany) {
      onSetSelectedCompany(selectedCompany);
    }
  }, [companyOptions, currentUser?.selectedCompanyId]);

  useEffect(() => {
    if (currentUser?.selectedCompanyId) {
      setIsModalOpen(false);
    }
  }, [currentUser?.selectedCompanyId]);

  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  if (!isAuthenticated || isRouteIgnored) {
    return <>{children}</>;
  }

  const GENERAL_ITEMS = {
    label: translate(intl, 'SIDEBAR.CATEGORY_GENERAL', 'General settings'),
    roles: [UserRole.ADMIN],
    items: [
      {
        label: translate(intl, 'SIDEBAR.COMPANIES', 'Companies'),
        to: '/companies',
        icon: faBuilding as IconProp,
        roles: [UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.USERS', 'Users'),
        to: '/users',
        icon: faUser as IconProp,
        roles: [UserRole.ADMIN],
      },
    ],
  };

  const COMPANY_SETTINGS = {
    label: translate(intl, 'SIDEBAR.COMPANY_SETTINGS', 'Company settings'),
    roles: [
      UserRole.ACCOUNTANT,
      UserRole.EMPLOYEE,
      UserRole.ADMIN,
      UserRole.OWNER,
    ],
    items: [
      {
        label: translate(intl, 'SIDEBAR.DAY_EVENTS', 'Day Events'),
        to: '/events',
        icon: faCalendar as IconProp,
        roles: [UserRole.ADMIN, UserRole.OWNER, UserRole.EMPLOYEE],
      },
      {
        label: translate(intl, 'SIDEBAR.INVOICES', 'Invoices'),
        to: '/invoices',
        icon: faReceipt as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.ACCOUNTANT],
      },
      {
        label: translate(intl, 'SIDEBAR.EMPLOYEES', 'Employees'),
        to: '/employees',
        icon: faUser as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.CLIENTS', 'Clients'),
        to: '/clients',
        icon: faSuitcase as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.TEAMS', 'Teams'),
        to: '/teams',
        icon: faUsers as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.CITIES', 'Cities'),
        to: '/cities',
        icon: faBuilding as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.LOCATIONS', 'Locations'),
        to: '/locations',
        icon: faMap as IconProp,
        roles: [UserRole.OWNER, UserRole.ADMIN],
      },
      {
        label: translate(intl, 'SIDEBAR.SMS_TEMPLATES', 'SMS Templates'),
        to: '/sms-templates',
        icon: faEnvelope as IconProp,
        roles: [UserRole.ADMIN, UserRole.OWNER],
      },
      {
        label: translate(intl, 'SIDEBAR.TIME_OFFS', 'Time offs'),
        to: '/time-offs',
        icon: faClock as IconProp,
        roles: [UserRole.ADMIN, UserRole.OWNER],
      },
    ],
  };

  const SIDEBAR_ITEMS: NavigationGroup[] = [COMPANY_SETTINGS, GENERAL_ITEMS];

  const getNavigationGroups = (): NavigationGroup[] => {
    const sidebarItems = SIDEBAR_ITEMS.filter(
      (item) => currentUser && item.roles.includes(currentUser.role),
    );

    if (!company && currentUser?.role === UserRole.OWNER) {
      return [];
    }

    if (!company && currentUser?.role === UserRole.ADMIN) {
      return [GENERAL_ITEMS].filter(
        (item) => currentUser && item.roles.includes(currentUser.role),
      );
    }

    return sidebarItems;
  };

  const onCompanySelect = () => {
    setIsModalOpen(false);

    if (routeLocation.pathname === '/') {
      history.push('/events');
    }
  };

  const getCurrentCompany = () => (
    <CurrentAccount
      title={translate(intl, 'COMPANIES.NO_COMPANY_SELECTED', '')}
      subTitle={
        company
          ? `${translate(intl, 'COMPANIES.SELECTED_COMPANY', '')}:`
          : translate(intl, 'COMPANIES.CLICK_TO_SELECT', '')
      }
      companyName={company?.name}
      onClick={() => !isModalOpen && setIsModalOpen(true)}
    >
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={translate(intl, 'COMPANIES.SELECT_COMPANY_TITLE', '')}
        className={styles.companySelectModal}
      >
        {isModalOpen && (
          <CompanySelectForm
            onSuccessSelect={onCompanySelect}
            selectedCompany={company}
          />
        )}
      </Modal>
    </CurrentAccount>
  );

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.layout}>
        <Navigation
          onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
          isMobileMenuOpen={isMobileMenuOpen}
        />
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentCompany()}
            currentUser={currentUser}
          />
        )}
        <div
          className={cx(styles.withSidebar, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {(currentUser?.role === UserRole.ADMIN ||
            currentUser?.role === UserRole.OWNER) &&
            getCurrentCompany()}
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (navItem) =>
                    currentUser && navItem.roles.includes(currentUser.role),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={(isActive) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  companyOptions: state.company.companyOptions,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: () => dispatch(companyService.fetchCompanyOptions()),
  onSetSelectedCompany: (selectedCompany: Company) =>
    dispatch(setSelectedCompany(selectedCompany)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
