import * as actionTypes from './actionTypes';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = () => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const loginFacebookStart = () => ({
  type: actionTypes.LOGIN_FACEBOOK_START,
});

export const loginFacebookSuccess = () => ({
  type: actionTypes.LOGIN_FACEBOOK_SUCCESS,
});

export const loginFacebookFail = (loginFacebookError: string) => ({
  type: actionTypes.LOGIN_FACEBOOK_FAIL,
  loginFacebookError,
});

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('selectedCompany');
  return {
    type: actionTypes.LOGOUT,
  };
};
