import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company } from '../../domain/Company';

export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companyList: Company[]) => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  companyList,
});

export const fetchCompaniesFail = (clientsListError: HttpError) => ({
  type: actionTypes.FETCH_COMPANIES_FAIL,
  clientsListError,
});

export const createCompanyStart = () => ({
  type: actionTypes.CREATE_COMPANY_START,
});

export const createCompanySuccess = () => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
});

export const createCompanyFail = (createCompanyFail: HttpError) => ({
  type: actionTypes.CREATE_COMPANY_FAIL,
  createCompanyFail,
});

export const resetCreateAndUpdateCompany = () => ({
  type: actionTypes.RESET_CREATE_AND_UPDATE_COMPANY,
});

export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START,
});

export const fetchCompanySuccess = (company: Company) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  company,
});

export const fetchCompanyFail = (clientsListError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  clientsListError,
});

export const updateCompanyStart = () => ({
  type: actionTypes.UPDATE_COMPANY_START,
});

export const updateCompanySuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFail = (updateCompanyFail: HttpError) => ({
  type: actionTypes.UPDATE_COMPANY_FAIL,
  updateCompanyFail,
});

export const fetchCompanyOptionsStart = () => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_START,
});

export const fetchCompanyOptionsSuccess = (companyOptions: Company[]) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS,
  companyOptions,
});

export const fetchCompanyOptionsFail = (companyOptionsError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_FAIL,
  companyOptionsError,
});

export const setSelectedCompanyId = (selectedCompanyId: number) => {
  localStorage.setItem('selectedCompany', selectedCompanyId.toString());
  return {
    type: actionTypes.SET_SELECTED_COMPANY_ID,
    selectedCompanyId,
  };
};

export const resetCompanyStore = () => ({
  type: actionTypes.RESET_COMPANY_STORE,
});

export const deleteCompanyStart = () => ({
  type: actionTypes.DELETE_COMPANY_START,
});

export const deleteCompanySuccess = () => ({
  type: actionTypes.DELETE_COMPANY_SUCCESS,
});

export const deleteCompanyFail = (deleteCompanyError: HttpError) => ({
  type: actionTypes.DELETE_COMPANY_FAIL,
  deleteCompanyError,
});

export const setSelectedCompany = (selectedCompany: Company) => ({
  type: actionTypes.SET_SELECTED_COMPANY,
  selectedCompany,
});
