export const FETCH_CITIES_START = 'FETCH_CITIES_START';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAIL = 'FETCH_CITIES_FAIL';

export const FETCH_CITY_START = 'FETCH_CITY_START';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAIL = 'FETCH_CITY_FAIL';

export const CREATE_CITY_START = 'CREATE_CITY_START';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAIL = 'CREATE_CITY_FAIL';

export const UPDATE_CITY_START = 'UPDATE_CITIES_START';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITIES_SUCCESS';
export const UPDATE_CITY_FAIL = 'UPDATE_CITIES_FAIL';

export const DELETE_CITY_START = 'DELETE_CITY_START';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAIL = 'DELETE_CITY_FAIL';

export const RESET_CREATE_AND_UPDATE_CITY = 'RESET_CREATE_AND_UPDATE_CITY';

export type CityActionTypes =
  | typeof FETCH_CITIES_START
  | typeof FETCH_CITIES_SUCCESS
  | typeof FETCH_CITIES_FAIL
  | typeof FETCH_CITY_START
  | typeof FETCH_CITY_SUCCESS
  | typeof FETCH_CITY_FAIL
  | typeof CREATE_CITY_START
  | typeof CREATE_CITY_SUCCESS
  | typeof CREATE_CITY_FAIL
  | typeof UPDATE_CITY_START
  | typeof UPDATE_CITY_SUCCESS
  | typeof UPDATE_CITY_FAIL
  | typeof DELETE_CITY_START
  | typeof DELETE_CITY_SUCCESS
  | typeof DELETE_CITY_FAIL
  | typeof RESET_CREATE_AND_UPDATE_CITY;
