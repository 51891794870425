export const FETCH_INVOICES_START = 'FETCH_INVOICES_START';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAIL = 'FETCH_INVOICES_FAIL';

export const FETCH_INVOICE_START = 'FETCH_INVOICE_START';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAIL = 'FETCH_INVOICE_FAIL';

export const SEND_INVOICE_START = 'SEND_INVOICE_START';
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS';
export const SEND_INVOICE_FAIL = 'SEND_INVOICE_FAIL';

export const SET_INVOICE_PAID_START = 'SET_INVOICE_PAID_START';
export const SET_INVOICE_PAID_SUCCESS = 'SET_INVOICE_PAID_SUCCESS';
export const SET_INVOICE_PAID_FAIL = 'SET_INVOICE_PAID_FAIL';

export const CREATE_INVOICE_START = 'CREATE_INVOICE_START';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL';

export const UPDATE_INVOICE_START = 'UPDATE_INVOICE_START';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAIL = 'UPDATE_INVOICE_FAIL';

export const PREVIEW_INVOICE_START = 'PREVIEW_INVOICE_START';
export const PREVIEW_INVOICE_SUCCESS = 'PREVIEW_INVOICE_SUCCESS';
export const PREVIEW_INVOICE_FAIL = 'PREVIEW_INVOICE_FAIL';

export const DOWNLOAD_INVOICE_START = 'DOWNLOAD_INVOICE_START';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export const DOWNLOAD_MULTIPLE_INVOICES_START =
  'DOWNLOAD_MULTIPLE_INVOICES_START';
export const DOWNLOAD_MULTIPLE_INVOICES_SUCCESS =
  'DOWNLOAD_MULTIPLE_INVOICES_SUCCESS';
export const DOWNLOAD_MULTIPLE_INVOICES_FAIL =
  'DOWNLOAD_MULTIPLE_INVOICES_FAIL';

export const DOWNLOAD_CLIENT_INVOICE_START = 'DOWNLOAD_CLIENT_INVOICE_START';
export const DOWNLOAD_CLIENT_INVOICE_SUCCESS =
  'DOWNLOAD_CLIENT_INVOICE_SUCCESS';
export const DOWNLOAD_CLIENT_INVOICE_FAIL = 'DOWNLOAD_CLIENT_INVOICE_FAIL';

export const REST_UPDATE_OR_CREATE_INVOICE = 'REST_UPDATE_OR_CREATE_INVOICE';

export const FETCH_CLIENT_INVOICES_START = 'FETCH_CLIENT_INVOICES_START';
export const FETCH_CLIENT_INVOICES_SUCCESS = 'FETCH_CLIENT_INVOICES_SUCCESS';
export const FETCH_CLIENT_INVOICES_FAIL = 'FETCH_CLIENT_INVOICES_FAIL';

export const SET_INVOICE_FILTER = 'SET_INVOICE_FILTER';
export const RESET_INVOICE_FILTER = 'RESET_INVOICE_FILTER';

export const SET_INVOICE_WINDOW_POSITION = 'SET_INVOICE_WINDOW_POSITION';
export const RESET_INVOICE_WINDOW_POSITION = 'RESET_INVOICE_WINDOW_POSITION';

export const CREATE_CREDIT_INVOICE_START = 'CREATE_CREDIT_INVOICE_START';
export const CREATE_CREDIT_INVOICE_SUCCESS = 'CREATE_CREDIT_INVOICE_SUCCESS';
export const CREATE_CREDIT_INVOICE_FAIL = 'CREATE_CREDIT_INVOICE_FAIL';

export const UPDATE_CREDIT_INVOICE_START = 'UPDATE_CREDIT_INVOICE_START';
export const UPDATE_CREDIT_INVOICE_SUCCESS = 'UPDATE_CREDIT_INVOICE_SUCCESS';
export const UPDATE_CREDIT_INVOICE_FAIL = 'UPDATE_CREDIT_INVOICE_FAIL';

export const REST_CREATE_CREDIT_INVOICE = 'REST_CREATE_CREDIT_INVOICE';

export const DELETE_INVOICE_START = 'DELETE_INVOICE_START';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAIL = 'DELETE_INVOICE_FAIL';

export const UNSET_INVOICE_PAID_START = 'UNSET_INVOICE_PAID_START';
export const UNSET_INVOICE_PAID_SUCCESS = 'UNSET_INVOICE_PAID_SUCCESS';
export const UNSET_INVOICE_PAID_FAIL = 'UNSET_INVOICE_PAID_FAIL';

export const COPY_INVOICE_LINK_START = 'COPY_INVOICE_LINK_START';
export const COPY_INVOICE_LINK_SUCCESS = 'COPY_INVOICE_LINK_SUCCESS';
export const COPY_INVOICE_LINK_FAIL = 'COPY_INVOICE_LINK_FAIL';

export type InvoicesActionTypes =
  | typeof FETCH_INVOICES_START
  | typeof FETCH_INVOICES_SUCCESS
  | typeof FETCH_INVOICES_FAIL
  | typeof FETCH_INVOICE_START
  | typeof FETCH_INVOICE_SUCCESS
  | typeof FETCH_INVOICE_FAIL
  | typeof SEND_INVOICE_START
  | typeof SEND_INVOICE_SUCCESS
  | typeof SEND_INVOICE_FAIL
  | typeof SET_INVOICE_PAID_START
  | typeof SET_INVOICE_PAID_SUCCESS
  | typeof SET_INVOICE_PAID_FAIL
  | typeof CREATE_INVOICE_START
  | typeof CREATE_INVOICE_SUCCESS
  | typeof CREATE_INVOICE_FAIL
  | typeof UPDATE_INVOICE_START
  | typeof UPDATE_INVOICE_SUCCESS
  | typeof UPDATE_INVOICE_FAIL
  | typeof DOWNLOAD_INVOICE_START
  | typeof DOWNLOAD_INVOICE_SUCCESS
  | typeof DOWNLOAD_INVOICE_FAIL
  | typeof DOWNLOAD_CLIENT_INVOICE_START
  | typeof DOWNLOAD_CLIENT_INVOICE_SUCCESS
  | typeof DOWNLOAD_CLIENT_INVOICE_FAIL
  | typeof REST_UPDATE_OR_CREATE_INVOICE
  | typeof PREVIEW_INVOICE_START
  | typeof PREVIEW_INVOICE_SUCCESS
  | typeof PREVIEW_INVOICE_FAIL
  | typeof FETCH_CLIENT_INVOICES_START
  | typeof FETCH_CLIENT_INVOICES_SUCCESS
  | typeof FETCH_CLIENT_INVOICES_FAIL
  | typeof DOWNLOAD_MULTIPLE_INVOICES_START
  | typeof DOWNLOAD_MULTIPLE_INVOICES_SUCCESS
  | typeof DOWNLOAD_MULTIPLE_INVOICES_FAIL
  | typeof SET_INVOICE_FILTER
  | typeof RESET_INVOICE_FILTER
  | typeof SET_INVOICE_WINDOW_POSITION
  | typeof RESET_INVOICE_WINDOW_POSITION
  | typeof CREATE_CREDIT_INVOICE_START
  | typeof CREATE_CREDIT_INVOICE_SUCCESS
  | typeof CREATE_CREDIT_INVOICE_FAIL
  | typeof REST_CREATE_CREDIT_INVOICE
  | typeof UPDATE_CREDIT_INVOICE_START
  | typeof UPDATE_CREDIT_INVOICE_SUCCESS
  | typeof UPDATE_CREDIT_INVOICE_FAIL
  | typeof DELETE_INVOICE_START
  | typeof DELETE_INVOICE_SUCCESS
  | typeof DELETE_INVOICE_FAIL
  | typeof COPY_INVOICE_LINK_START
  | typeof COPY_INVOICE_LINK_SUCCESS
  | typeof COPY_INVOICE_LINK_FAIL
  | typeof UNSET_INVOICE_PAID_START
  | typeof UNSET_INVOICE_PAID_SUCCESS
  | typeof UNSET_INVOICE_PAID_FAIL;
