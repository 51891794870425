import * as actionTypes from './actionTypes';
import { PaymentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PaymentsList } from '../../domain/Payment';

export type PaymentStateType = {
  paymentsList: PaymentsList | null;
  paymentsLoading: boolean;
  paymentsError: HttpError;
  paymentsListUpdateNeeded: boolean;
  paymentCompleteLoading: boolean;
  paymentCompleteError: HttpError;
  paymentCompleteSuccess: boolean;
  clientPaymentsList: PaymentsList | null;
  clientPaymentsLoading: boolean;
  clientPaymentsError: HttpError;
  clientPaymentsListUpdateNeeded: boolean;
  clientPaymentsCompleteSuccess: boolean;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentsList: null,
  paymentsLoading: true,
  paymentsError: null,
  paymentsListUpdateNeeded: false,
  paymentCompleteLoading: false,
  paymentCompleteError: null,
  paymentCompleteSuccess: false,
  clientPaymentsList: null,
  clientPaymentsLoading: true,
  clientPaymentsError: null,
  clientPaymentsListUpdateNeeded: false,
  clientPaymentsCompleteSuccess: false,
};

const fetchPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsLoading: true,
});

const fetchPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsList: action.paymentsList,
  paymentsLoading: false,
  paymentsError: null,
  paymentsListUpdateNeeded: false,
  paymentCompleteSuccess: false,
});

const fetchPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsError: action.paymentsError,
  paymentsLoading: false,
});

const completePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCompleteLoading: true,
});

const completePaymentSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCompleteLoading: false,
  paymentCompleteError: null,
  paymentCompleteSuccess: true,
  paymentsListUpdateNeeded: true,
});

const completePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCompleteLoading: false,
  paymentCompleteError: action.paymentCompleteError,
});

const fetchClientPaymentsStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  clientPaymentsLoading: true,
});

const fetchClientPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  clientPaymentsList: action.clientPaymentsList,
  clientPaymentsLoading: false,
  clientPaymentsError: null,
  clientPaymentsListUpdateNeeded: false,
  clientPaymentsCompleteSuccess: false,
});

const fetchClientPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  clientPaymentsError: action.paymentsError,
  clientPaymentsLoading: false,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_START:
      return fetchPaymentsStart(state);
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return fetchPaymentsSuccess(state, action);
    case actionTypes.FETCH_PAYMENTS_FAIL:
      return fetchPaymentsFail(state, action);
    case actionTypes.COMPLETE_PAYMENT_START:
      return completePaymentStart(state);
    case actionTypes.COMPLETE_PAYMENT_SUCCESS:
      return completePaymentSuccess(state);
    case actionTypes.COMPLETE_PAYMENT_FAIL:
      return completePaymentFail(state, action);
    case actionTypes.FETCH_CLIENT_PAYMENTS_START:
      return fetchClientPaymentsStart(state);
    case actionTypes.FETCH_CLIENT_PAYMENTS_SUCCESS:
      return fetchClientPaymentsSuccess(state, action);
    case actionTypes.FETCH_CLIENT_PAYMENTS_FAIL:
      return fetchClientPaymentsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
