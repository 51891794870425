import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { getFormData } from '../../utility/form/formData';
import {
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  resetCreateAndUpdateCompany,
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  fetchCompanyFail,
  updateCompanyStart,
  updateCompanySuccess,
  updateCompanyFail,
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFail,
} from './actions';
import { Asset } from '../../domain/Asset';

const API_URL = '/companies';

export type CompanyCreateRequest = {
  name: string;
  ownerId: number;
};

export type CompanyUpdateRequest = {
  id: number;
  name: string;
  ownerId: number;
  address: string;
  postcode: string;
  companyCode: number;
  website: string;
  email: string;
  city: string;
  phone: string;
  bankAccount: string;
  vat: number;
  logo: Asset;
  workingHoursStart: string;
  workingHoursEnd: string;
};

export const fetchCompanies = (params?: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchCompaniesStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchCompaniesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompaniesFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const createCompany =
  (inputs: CompanyCreateRequest) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then(() => {
        dispatch(createCompanySuccess());
        showToast('Company was successfully created!', 'success');
        dispatch(resetCreateAndUpdateCompany());
      })
      .catch((err) => {
        dispatch(createCompanyFail(err?.response?.data?.error));
        showToast(err?.response?.data?.message, 'error');
      });
  };

export const fetchCompany = (companyId: number) => (dispatch: Dispatch) => {
  dispatch(fetchCompanyStart());
  return axios
    .get(`${API_URL}/${companyId}`)
    .then((response) => {
      dispatch(fetchCompanySuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompanyFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const updateCompany =
  (inputs: CompanyUpdateRequest) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    const { id, ...updatedCompany } = inputs;
    return axios
      .patch(`${API_URL}/${id}`, getFormData(updatedCompany))
      .then(() => {
        dispatch(updateCompanySuccess());
        showToast('Company was successfully updated!', 'success');
        dispatch(resetCreateAndUpdateCompany());
      })
      .catch((err) => {
        dispatch(updateCompanyFail(err?.response?.data?.error));
        showToast(err?.response?.data?.message, 'error');
      });
  };

export const fetchCompanyOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchCompanyOptionsStart());
  return axios
    .get(`${API_URL}/options`)
    .then((response) => {
      dispatch(fetchCompanyOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompanyOptionsFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const deleteCompany = (id: number) => (dispatch: Dispatch) => {
  dispatch(deleteCompanyStart());
  return axios
    .delete(`${API_URL}/${id}`)
    .then(() => {
      dispatch(deleteCompanySuccess());
      showToast('Company was successfully deleted!', 'success');
    })
    .catch((err) => {
      dispatch(deleteCompanyFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};
